import {
  callDeclarations, 
  displayLogs,
  serverconfigs,
} from "../constants";
import {primaryRegion, secondaryRegion} from "../awsexport-client";
import * as turfHelper from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects'

export function adjustPopup() {
  var newAnchor = "";
  if (this.markers && this.markers.length > 0) {
      this.markers.map((pin, index) => {
          const pinPopup = pin.getPopup();
          if(pinPopup.isOpen()) {
              // Get right limit from one of the icons
              const drawToolsBounds = this.props.map.getContainer().getElementsByClassName('icon-measurement')[0].getBoundingClientRect();
              // Figure out bounding box for search box
              const searchBoxBounds = this.props.map.getContainer().getElementsByClassName('searchLayout')[0].getBoundingClientRect();
              // Figure out bounding box for search results box)
              var searchResults;
              var searchResultsBounds;
              searchResults = this.props.map.getContainer().getElementsByClassName('intersection');
              if (searchResults.length) {
                  searchResultsBounds = this.props.map.getContainer().getElementsByClassName('intersection')[0].getElementsByTagName('form')[0].getBoundingClientRect();
              }
              else {
                  searchResults = this.props.map.getContainer().getElementsByClassName('list-container');
                  if (searchResults.length) {
                      searchResultsBounds = this.props.map.getContainer().getElementsByClassName('list-container')[0].getBoundingClientRect();
                  }
              }
              // Get point location for popup anchor
              const popupLocation = this.props.map.project(pinPopup.getLngLat());
              // Figure out popup bounds
              const popupBounds = pinPopup.getElement().getBoundingClientRect();
              // Get map bounds
              const mapBounds = this.props.map.getContainer().getBoundingClientRect();
              const oldAnchor = pinPopup.options.hasOwnProperty('anchor') ? pinPopup.options.anchor : "";
              // Calculate popup bounds to check against
              const popupBottom = popupLocation.y;
              const popupTop = popupLocation.y - popupBounds.height;
              const popupLeft = popupLocation.x + mapBounds.left - (popupBounds.width / 2);
              const popupRight = popupLocation.x + mapBounds.left + (popupBounds.width / 2);
              // Check search box overlap
              if (popupTop < searchBoxBounds.bottom) {
                  if (popupLeft < searchBoxBounds.right) {
                      newAnchor = "top-left";
                  }
                  if (popupRight < searchBoxBounds.right) {
                      newAnchor = "top";
                  }
              }
              if (searchResults.length) {
                  // Check search results box
                  if ((popupTop < searchResultsBounds.bottom) && (popupLeft < searchResultsBounds.right)) {
                      newAnchor = "top-left";
                  }
                  if ((popupTop < searchResultsBounds.bottom) && (popupRight < searchResultsBounds.right)) {
                      newAnchor = "top";
                  }
                  if ((popupBottom < searchResultsBounds.bottom) && (popupLeft < searchResultsBounds.right)) {
                      newAnchor = "left";
                  }
                  if ((popupBottom < searchResultsBounds.bottom) && (popupRight < searchResultsBounds.right)) {
                      newAnchor = "top-left";
                  }
              }
              // Check tools overlap
              if (popupRight > drawToolsBounds.left) {
                  switch (newAnchor) {
                      case "left":
                          newAnchor = "top";
                          break;
                      case "top-left":
                          newAnchor = "top";
                          break;
                      case "top":
                          newAnchor = "top-right";
                          break;
                      default:
                          newAnchor = "right";
                          break;
                  }
              }
              if ( newAnchor != oldAnchor) {
                  if (newAnchor == "") {
                      delete(pinPopup.options.anchor);
                  }
                  else {
                      pinPopup.options.anchor = newAnchor;
                  }
                  pinPopup.remove();
                  pin.setPopup(pinPopup);
                  pinPopup.addTo(this.props.map);
              }
          }
      })
  }
}

export function turnonMapDrag(item) {
  if(item.props.map)
    item.props.map.on('drag', adjustPopup.bind(item));
}

export function turnoffMapDrag(item) {
  if(item.props.map)
    item.props.map.off('drag', adjustPopup.bind(item));
}


export function isEmpty(str) {
  if (!str || str.trim().length === 0) {
    return false;
  }
  return true;
}

export function setSimulationEnabled() {
  if (callDeclarations.enableSimulation) {
    let simulationDisabled = sessionStorage.getItem("isSimulationEnabled");
    if (simulationDisabled === null) {
      sessionStorage.setItem("isSimulationEnabled", true);
      callDeclarations.isSimulationEnabled = true;
    }
  }
}

export const setStationIdInLocalStorage = (name, stationId) => {
  /* setting the station Id pref for multiple users in ocal storage */
  var temp = [];
  let stationIdArray = localStorage.getItem("stationIdPref") // Get Previous station Id lists to temp = []
  if (stationIdArray !== null) {
    temp = [...JSON.parse(stationIdArray)]
    // const people = this.state.people.slice();
    const index = temp.findIndex(item => item.userName === name);
    if(index >= 0) {
      temp[index].stationId = stationId;
    } else {
      temp.push({
        userName: name,
        stationId: stationId,
      })
    }
  } else {
    temp.push({
      userName: name,
      stationId: stationId,
    })
  }
  localStorage.setItem("stationIdPref", JSON.stringify(temp))
};

export const getStationIdFromLocalStorage = () => {
  var stationId = null;
  var stationIdList = JSON.parse(localStorage.getItem("stationIdPref"));
  var currentUser = sessionStorage.getItem("currentUser") || sessionStorage.getItem("user");
  if(stationIdList && stationIdList != null && currentUser && currentUser != null) {
    stationIdList.map(station => {
      if(station.userName === currentUser) {
        stationId = station.stationId;
        return stationId;
      }
    }) 
    return stationId;
  }
  return stationId;
}

export const setEndCallTimeInLocalStorage = (userName, endCallTime) => {
  var temp = [];
  let endCallTimerArray = localStorage.getItem("endCallTime") // Get Previous endCallTimer lists to temp = []
  if (endCallTimerArray !== null) {
    temp = [...JSON.parse(endCallTimerArray)]
    const index = temp.findIndex(item => item.userName === userName);
    if(index >= 0) {
      temp[index].endCallTimer = endCallTime;
    } else {
      temp.push({
        userName: userName,
        endCallTimer: endCallTime,
      })
    }
  } else {
    temp.push({
      userName: userName,
      endCallTimer: endCallTime,
    })
  }
  localStorage.setItem("endCallTime", JSON.stringify(temp))
};

export const getEndCallTimeFromLocalStorage = () => {
  var endCallTime = null;
  var endCallTimeList = JSON.parse(localStorage.getItem("endCallTime"));
  var currentUser = sessionStorage.getItem("user");
  if(endCallTimeList && endCallTimeList != null && currentUser && currentUser != null) {
    endCallTimeList.map(endCall => {
      if(endCall.userName === currentUser) {
        endCallTime = endCall.endCallTimer;
        return endCallTime;
      }
    }) 
    return endCallTime;
  }
  return endCallTime;
}

export const isPointedToSecondaryRegion = () => {
  var pointedToSecondaryRegion = false
  var currentRegion = localStorage.getItem('currentRegion')
  if ((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region) {
    pointedToSecondaryRegion = true
  }
  return pointedToSecondaryRegion;
}

export const consoleLog = (consoleMessage, info) => {
  if (displayLogs === true || displayLogs === 'true') {
    if (info) console.log(consoleMessage, info);
    else console.log(consoleMessage);
  }
}

export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const calculateIndoorBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.coordinates && list.coordinates.length && map.getZoom() >= serverconfigs.indoorMapZoomLevel) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      const indoorPolygonObj = turfHelper.polygon([[
          ...list.coordinates,list.coordinates[0]
      ]]);
      isInside =  booleanIntersects(mapPolygonObj, indoorPolygonObj);
      if (isInside) {
          consoleLog(':::: The coordinates are within the bounding box');
      } else {
          consoleLog(':::: The coordinates are not within the bounding box');
      }
  }
  return isInside;
}
