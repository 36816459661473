import React, { Component } from "react";
import Logo from "./Logo";
import { Auth } from "aws-amplify";
import Dropdown from "react-bootstrap/Dropdown";
import { createBrowserHistory } from "history";
import withRouter from "../withRouter";
import srLogo from "../assets/sr_logo.png";
import icMapOff from '../assets/icon_map_off.png';
import icMapOn from '../assets/icon_map_on.png';
import icTrafficOff from '../assets/ml_traffic_off.png';
import icTrafficOn from '../assets/ml_traffic_on.png';
import icWeatherOff from '../assets/ml_weather_off.png';
import icWeatherOn from '../assets/ml_weather_on.png';
import icUncOff from '../assets/icon_unc_off.png';
import icUncOn from '../assets/icon_unc_on.png';
import icPrintOff from '../assets/icon_print_off.png';
// import from '../assets/icon_settings_off.png';
import icSplitOn from '../assets/icon_split_on.png';
import icSplitOff from '../assets/icon_split_off.png';
import icExpandCustomLayers from '../assets/expand_custom_layers.png';
import icCollapseCustomLayers from '../assets/collapse_custom_layers.png';
import {
  getMapThemesData, fetchMapThemeUrl, fetchTraffic, fetchWeather, fetchRenderWeather, fetchUncertainity,
  fetchRenderUncertainity, fetchSplitScreen, fetchRouting, fetchCamera, setRapidSOS, renderRapidSOS, fetchPsapBoundsLayers, fetchLoginStatus, getSourceLayers,
  fetchTrackingData, showTrackingList, fetchRenderRouting, selectedSourceLayer, fetchAllMapLayers, fetchFilteredSourceLayer, setStreamingStatus
} from '../redux/actions/headerActions';
import { callsPanelReloaded } from '../redux/actions/callActions';
import { connect } from 'react-redux';
import { fetchMapLoaded, setPeerSelectedValue, setPeersApiResp } from '../redux/actions/incidentActions';
import { mapStyle, featureLabels, tabUrls, callDeclarations, psapLayerBounds, configTimers, groupFeatures, peerDeclarations, massGISAPLayerBound } from '../constants';
import { getStationIdFromLocalStorage, consoleLog } from "../utils/commonUtils"
import icHandler from '../assets/handler.png';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';
import icDirectionsOff from '../assets/icon_directions_off.png';
import icDirectionsOn from '../assets/icon_directions_on.png';
import icCameraOff from '../assets/icon_camera_off.png';
import icCameraOn from '../assets/icon_camera_on.png';
import icRapidOff from '../assets/icon_rapid_off.png';
import icRapidOn  from '../assets/icon_rapid_on.png';
import icCustomLayers from '../assets/custom_layers.png';
import icCustomLayersOff from '../assets/icon_custom_off.png';
import icCustomLayersOn from '../assets/icon_custom_on.png';
import icMapCustomLayersOff from '../assets/ml_custom_off.png';
import icMapCustomLayersOn from '../assets/ml_custom_on.png';
import icPeerOn from '../assets/peerActive.png';
import icPeerOff from '../assets/peerInactive.png';
import trackingIconOff from '../assets/tracking_off.png';
import trackingIconOn from '../assets/tracking_on.png';
import {setSearchZoom} from '../redux/actions/incidentActions';

var initialFilterList = true;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: false,
      user: '',
      role: '',
      showMapTheme: false,
      showMapLayers: false,
      selectedTheme: '',
      activeTraffic: false,
      activeUncertainity: true,
      activeCamera: false,
      activeWeather: false,
      splitScreen: true,
      styles: [],
      initMap: true,
      showRouting: false,
      showRapidSOS: true,
      setPath: createBrowserHistory().location.pathname,
      psapBounds: psapLayerBounds,
      sourceLayers: [],
      showCustomLayers: false,
      showTracking: false,
      showCustomLayers: false,
      selectedVehicleIds: [],
      showAllVehicles: false,
      showTrackingList: [],
      dropDownList: [],
      selectedVehicleType: [],
      peersData: [],
      peersActive: false,
      allPeersActive: false,
      activateStream: false
    };
    this.signOut = this.signOut.bind(this);
    this.trackListTypes = [];
    this.addressPoint = false;
    this.customLayers = [];
    this.filteredSourceLayers = [];
  }

  static getDerivedStateFromProps(props, state) {
    if (props.trackingList && props.trackingList.length) {
      var trackingList = [];
      if (state.showAllVehicles) {
        state.showTrackingList = props.trackingList;
      }
      else {
        props.trackingList.map(list => {
          if (state.selectedVehicleType.includes(list.type)) {
            list.active = true;
          }
          trackingList.push(list);
        });
        state.showTrackingList = trackingList;
      }
      var dropDownList = props.trackingList.reduce((unique, o) => {
        if (!unique.some(obj => obj.type === o.type)) {
          if (o.type.toLowerCase() !== callDeclarations.allVehicles) {
            unique.push(o);
          }
        }
        return unique;
      }, []);
      state.dropDownList = dropDownList;
    }
    return state
  }

  componentDidMount() {
    if ((groupFeatures != null) && (groupFeatures.peers && groupFeatures.peers.length)) {
      let peerDataArray = [];
      groupFeatures.peers.map(peerName => {
        let newObj = {
          name: peerName,
          active: false
        }
        peerDataArray.push(newObj);
      })
      this.setState({ peersData: peerDataArray });
    }
    if (sessionStorage.getItem('currentUser') !== null) {
      this.props.getMapThemesData();
      this.props.getSourceLayers();
      setInterval(() => {
        if (this.props.callEnable) {
          this.props.getMapThemesData();
        }
      }, configTimers.mapDataTimeout + 1000);
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user) {
          this.setState({ signedIn: true });
          this.props.fetchLoginStatus(true);
          this.setState({ user: user.username });
          if (user.signInUserSession.idToken.payload['cognito:groups']) {
            this.setState({ role: user.signInUserSession.idToken.payload['cognito:groups'][0] });
          }
        }
      })
      .catch(() => {
        this.props.fetchLoginStatus(false);
        this.setState({ signedIn: false })
      });
  }
  signOut() {
    Auth.signOut()
      .then(() => {
        let idTokenFeatures = sessionStorage.getItem('idToken');
        let currentUser = sessionStorage.getItem('currentUser');
        var groupFeatures = sessionStorage.getItem('groupProfile');
        var showNotifications = sessionStorage.getItem('showNotifications');
        this.setState({ signedIn: false });
        this.props.fetchLoginStatus(false);
        sessionStorage.currentUser = undefined;
        sessionStorage.notAdmin = undefined;
        sessionStorage.removeItem('createUser');
        sessionStorage.clear();
        if (isFeatureAllowed(featureLabels.call) && !callDeclarations.isSimulationEnabled) {
          sessionStorage.setItem('isSimulationEnabled', callDeclarations.isSimulationEnabled);
          sessionStorage.setItem('idToken', idTokenFeatures);
          sessionStorage.setItem('user', currentUser);
          sessionStorage.setItem('groupProfile', groupFeatures);
          sessionStorage.setItem('showNotifications', showNotifications);
        }
        window.location.href = "/auth";
      })
      .catch(e => {
        consoleLog(e);
      });
  }

  handleTraffic = (e) => {
    this.setState({ activeTraffic: this.state.activeTraffic = !this.state.activeTraffic });

    this.props.fetchTraffic(this.state.activeTraffic)
    // this.props.handleTraffic();
  }

  handleCamera = (e) => {
    this.setState({ activeCamera: this.state.activeCamera = !this.state.activeCamera });
    this.props.fetchCamera(this.state.activeCamera);
    if (this.state.activeCamera) {
      this.props.callsPanelReloaded(1);
    }
    else {
      this.props.callsPanelReloaded(5);
    }
  }

  handleStreaming = (e) => {
    let streamVal = this.props.isStreaming ? this.props.isStreaming : false;
    this.setState({ activateStream: streamVal= !streamVal }, () => {
      this.props.setStreamingStatus(this.state.activateStream)
    });
  }

  handleUncertainty = (e) => {
    this.setState({ activeUncertainty: this.state.activeUncertainity = !this.state.activeUncertainity });
    this.props.fetchUncertainity(this.state.activeUncertainity);
    this.props.fetchRenderUncertainity(0);
    this.props.callsPanelReloaded(1);
  }

  handleRouting = (e) => {
    sessionStorage.setItem("routeTo", false);
    let routingVal = this.props.routing ? this.props.routing : false;
    this.setState({ showRouting: routingVal = !routingVal }, () => {
      this.props.fetchRouting(this.state.showRouting);
    });
  }

  handleWeather = (e) => {
    this.setState({ activeWeather: (this.props.weather ? this.props.weather : false) }, () => {
      this.props.fetchWeather(!this.state.activeWeather);
      this.props.fetchRenderWeather(1);
    });

    // this.props.handleWeather();
  }

  handlePsapBounds = (obj) => {
    let boundsData = {
      name: obj.Name,
      show: obj.active,
      id: obj.id
    }
    psapLayerBounds.map(list => {
      if (list.value === obj.value) {
        list.active = !list.active;
        boundsData.show = list.active
      }
      else {
        list.active = false;
      }
    })
    this.setState({
      psapBounds: psapLayerBounds
    })
    this.props.fetchPsapBoundsLayers(boundsData);
  }

  handleSourceLayers = (obj) => {
    if (obj.active) {
      obj.active = false;
    } else {
      obj.active = true;
    }

    let SourceData = {
      name: obj.Name,
      show: obj.active,
      id: obj.id
    }

    if (this.props.allSourceLayers && this.props.allSourceLayers.length) {
      this.props.allSourceLayers.map(list => {
        if (list.id === obj.id) {
          list.active = obj.active;
        }
      })
    }

    // This condition is to check if the selected value is already present in this.props.selectedSourceLayers
    if (this.props.selectedSourceLayers && this.props.selectedSourceLayers.length) {
      const index = this.props.selectedSourceLayers.findIndex(item => item.id.includes(SourceData.id));
      if (index != -1) {
        this.props.selectedSourceLayers.splice(index, 1);
        this.props.selectedSourceLayer(SourceData);
      } else {
        this.props.selectedSourceLayer(SourceData);
      }
    } else {
      this.props.selectedSourceLayer(SourceData);
    }
    initialFilterList = false;
  }
  getMapDefinedCustomLayers = () => {
    if (this.addressPoint) {
      this.filteredSourceLayers.unshift(massGISAPLayerBound.massGISAPLayer);
      this.addressPoint = false;
    }
  }

  renderPsapBounds = () => {
    let filteredLayers = [];
    this.filteredSourceLayers = [];
    this.addressPoint = false;
    let customLayersExist = false;
    if (this.props.allMapLayers && this.props.allMapLayers.length) {
      this.props.allMapLayers.map(layer => {
        psapLayerBounds.map(list => {
          if ((list.Name === 'Counties' && list.Name === layer.id) || (list.Name === 'Incorporated Municipalities' && list.Name === layer.id)) {
            filteredLayers.unshift(list);
          } else if (layer.id === list.Name) {
            filteredLayers.push(list);
          }
        })
        //Check for address points
        if (layer.id === massGISAPLayerBound.massGISAPLayer.id) {
          if (layer.layout && layer.layout.visibility && layer.layout.visibility === 'none') {
            massGISAPLayerBound.massGISAPLayer.active = false;
          } else {
            massGISAPLayerBound.massGISAPLayer.active = true;
          }
          this.addressPoint = true;
        }

        // This condition is to check if source layers are present in all map layers
        if (this.props.allSourceLayers && this.props.allSourceLayers.length) {
          this.customLayers = [];
          this.props.allSourceLayers.map(val => {
            if (val.id && val.id === layer.id) {
              customLayersExist = true;
              val.displayName = layer.id
              if (layer.layout && layer.layout.visibility && layer.layout.visibility === 'none')
                val.active = false;
              else
                this.handleSourceLayers(val);

              this.filteredSourceLayers.push(val);
              this.customLayers.push(val);
              this.props.fetchFilteredSourceLayer(...this.customLayers);
            }
          })
          if (this.filteredSourceLayers.length) {
            this.getMapDefinedCustomLayers();
          }
        }
        else {
          this.getMapDefinedCustomLayers();
        }
      })
    }
    // this.state.psapBounds = filteredLayers.sort((a, b) => (a.id > b.id) ? 1 : -1);
    this.state.psapBounds = filteredLayers;
    this.state.sourceLayers = this.filteredSourceLayers;

    if (customLayersExist) {
      initialFilterList = false;
    }
  }

  handleMapTheme = (val) => {
    this.setState({ showMapTheme: !this.state.showMapTheme });
    // this.props.handleMapTheme();
  }

  handleMapLayers = () => {
    this.setState({ showMapLayers: !this.state.showMapLayers });
  }

  handleSplitScreen = () => {
    this.setState({ splitScreen: !this.props.splitScreen }, () => {
      this.props.fetchSplitScreen(this.state.splitScreen);
    });
  }
  
  handleRapidSOS = (e) => {
    this.setState({ showRapidSOS: this.state.showRapidSOS = !this.state.showRapidSOS }, () => {
      this.props.callsPanelReloaded(1);
      this.props.setRapidSOS(this.state.showRapidSOS);
      // this.props.renderRapidSOS(0);
    })
  }

  handleVehicleGroup = (e) => {
    this.setState({ showTracking: !this.state.showTracking });
    // this.props.showTrackingList(this.state.showTracking, this.props.trackingList , 1);
  }

  handleAllTypes = () => {
    if (this.props.trackingList.length) {
      let showTrackingList = this.state.showTrackingList;
      this.setState({
        showAllVehicles: !this.state.showAllVehicles
      }, () => {
        this.props.setSearchZoom(false);
        if (this.state.showAllVehicles) {
          showTrackingList.map(list => {
            list.active = false;
          })
          this.setState({
            showTrackingList: showTrackingList,
            selectedVehicleIds: [],
            selectedVehicleType: []
          })
          this.props.showTrackingList(this.props.trackingList, 0, true);
          this.props.fetchRenderRouting(this.state.showRouting ? 0 : 1);
        }
        else {
          this.props.showTrackingList([], 2, false);
          this.props.fetchRenderRouting(1);
          this.props.fetchRouting(false);
        }
      })
    }
  }

  handleTracking = () => {
    if (this.props.trackingError && sessionStorage.getItem('currentUser') !== null) {
      this.props.getTrackingData();
    }
  }

  handleVehicleType(obj) {
    let showTrackingList = this.state.showTrackingList;
    var selectedVehicleType = this.state.selectedVehicleType;
    var tempList = [], selectedTempIds = this.state.selectedVehicleIds;
    this.props.setSearchZoom(false);
    if (this.state.showAllVehicles) {
      this.setState({
        showAllVehicles: false,
      })
    }
    showTrackingList.map((list, ind) => {
      if (obj.type === list.type) {
        list.active = !list.active;
        if (!list.active) {
          var myIndex = selectedVehicleType.indexOf(obj.type);
          selectedVehicleType.splice(myIndex, 1);
        }
      }
      if (list.active) {
        tempList.push(list);
        if (!selectedTempIds.includes(list.id)) {
          selectedTempIds.push(list.id);
        }
        if (!selectedVehicleType.includes(list.type)) {
          selectedVehicleType.push(list.type);
        }
      }
      if (list.id === obj.id && !list.active && selectedTempIds.includes(obj.id)) {
        var myIndex = selectedTempIds.indexOf(obj.id);
        selectedTempIds.splice(myIndex);
      }
    })
    this.setState({
      showTrackingList: showTrackingList,
      selectedVehicleIds: selectedTempIds,
      selectedVehicleType: selectedVehicleType
    })
    this.props.fetchRenderRouting((tempList.length && this.state.showRouting) ? 0 : 1);
    if (!tempList.length) {
      this.props.fetchRouting(false);
    }
    this.props.showTrackingList(tempList, tempList.length ? 0 : 2, tempList.length ? true : false);
  }

  handlePeerLayers = () => {
    this.setState({ peersActive: !this.state.peersActive });
  }

  handleAllPeers = () => {
    this.setState({
      allPeersActive: !this.state.allPeersActive
    }, () => {
      let peerData = this.state.peersData;

      if (this.state.allPeersActive) {
        peerData.map(peerList => {
          peerList.active = false;
        })
        this.setState({ peersData: peerData });
        this.props.setPeerSelectedValue(peerData);
      }
      else {
        this.props.setPeerSelectedValue([]);
      }
    })
  }

  handlePeersBound = (selectedObj) => {
    let peerData = this.state.peersData;
    let selectedArray = [];
    this.setState({ allPeersActive: false });
    if (selectedObj.active) {
      selectedObj.active = false;
    } else {
      selectedObj.active = true;
    }
    if (peerData && peerData.length) {
      peerData.map(peer => {
        if (peer.active) {
          selectedArray.push(peer);
        }
      })
    }
    else {
      selectedArray.push(selectedObj);
    }
    this.props.setPeerSelectedValue(selectedArray);
  }

  handleShowCustomLayers = () => {
    this.setState({ showCustomLayers: !this.state.showCustomLayers });
  }

  handleStyleSelection = (theme) => {
    this.setState({ selectedTheme: theme.Name });
    this.setState({ initMap: false });
    this.setState({ showCustomLayers: false });
    this.props.fetchMapThemeUrl(theme.Url);
    this.props.callsPanelReloaded(1);
    this.setState({ sourceLayers: [], psapBounds: [] });
    this.props.fetchAllMapLayers([]);
    initialFilterList = true;
  }

  redirectToAbout = () => {
    this.props.navigate('/about');
    this.setState({
      setPath: '/about'
    })
  }

  redirectToIncidents = () => {
    this.props.navigate('/calls');
    this.setState({
      setPath: '/calls'
    })
  }

  redirectToDoc = () => {
    this.props.navigate('/documentation');
    this.setState({
      setPath: '/documentation'
    })
  }

  redirectToAccount = () => {
    this.props.navigate('/account');
    this.setState({
      setPath: '/account'
    })
  }

  render() {
    const path = createBrowserHistory().location.pathname;
    // below condition will execute when user clears session storage
    if (this.props.loggedIn && !sessionStorage.getItem('currentUser')) {
      this.signOut();
    }

    if (initialFilterList && this.props.allMapLayers.length) {
      this.renderPsapBounds();
    }

    return (
      <div className="header">
        <nav className="psap-header">
          <div className={`navbar-header ${path !== '/auth' && sessionStorage.getItem('currentUser') ? 'container-fluid' : 'container'}`}>
            <div className="d-flex align-items-center justify-content-between">
              <div className='d-flex align-items-center'>
                {(path !== '/auth' && path !== '/calls' && path !== '/') ?
                  <span className='hand-cursor' onClick={this.redirectToIncidents}>
                    <img src={srLogo} />
                  </span> : <img src={srLogo} />}
                <Logo role={this.state.role} />

              </div>

              {(sessionStorage.getItem('currentUser') !== null) ?
                <div className="float-right user-name d-flex align-items-center">
                  {path === '/calls' ?
                    <>
                      {isFeatureAllowed(featureLabels.call) ? <div className={`hand-cursor nav-list ${this.state.activeTraffic ? 'active' : ''} ${this.props.tabName === tabUrls.call ? '' : 'hide'}`} onClick={this.handleSplitScreen}>
                        <img src={this.props.splitScreen ? icSplitOn : icSplitOff} className='nav-icon' />
                        <span className={this.props.splitScreen ? "active" : "inactive"}>Split Screen</span>
                      </div> : ''}
                      <div className='hand-cursor nav-list map-style' onClick={this.handleMapTheme}>
                        {/* <span className="inactive">Map Style</span> */}
                        <Dropdown className='hand-cursor '>
                          <Dropdown.Toggle drop='left'>
                            <img src={this.state.showMapTheme ? icMapOn : icMapOff} className='nav-icon' />
                            Map Style
                          </Dropdown.Toggle>
                          {this.props.mapThemes && this.props.isLoading == undefined && this.props.mapThemes.length ?
                            <Dropdown.Menu >
                              {this.props.mapThemes.map((list, ind) => {
                                return (
                                  <Dropdown.Item key={ind} className={(this.state.selectedTheme === list.Name || this.state.initMap && ind === 0) ? 'selected' : null} onClick={this.handleStyleSelection.bind(this, list)}>
                                    <img src={mapStyle.images[list.Name] ? mapStyle.images[list.Name] :
                                      list.Name.toLowerCase().includes(mapStyle.themes.dark) ? mapStyle.images[mapStyle.themes.dark] :
                                        list.Name.toLowerCase().includes(mapStyle.themes.day) ? mapStyle.images[mapStyle.themes.day] :
                                          list.Name.toLowerCase().includes(mapStyle.themes.light) ? mapStyle.images[mapStyle.themes.light] :
                                            list.Name.toLowerCase().includes(mapStyle.themes.night) ? mapStyle.images[mapStyle.themes.night] :
                                              mapStyle.images['default']} className='mapstyle-icon' />
                                    {list.Name}
                                  </Dropdown.Item>)
                              })}

                            </Dropdown.Menu>
                            : ''}
                        </Dropdown>
                      </div>
                      {isFeatureAllowed(featureLabels.routing) && (isFeatureAllowed(featureLabels.call)) ?
                        <div className={`hand-cursor nav-list ${this.props.routing ? 'active' : ''} ${this.props.tabName === tabUrls.call ? '' : 'hide'}`} onClick={this.handleRouting}>
                          <img src={this.props.routing ? icDirectionsOn : icDirectionsOff} className='nav-icon' />
                          <span className={this.props.routing ? "active" : "inactive"}>Routing</span>
                        </div>
                        : ''
                      }
                      {isFeatureAllowed(featureLabels.tracking) && (isFeatureAllowed(featureLabels.call) || isFeatureAllowed(featureLabels.incident)) ?
                        <div className={`hand-cursor nav-list map-style tracking-list ${this.props.tabName === tabUrls.call || this.props.tabName === tabUrls.incident ? '' : 'hide'}`} onClick={this.handleVehicleGroup}>
                          <Dropdown className='hand-cursor '>
                            <div onClick={this.handleTracking}>
                              <Dropdown.Toggle drop='left'>
                                <img src={(this.state.selectedVehicleIds.length || this.state.showAllVehicles) ? trackingIconOn : trackingIconOff} className='nav-icon track-icon' />
                                <span className={(this.state.selectedVehicleIds.length || this.state.showAllVehicles) ? 'active' : 'inactive'}>Tracking</span>
                              </Dropdown.Toggle>
                            </div>
                            {this.state.showTrackingList && this.state.showTrackingList.length ?
                              <Dropdown.Menu >
                                <Dropdown.Item >
                                  <div className={`hand-cursor text-capitalize ${this.state.showAllVehicles ? 'active' : ''}`} onClick={this.handleAllTypes}>{callDeclarations.allVehicles}</div>
                                </Dropdown.Item>
                                {this.state.dropDownList.map((list, ind) => {
                                  return (
                                    <Dropdown.Item key={ind}>
                                      <div className={`hand-cursor ${list.active ? 'active' : ''}`} onClick={this.handleVehicleType.bind(this, list)}>{list.type}</div>
                                    </Dropdown.Item>)
                                })}

                              </Dropdown.Menu>
                              : ''}
                          </Dropdown>
                        </div> : ''
                      }
                      {isFeatureAllowed(featureLabels.call) && isFeatureAllowed(featureLabels.video) ?
                        <div className={`hand-cursor nav-list camera-btn ${this.props.isStreaming ? 'active' : ''} ${this.props.tabName === tabUrls.call ? '' : 'hide'}`} onClick={this.handleStreaming}>
                          <img src={this.props.isStreaming ? icCameraOn : icCameraOff} className='nav-icon' />
                          <span className={this.props.isStreaming ? "active" : "inactive"}>Video</span>
                        </div>
                      : ''}
                      {isFeatureAllowed(featureLabels.call) && isFeatureAllowed(featureLabels.sitCount) ?
                        <div className={`hand-cursor nav-list camera-btn ${this.state.activeCamera ? 'active' : ''} ${this.props.tabName === tabUrls.call ? '' : 'hide'}`} onClick={this.handleCamera}>
                          <img src={this.state.activeCamera ? icCameraOn : icCameraOff} className='nav-icon' />
                          <span className={this.state.activeCamera ? "active" : "inactive"}>Camera</span>
                        </div>
                        : ''}

                      <div className={`hand-cursor nav-list ${this.state.showRapidSOS ? 'active' : ''} ${this.props.tabName !== tabUrls.analytics ? '' : 'hide'}`} onClick={this.handleRapidSOS}>
                        <img src={this.state.showRapidSOS ? icRapidOn : icRapidOff} className='nav-icon' />
                        <span className={this.state.showRapidSOS ? "active" : "inactive"}>RapidSOS</span>
                      </div>

                      {((this.state.peersData && this.state.peersData.length) && this.props.tabName === tabUrls.incident) ?
                        <div className='hand-cursor nav-list map-style tracking-list' onClick={this.handlePeerLayers}>
                          <Dropdown className='hand-cursor'>
                            <Dropdown.Toggle drop='left' >
                              <img src={(this.state.allPeersActive || this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length) ? icPeerOn : icPeerOff} className='nav-icon' />
                              <span className={(this.state.allPeersActive || this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length) ? 'active' : 'inactive'}>Peers</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                              <Dropdown.Item>
                                <div className={`hand-cursor layer-divider text-capitalize ${this.state.allPeersActive ? 'active' : ''}`} onClick={this.handleAllPeers.bind(this)}>
                                  {peerDeclarations.allPeers}
                                </div>
                              </Dropdown.Item>
                              {
                                this.state.peersData.map(peerObj => {
                                  return (
                                    <Dropdown.Item key={peerObj.name}>
                                      <div className={`hand-cursor ${peerObj.active ? 'active' : ''}`} onClick={this.handlePeersBound.bind(this, peerObj)}>
                                        {peerObj.name}
                                      </div>
                                    </Dropdown.Item>
                                  )
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        : ''
                      }

                      <div className='hand-cursor nav-list map-style map-layers' onClick={this.handleMapLayers} onBlur={this.handleMapLayers}>
                        <Dropdown className='hand-cursor d-inline mx-2'>
                          <Dropdown.Toggle drop='left'>
                            <img src={this.state.showMapLayers ? icCustomLayersOn : icCustomLayersOff} className='nav-icon' />
                            Map Layers
                          </Dropdown.Toggle>
                          <Dropdown.Menu >
                            {isFeatureAllowed(featureLabels.traffic) ?
                              <Dropdown.Item>
                                <div className={`hand-cursor nav-list ${this.state.activeTraffic ? 'active' : ''}`} onClick={this.handleTraffic}>
                                  <img src={this.state.activeTraffic ? icTrafficOn : icTrafficOff} className='nav-icon' />
                                  <span className={this.state.activeTraffic ? "active" : "inactive"}>Traffic</span>
                                </div>
                              </Dropdown.Item>
                              : ''}
                            {isFeatureAllowed(featureLabels.weather) ?
                              <Dropdown.Item>
                                <div className={`hand-cursor nav-list layer-divider ${this.props.weather ? 'active' : ''}`} onClick={this.handleWeather}>
                                  <img src={this.props.weather ? icWeatherOn : icWeatherOff} className='nav-icon' />
                                  <span className={this.props.weather ? "active" : "inactive"}>Weather</span>
                                </div>
                              </Dropdown.Item>
                              : ''}

                            <Dropdown className="custom-layer-border-bottom">
                              {this.state.sourceLayers.length ?
                                <div className={`hand-cursor nav-list custom-layers ${this.state.showCustomLayers ? 'active' : ''}`} onClick={this.handleShowCustomLayers}>
                                  <img className='nav-icon' src={this.state.showCustomLayers ? icCollapseCustomLayers : icExpandCustomLayers}>
                                  </img>
                                  <span> Custom Layers</span>
                                </div>
                                : ''
                              }

                              {this.state.showCustomLayers ? this.props.allMapLayers.length ?
                                this.state.sourceLayers.map(list => {
                                  return (
                                    <Dropdown.Item key={list.displayName}>
                                      <div className={`hand-cursor nav-list ml-3 ${list.active ? 'active' : ''}`} onClick={this.handleSourceLayers.bind(this, list)}>
                                        <img src={list.active ? icMapCustomLayersOn : icMapCustomLayersOff} className='nav-icon' />
                                        <span >{list.displayName}</span>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                }) : ''
                                : ''
                              }
                            </Dropdown>
                            {this.props.allMapLayers.length ?
                              this.state.psapBounds.map(list => {
                                return (
                                  <Dropdown.Item key={list.value}>
                                    <div className={`hand-cursor nav-list ${list.active ? 'active' : ''}`} onClick={this.handlePsapBounds.bind(this, list)}>
                                      <img src={list.active ? icMapCustomLayersOn : icMapCustomLayersOff} className='nav-icon' />
                                      <span >{list.displayName}</span>
                                    </div>
                                  </Dropdown.Item>
                                )
                              })
                              : ''
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className={`hand-cursor nav-list ${this.state.activeUncertainity ? 'active' : ''}`} onClick={this.handleUncertainty}>
                        <img src={this.state.activeUncertainity ? icUncOn : icUncOff} className='nav-icon' />
                        <span className={this.state.activeUncertainity ? "active" : "inactive"}>Uncertainty</span>
                      </div>

                      {/* <div >
                            <img src='../assets/handler.png' className='divider-icon' />
                          </div>
                          <div className='ml-0 hand-cursor nav-list'>
                            <img src='../assets/icon_print_off.png' className='nav-icon' />
                            <span className="inactive">Print</span>
                          </div> */}
                      {/* <div className='hand-cursor nav-list'>
                        <img src='../assets/icon_settings_off.png' className='nav-icon' />
                          Settings
                      </div> */}
                    </>
                    : ""
                  }

                  <Dropdown className='hand-cursor nav-list'>
                    <Dropdown.Toggle drop='left' as="div" className="profile-img d-flex align-items-center">
                      {this.state.user}
                      <span className='user-name-icon'>{this.state.user.charAt(0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <Dropdown.Item  onClick={this.redirectToAccount}>
                        Account
                      </Dropdown.Item>
                      <Dropdown.Item  onClick={this.redirectToDoc}>
                        Documentation
                      </Dropdown.Item>
                      {/* <Dropdown.Item>
                              Support
                            </Dropdown.Item> */}
                      <Dropdown.Item onClick={this.redirectToAbout}>About</Dropdown.Item>
                      <Dropdown.Item onClick={this.signOut}>
                        Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

              : ''}
            </div>
          </div>
        </nav>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
    isLoading: state.mapThemes ? state.mapThemes.isLoading : state.mapThemes,
    error: state.mapThemes.error,
    tabName: state.tab.tabName,
    splitScreen: state.header.isSplitScreen,
    routing: state.routing ? state.routing.routing : state.routing,
    showCamera: state.showCamera ? state.showCamera.showCamera : state.showCamera,
    allMapLayers: state.allMapLayers.allMapLayers,
    callEnable: state.mapThemes ? state.mapThemes.callEnable : state.mapThemes,
    weather: state.weather ? state.weather.weather : state.weather,
    trackingList: state.trackingList ? state.trackingList.trackingList : [],
    showTracking: state.showTracking ? state.showTracking.showTracking : state.showTracking,
    vehicleRender: state.showTracking ? state.showTracking.renderCount : state.showTracking,
    vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking,
    allSourceLayers: state.allSourceLayers.allSourceLayers,
    selectedSourceValue: state.selectedSourceValue,
    boundLayer: state.boundLayer,
    selectedSourceLayers: state.selectedSourceLayers,
    selectedPeersValue: state.selectedPeersValue,
    peersApiResp: state.peersApiResp.peersApiResp,
    styleLoad: state.mapUrl ? state.mapUrl.styleLoad : state.styleLoad,
    isStreaming: state.isStreaming.isStreaming
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMapThemesData: () => dispatch(getMapThemesData()),
    fetchMapThemeUrl: (url) => dispatch(fetchMapThemeUrl(url)),
    fetchTraffic: (val) => dispatch(fetchTraffic(val)),
    fetchWeather: (val) => dispatch(fetchWeather(val)),
    fetchRenderWeather: (val) => dispatch(fetchRenderWeather(val)),
    fetchMapLoaded: (val) => dispatch(fetchMapLoaded(val)),
    callsPanelReloaded: (val) => dispatch(callsPanelReloaded(val)),
    fetchUncertainity: (val) => dispatch(fetchUncertainity(val)),
    setRapidSOS: (val) => dispatch(setRapidSOS(val)),
    renderRapidSOS: (val) => dispatch(renderRapidSOS(val)),
    fetchRenderUncertainity: (val) => dispatch(fetchRenderUncertainity(val)),
    fetchSplitScreen: (val) => dispatch(fetchSplitScreen(val)),
    fetchRouting: (val) => dispatch(fetchRouting(val)),
    fetchCamera: (val) => dispatch(fetchCamera(val)),
    fetchPsapBoundsLayers: (val) => dispatch(fetchPsapBoundsLayers(val)),
    fetchTrackingData: (val) => dispatch(fetchTrackingData(val)),
    showTrackingList: (type, renderCount, val) => dispatch(showTrackingList(type, renderCount, val)),
    fetchRenderRouting: (val) => dispatch(fetchRenderRouting(val)),
    getSourceLayers: () => dispatch(getSourceLayers()),
    fetchLoginStatus: (val) => dispatch(fetchLoginStatus(val)),
    selectedSourceLayer: (val) => dispatch(selectedSourceLayer(val)),
    setPeerSelectedValue: (val) => dispatch(setPeerSelectedValue(val)),
    setPeersApiResp: (val) => dispatch(setPeersApiResp(val)),
    fetchAllMapLayers: (val) => dispatch(fetchAllMapLayers(val)),
    fetchFilteredSourceLayer: (val) => dispatch(fetchFilteredSourceLayer(val)),
    setSearchZoom: (val) => dispatch(setSearchZoom(val)),
    setStreamingStatus: (val) => dispatch(setStreamingStatus(val))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

