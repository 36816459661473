import { SET_TAB_NAME } from '../actions/actionTypes';
import {tabUrls} from '../../constants';


const initialState = {
    tabName: tabUrls.call
}

export const TabReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_NAME:
            return { ...state, tabName: action.data }        
        default:
            return state;
    }
}