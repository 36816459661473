import { combineReducers, createStore, applyMiddleware } from "redux";
import {AddInfoReducer, incidentReducer, MapBoundReducer, MapLoadedReducer, NENAReducer, SelectIncidentReducer, UberReducer, PauseZoomReducer, SearchZoomReducer, IncidentDependenciesReducer, IncidentAutoZoomdReducer, PeersDataReducer,PeersApiRespReducer, FilteredPeersRespReducer, RegionNameReducer, SecondMapPathReducer, StationIDReducer} from './reducers/incidentReducer';
import thunk from 'redux-thunk';
import {DatesReducer, HistoryIntervalReducer, HistoryReducer, HistoryRenderReducer, HistorySliderReducer} from "./reducers/historyReducer"
import {AEDReducer, CallAutoZoomdReducer, CallDependenciesReducer, CallsReducer, RenderCallsReducer} from './reducers/callsReducer'
import { SolacomReducer } from "./reducers/solacomReducer";
import { MapRenderReducer, MapThemesReducer, MapThemeUrlReducer, RenderWeatherReducer, TrafficReducer, WeatherReducer, UncertainityReducer, 
    UncertainityRenderReducer, SplitScreenReducer, RoutingReducer, RoutingRenderReducer, CameraReducer, RapidSOSRenderReducer, RapidSOSReducer, PsapBoundsReducer, AllMapLayersReducer, LoggedInReducer, AllSourceLayersReducer, TrackingReducer, ShowTrackingReducer, SelectedSourceLayersReducer, NetworkCheckReducer, FilteredSourceLayersReducer, StreamingReducer } from "./reducers/headerReducer";
import { TabReducer } from './reducers/tabReducers';
import {SearchReducer, SearchFlagReducer} from './reducers/searchReducers'
import { CriminalDataReducer, IdentityReducer } from "./reducers/whoosterReducer";

const rootReducer = combineReducers({
    incidents: incidentReducer,
    NENA: NENAReducer ,
    uber: UberReducer,
    addInfo: AddInfoReducer,
    selectIncident: SelectIncidentReducer,
    analytics: HistoryReducer,
    loadMap: MapLoadedReducer,
    updateBound: MapBoundReducer,
    dates: DatesReducer,
    historyRender: HistoryRenderReducer,
    historyIntervals: HistoryIntervalReducer,
    sliderVal: HistorySliderReducer,
    calls: CallsReducer,
    mapThemes: MapThemesReducer,
    mapUrl: MapThemeUrlReducer,
    mapRender: MapRenderReducer,
    traffic: TrafficReducer,
    weather: WeatherReducer,
    renderWeather: RenderWeatherReducer,
    renderCalls: RenderCallsReducer,
    uncertainity: UncertainityReducer,
    renderUncertainity: UncertainityRenderReducer,
    header:SplitScreenReducer,
    tab: TabReducer,
    search : SearchReducer,
    searchFlag: SearchFlagReducer,
    identity: IdentityReducer,
    criminalData: CriminalDataReducer,
    pauseZoom: PauseZoomReducer,
    searchPauseZoom: SearchZoomReducer,
    routing: RoutingReducer,
    renderRouting: RoutingRenderReducer,
    showCamera: CameraReducer,
    AEDList: AEDReducer,
    rapidSOS: RapidSOSReducer,
    renderRapidSOS: RapidSOSRenderReducer,
    boundLayer: PsapBoundsReducer,
    allMapLayers: AllMapLayersReducer,
    incidentDependencies:IncidentDependenciesReducer,
    incidentAutoZoom: IncidentAutoZoomdReducer,
    trackingList: TrackingReducer,
    showTracking: ShowTrackingReducer,
    allSourceLayers: AllSourceLayersReducer,
    loggedIn: LoggedInReducer,
    selectedSourceLayers: SelectedSourceLayersReducer,
    isNetworkAvailable: NetworkCheckReducer,
    regionName: RegionNameReducer,
    selectedPeersValue: PeersDataReducer,
    peersApiResp: PeersApiRespReducer,
    callAutoZoom: CallAutoZoomdReducer,
    callDependencies: CallDependenciesReducer,
    filteredpeers: FilteredPeersRespReducer,
    filteredSourceLayer: FilteredSourceLayersReducer,
    secondMapPath: SecondMapPathReducer,
    solacomCallEvents: SolacomReducer,
    isStreaming: StreamingReducer,
    stationID: StationIDReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
