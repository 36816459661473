import React from 'react';
import mapboxgl from 'mapbox-gl';
import { fetchRenderRouting, fetchRouting, showTrackingList } from '../redux/actions/headerActions';
import { connect } from 'react-redux';
import TrackingPopup from './modals/TrackingPopup';
import { tabUrls, callDeclarations } from '../constants';
import { createRoot } from 'react-dom/client';

const colors = ['#4DD500', '#FF2615', '#99999B'];
const highStatusExp = ['==', ['get', 'ignition'], ['literal', 'on']];
const mediumsStatusExp = ['==', ['get', 'ignition'], ['literal', 'off']];
const lowStatusExp = ['==', ['get', 'ignition'], ['literal', '']];

var markers = {}, featureData = {
    type: "FeatureCollection",
    features: []
};
let markersOnScreen = {};

class VehicleDonut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackingList: [],
            showTrackingPopup: false,
            trackingPopupData: null,
            currentTrackID: 0,
            vehicleData: null
        };
        this.clusterPopup;
        this.trackingPopups = [];
        this.trackingBreadCrumbsLayers = [];
        this.donutMarkers = [];
        this.updateBounds = true;
        this.donutPopup;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.vehicleType && !props.vehicleType.length) {
            state.showTrackingPopup = false;
            state.currentTrackID = 0;
        }
        return state
    }

    componentDidUpdate = (prevProps, prevState) => { 
        var updateSelectedpopup = false;
        const { tabName, vehicleType } = this.props;
        if ((prevProps.tabName && prevProps.tabName !== tabName) || prevProps.vehicleType && prevProps.vehicleType.length && vehicleType.length === 0) {
            this.onCloseTrackPopup();
        }     
        if(vehicleType && vehicleType.length){
            if(prevProps.vehicleType && prevProps.vehicleType.length !== vehicleType.length){
                this.updateBounds = true;
                this.props.vehicleType.map(vehicle => {
                    if(vehicle.id === prevState.currentTrackID){
                        updateSelectedpopup = true;
                        }
                        else{
                        updateSelectedpopup = false;
                    }
                })
                if(updateSelectedpopup){
                            this.setState({
                        showTrackingPopup: true
                            })
                        }
                else{
                    this.onCloseTrackPopup();  
                    }
            }
            else if(this.state.showTrackingPopup && this.updateBounds){
                this.updateBounds = false;
            }
        }
    }

    addClusteredResources = (data, layerName) => {
        this.clearTrackLayers();
        // add a clustered GeoJSON source for a sample set of incidents
        if (!this.props.map.getSource(layerName)) {
            this.props.map.addSource(layerName, {
                'type': 'geojson',
                'data': data,
                'cluster': true,
                'clusterRadius': 40,
                'clusterProperties': {
                    // keep separate counts for each magnitude category in a cluster
                    'highStatus': ['+', ['case', highStatusExp, 1, 0]],
                    'mediumStatus': ['+', ['case', mediumsStatusExp, 1, 0]],
                    'lowStatus': ['+', ['case', lowStatusExp, 1, 0]],
                    "clusterTrackIds": ["concat", ["concat", ["get", "trackId"], ","]]
                }
            });

            // circle and symbol layers for rendering individual incidents (unclustered points)
            this.props.map.addLayer({
                'id': layerName,
                'type': 'symbol',
                'source': layerName,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    "icon-image": [
                        "match",
                        [
                            "get",
                            "vehicleType"
                        ],
                        ["Breakers"], 'brush_breaker',
                        ["Rescue"], 'firetruck',
                        ["Engine"], 'firetruck',
                        ["Engines"], 'firetruck',
                        ["Command Cars"], 'car-top-view',
                        'firetruck'
                    ],
                    "icon-rotate": ["get", "heading"],
                    "icon-size": 0.7,
                }
                
            });
            this.props.map.on("click", layerName, this.trackingPopup);
            this.props.map.on("mousemove", layerName, this.addClusterPopup);
            this.props.map.on("mouseleave", layerName, this.closeClusterPopup);
            this.props.map.on("mousemove", layerName, () => {
                this.props.map.getCanvas().style.cursor = "pointer";
            });
            this.props.map.on("mouseleave", layerName, () => {
                if (this.props.isMeasurementOn) {
                    this.props.map.getCanvas().style.cursor = 'crosshair';
                } else {
                    this.props.map.getCanvas().style.cursor = '';
                }
            });
        } else { // If the layer has already been added then just update it with the new data
            this.props.map.getSource(layerName).setData(data);
        }
        if(this.state.currentTrackID){
            data.features.map(list => {
                if(this.state.currentTrackID === list.properties.trackId){
                    this.setState({
                        trackingPopupData: list
                    })
                }
            })
        }
    }

    addClusterPopup = (e) => {
        if (this.clusterPopup) {
            this.clusterPopup.remove();
        }
        const place = e.features[0].properties;
        this.clusterPopup = new mapboxgl.Popup({  closeButton: false, closeOnClick: false, className: `cluster-popup`})
            .setLngLat(e.lngLat)
            .setHTML(`<div class='popup-title'><span class='inginition-status ${place.ignition}'></span>${place.label}</div>`);
            this.clusterPopup.addTo(this.props.map);
    }

    closeClusterPopup = () => {
        if (this.clusterPopup) {
            this.clusterPopup.remove();
        }
    }

    createDonutChart = (props) => {
        const offsets = [];
        const counts = [
            props.highStatus,
            props.mediumStatus,
            props.lowStatus
        ];
        let total = 0;
        for (const count of counts) {
            offsets.push(total);
            total += count;
        }
        const fontSize =
            total >= 50 ? 12 : total >= 20 ? 11 : total >= 10 ? 11 : 10;
        const r =
            total >= 50 ? 32 : total >= 20 ? 24 : total >= 10 ? 18 : 14;
        const r0 = Math.round(r * 0.6);
        const w = r * 2;

        let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle"; font: ${fontSize}px sans-serif;>`;

        for (let i = 0; i < counts.length; i++) {
            html += this.donutSegment(
                offsets[i] / total,
                (offsets[i] + counts[i]) / total,
                r,
                r0,
                colors[i]
            );
        }
        html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})">
            ${total.toLocaleString()}
        </text>
        </svg>
        </div>`;

        var el = document.createElement('div');
        el.innerHTML = html;
        return el.firstChild;
    }

    donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001;
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0),
            y0 = Math.sin(a0);
        const x1 = Math.cos(a1),
            y1 = Math.sin(a1);
        const largeArc = end - start > 0.5 ? 1 : 0;

        // draw an SVG path
        return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
            } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
            } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
            }" fill="${color}" />`;
    }
    removeDonuts = () => {
        if(this.donutMarkers.length){
            this.donutMarkers.map(donut => {
                donut.remove();
            })
        }
        markers = {};
    }
    // objects for caching and keeping track of HTML marker objects (for performance)
    updateMarkers = (trackList) => {
        let that = this;
        const newMarkers = {};
        const features = this.props.map.querySourceFeatures('track-layer');

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;
            if (!props.cluster) continue;
            const id = props.cluster_id;
            let marker = markers[id];
            if (!marker) {
                const el = this.createDonutChart(props);
                el.addEventListener('mouseenter', function (e) {
                    e.target.style.cursor = "pointer";
                    that.addDonutPopup(props, coords, featureData);
                })
                el.addEventListener('mouseleave', function (e) {
                    e.target.style.cursor = "";
                    that.removeDonutPopup();
                })
                el.addEventListener('click', (e) => {
                    this.props.map.getSource('track-layer').getClusterExpansionZoom(
                        id,
                        (err, zoom) => {
                            if (err) return;

                            this.props.map.easeTo({
                                center: coords,
                                zoom: zoom
                            });
                            that.removeDonutPopup();
                        }
                    );
                    el.addEventListener('mouseleave', function (e) {
                        e.target.style.cursor = "";
                        that.removeDonutPopup();
                    })
                })
                this.props.map.on('zoom', () => {
                    that.removeDonutPopup();
                })
                el.addEventListener('touchstart', (e) => {
                    e.preventDefault();
                    this.props.map.getSource('track-layer').getClusterExpansionZoom(
                        id,
                        (err, zoom) => {
                            if (err) return;

                            this.props.map.easeTo({
                                center: coords,
                                zoom: zoom
                            });
                        }
                    );
                })
                marker = markers[id] = new mapboxgl.Marker({
                    element: el
                }).setLngLat(coords);
            }
            this.donutMarkers.push(marker);
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(this.props.map);
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;
    }

    showTrackingLayers(list) {
        this.removeDonuts();
        let trackIds = new Array();
        let trackJson = {
            type: "FeatureCollection",
            features: []
        };
        for (var i in list) {
            trackIds.push(list[i].id);
            trackJson.features.push(this.prepareTrackGeojson(list[i]));
        }      
        featureData = trackJson;      
        this.addClusteredResources(trackJson, 'track-layer');
        this.showSelectedTrack(trackJson, 'selected_track');
        this.resetTrackSelectedLayer(this.props.map, this.state.currentTrackID);
        this.updateMarkers(trackJson);
        this.props.map.on('render', () => {
            if (!this.props.map.getSource('track-layer') || !this.props.map.isSourceLoaded('track-layer'))
                return;
            this.updateMarkers(trackJson);
        });
        if(this.updateBounds){
            this.props.trackingBounds();
        }
    }

    showSelectedTrack(data, layerName) {
        if (!this.props.map.getSource(layerName)) {
            this.props.map.addSource(layerName, {
                type: "geojson", data: data
            });
            this.props.map.addLayer({
                id: layerName,
                type: 'symbol',
                source: layerName,
                layout: {
                    "icon-image": [
                        "match",
                        [
                            "get",
                            "vehicleType"
                        ],
                        ["Breakers"], 'brush_breaker',
                        ["Rescue"], 'firetruck',
                        ["Engine"], 'firetruck',
                        ["Engines"], 'firetruck',
                        ["Command Cars"], 'car-top-view',
                        'firetruck'
                    ],
                    "icon-rotate": ["get", "heading"],
                    "icon-size": 0.9,
                }, 'filter': ['==', 'trackId', '']
            });

            this.props.map.on('mousemove', layerName, () => {
                this.props.map.getCanvas().style.cursor = 'pointer';
            });
            this.props.map.on('mouseleave', layerName, () => {
                if (this.props.isMeasurementOn) {
                    this.props.map.getCanvas().style.cursor = 'crosshair';
                } else {
                    this.props.map.getCanvas().style.cursor = '';
                }
            });
        } else {
            this.props.map.setFilter('selected_track', ['==', 'trackId', '']);
            this.props.map.getSource(layerName).setData(data);
        }
    }

    resetTrackSelectedLayer = (map, trackId) => {
        if (map.getLayer('selected_track')) {
            map.setFilter('selected_track', ['==', 'trackId', trackId]);
        }
    }

    trackingPopup = (e) => {
        const place = e.features[0].properties;
        this.resetTrackSelectedLayer(this.props.map, e.features[0].properties.trackId);
        this.setState({
            showTrackingPopup: true,
            trackingPopupData:  e.features[0],
            currentTrackID: place.trackId
        })
        this.updateBounds = false;
        this.props.setUpdateBounds(false);
    }
    prepareTrackGeojson = (track) => {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: track.geometry && track.geometry.coordinates && track.geometry.coordinates.length ? this.getTrackCoordinates(track) : []
            },
            properties: this.getTrackProperties(track)
        }
    }

    getTrackCoordinates = (obj) => {
        return [
            obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1],
            obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0]
        ]
    }

    getTrackProperties = (obj) => {
        return {
            trackId: obj.id,
            vehicleType: obj.type,
            address: obj.address,
            ignition: obj.ignition,
            label: obj.label,
            odometer: obj.odometer,
            recorded_time: obj.recorded_time,
            runtime_minutes: obj.runtime_minutes,
            speed: obj.speed,
            speed_limit: obj.speed_limit,
            verified_time: obj.verified_time,
            heading: obj.heading,
            speed_label: obj.speed_label
        }
    }

    removeLayer = (layerName) => {
        if (this.props.map.getLayer(layerName)) {
            this.props.map.removeLayer(layerName);

        }
    }

    removeSource = (layerName) => {
        if (this.props.map.getSource(layerName)) {
            this.props.map.removeSource(layerName);

        }
    }

    clearTrackLayers = () => {
        if (this.props.showMap && this.props.map) {
            if (this.state.currentTrackID && this.props.vehicleType && this.props.vehicleType.length) {
                this.props.vehicleType.map(vehicle => {
                    if (this.state.currentTrackID === vehicle.id) {
                        // this.props.preventSetBounds(false);
                    }
                })
            }
            this.props.map.off('click', 'track-layer', this.trackingPopup);
            let aedSource = this.props.map.getSource("track-layer");
            if (typeof aedSource !== "undefined") {
                this.removeLayer('track-layer');
                this.removeSource('track-layer');
            }
            this.removeTrackingMarkers(this.props.map);
        }
    }

    removeTrackingMarkers(map) {
        ['track-layer', 'selected_track'].forEach(layer => {
            if (map.getSource(layer)) {
                this.props.map.off("click", layer, this.trackingPopup);
                map.removeLayer(layer);
                map.removeSource(layer);
            }
        });
        for (var i = 0; i < this.trackingBreadCrumbsLayers.length; i++) {
            if (this.props.showMap && this.props.map && this.props.map.getLayer(this.trackingBreadCrumbsLayers[i])) {
                this.props.map.removeLayer(this.trackingBreadCrumbsLayers[i]);
                this.props.map.removeSource(this.trackingBreadCrumbsLayers[i]);
            }
        }
        this.trackingBreadCrumbsLayers = [];
    }

    swapCoordinates = (arr) => {
        let geometry = [];
        if (arr) {
            if (arr.length > 0) {
                if (arr[0] instanceof Array) {
                    arr.forEach(coordinate => {
                        if (coordinate && coordinate.length > 1) {
                            geometry.push([coordinate[1], coordinate[0]]);
                        }
                    });
                } else {
                    geometry.push([arr[1], arr[0]]);
                }
            }
        }
        return geometry;
    }

    trackingBreadCrumbs = (map, trackingList) => {
        if (map && trackingList) {
            trackingList.map(vehicle => {
                if (vehicle && vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length && vehicle.geometry.coordinates.length > 1) {
                    let coordinates = this.swapCoordinates(vehicle.geometry.coordinates);
                    let layers = this.trackingBreadCrumbsLayers;
                    let lineId = 'trackcircles' + vehicle.id;
                    let polylineId = 'trackpolyline' + vehicle.id;
                    let circleId = 'trackinitCircle' + vehicle.id;
                    layers.push(lineId, polylineId, circleId);
                    this.trackingBreadCrumbsLayers = layers;
                    if (map.getLayer(lineId) || map.getSource(lineId)) {
                        map.removeLayer(lineId);
                        map.removeSource(lineId);
                    }
                    if (!map.getSource(lineId)) {
                        map.addSource(lineId, {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'properties': {},
                                'geometry': {
                                    'type': 'LineString',
                                    'coordinates': coordinates
                                }
                            }
                        });
                    }
                    map.addLayer({
                        'id': lineId,
                        "type": "circle",
                        "source": lineId,
                        "paint": {
                            "circle-radius": 5,
                            "circle-color": vehicle.color

                        }
                    }, 'track-layer');                    
                    if (map.getLayer(polylineId) || map.getSource(polylineId)) {
                        map.removeLayer(polylineId);
                        map.removeSource(polylineId);
                    }
                    if (!map.getSource(polylineId)) {
                        map.addSource(polylineId, {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'properties': {},
                                'geometry': {
                                    'type': 'LineString',
                                    'coordinates': coordinates
                                }
                            }
                        });
                    }
                    map.addLayer({
                        'id': polylineId,
                        "type": "line",
                        "source": polylineId,
                        'layout': {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        'paint': {
                            'line-color': '#888',
                            'line-width': 2
                        }
                    }, lineId);
                    if (map.getLayer(circleId) || map.getSource(circleId)) {
                        map.removeLayer(circleId);
                        map.removeSource(circleId);
                    }
                    if (!map.getSource(circleId)) {
                        map.addSource(circleId, {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: [
                                    {
                                        type: "Feature",
                                        geometry: {
                                            type: "Point",
                                            'coordinates': coordinates[0]
                                        }
                                    }
                                ]
                            }
                        });
                    }
                    map.addLayer({
                        "id": circleId,
                        "type": "circle",
                        "source": circleId,
                        "paint": {
                            "circle-radius": 8,
                            "circle-color": vehicle.color

                        }
                    }, 'track-layer');
                }
            })
        }
    }

    onCloseTrackPopup = (id) => {
        this.setState({
            showTrackingPopup: false,
            currentTrackID: 0
        })
        this.updateBounds = true;
        this.resetTrackSelectedLayer(this.props.map, 0);
        if(this.updateBounds){
            this.props.trackingBounds();
        }
        this.props.setUpdateBounds(true);
    }

    handleRouteTo = (vehicle) => {
        this.props.drawRouteToPolygon([vehicle.properties.trackId]);
        this.props.fetchRouting(true);
        this.props.fetchRenderRouting(0);
        sessionStorage.setItem("routeTo", true);
    }

    componentWillUnmount(){
        this.clearTrackLayers();
        this.props.fetchRouting(false);
        this.removeDonuts();
        this.setState({
            trackingPopupData: null
        })
    }

    addDonutPopup = (donut, coords, trackList) => {
        let filterTrackingList = [], vehicleTypes = [];
        let clusterIdsList = donut.clusterTrackIds.replace(/[, ]+$/, "").trim()
        let filterIdsList = clusterIdsList.split(',');
        trackList.features.filter(function (el) {
            if (el.properties.vehicleType.toLowerCase() !== callDeclarations.allVehicles) {
                vehicleTypes.push(el.properties.vehicleType);
            }
            filterIdsList.map(id => {
                if (el.properties && el.properties.trackId === id) {
                    filterTrackingList.push(el);
                }
            })
        })
        vehicleTypes = [...new Set(vehicleTypes)];
        if (this.donutPopup) {
            this.donutPopup.remove();
        }
        this.donutPopup = new mapboxgl.Popup({ offset: [0, -12], closeButton: false, closeOnClick: false, className: `cluster-popup` })
            .setLngLat(coords)
            .setDOMContent(this.addPopupContent(filterTrackingList, vehicleTypes));
        this.donutPopup.addTo(this.props.map);
    }

    addPopupContent = (trackData, vehicleTypes) => {
        vehicleTypes.push(callDeclarations.allVehicles.toUpperCase());
        let vehicleStatusObj = [];
        vehicleTypes.map(type => {
            let ignitionOffCount = [], ignitionONCount = [], ignitionEmptyCount = [];
            ignitionOffCount = trackData.filter(function (el) {
                return el.properties.ignition === 'off' && type === el.properties.vehicleType;
            })
            ignitionONCount = trackData.filter(function (el) {
                return el.properties.ignition === 'on' && type === el.properties.vehicleType;
            })
            ignitionEmptyCount = trackData.filter(function (el) {
                return el.properties.ignition === '' && type === el.properties.vehicleType;
            })
            trackData.filter(function (el) {
                if (el.properties.vehicleType === type) {
                    vehicleStatusObj.push({ 'type': type, 'ignitionOffCount': ignitionOffCount.length, 'ignitionONCount': ignitionONCount.length, 'ignitionEmptyCount': ignitionEmptyCount.length })
                }
            })
            vehicleStatusObj = [...new Map(vehicleStatusObj.map(item => [item['type'], item])).values()];
        })
        const placeholder = document.createElement('div');
        const jsx = <div className='donut-popup'>
            <h4>Vehicles</h4>
            {
                vehicleStatusObj.map((vehicle) => {
                    return (
                        <div key={vehicle.type} >
                            <div className='d-flex justify-content-between align-items-center text-capitalize'>
                                <span className='street-label'>{vehicle.type === callDeclarations.allVehicles.toUpperCase() ? 'Others' : vehicle.type}:</span>
                                <div className='d-flex justify-content-between align-items-center text-capitalize count-list'>
                                    <div className='on'>{vehicle.ignitionONCount}</div>
                                    <div className='off'>{vehicle.ignitionOffCount}</div>
                                    <div>{vehicle.ignitionEmptyCount}</div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    removeDonutPopup = () => {
        if (this.donutPopup) {
            this.donutPopup.remove();
        }
    }

    render() {
        if(this.props.tabName === tabUrls.call || this.props.tabName === tabUrls.incident){
            if (this.props.vehicleRender === 0 && this.props.vehicleType  && this.props.showTracking  && this.props.vehicleType.length && this.props.map) {
                this.showTrackingLayers(this.props.vehicleType);
                this.trackingBreadCrumbs(this.props.map, this.props.vehicleType);
                this.props.showTrackingList(this.props.vehicleType, 1, false);
            }
            else if(this.props.vehicleRender === 2 && this.props.vehicleType && this.props.showMap && !this.props.showTracking){
                this.clearTrackLayers();
                this.props.showTrackingList(this.props.vehicleType, 1, false);
                if(this.updateBounds){
                    this.props.trackingBounds();
                }
                this.removeDonuts();
                this.removeDonutPopup();
            }
        }
        else if(this.props.showMap){
            this.props.showTrackingList(this.props.vehicleType, 1, false);
            this.clearTrackLayers();
            this.removeDonuts();
            this.removeDonutPopup();
        }
        return (
            <><TrackingPopup show={this.state.showTrackingPopup} hide={this.onCloseTrackPopup} popupData={this.state.trackingPopupData} 
            tabName={this.props.tabName} handleRouteTo={this.handleRouteTo} /></>            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trackingList: state.trackingList ? state.trackingList.trackingList : [],
        vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking,
        vehicleRender: state.showTracking ? state.showTracking.renderCount : state.showTracking,
        showTracking: state.showTracking ? state.showTracking.showTracking : state.showTracking,
        tabName: state.tab.tabName,
        routing: state.routing ? state.routing.routing : state.routing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showTrackingList: (type, renderCount, val) => dispatch(showTrackingList(type, renderCount, val)),
        fetchRouting: (val) => dispatch(fetchRouting(val)),
        fetchRenderRouting: (val) => dispatch(fetchRenderRouting(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDonut);