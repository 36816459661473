import axios from 'axios';
import {serverconfigs, configTimers} from '../constants';

export default class DatasetService {
    uploadPolygon(geojson, uploadGroup){
        let fileName = geojson.id + '.geojson'; 
        let data = new FormData();
        data.append("file", new Blob([JSON.stringify(geojson)], { type: 'application/json' }), fileName);
        return axios.post(serverconfigs.serviceBaseUrl + 'upload/datasets/' + (uploadGroup ? uploadGroup : serverconfigs.username) + '?api_key=' + serverconfigs.apiKey,
        data,
        {
          headers: {            
              'Content-Type': 'application/json'
          }, timeout: configTimers.polygonUploadCallTimeOut
        })
    }

    deletePolygon(polygonId, uploadGroup){
        return axios.delete(serverconfigs.serviceBaseUrl + 'datasets/' + (uploadGroup ? uploadGroup : serverconfigs.username) + '/' + polygonId + '.geojson' +'?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut});
    }

    listPolygon(uploadGroup){
       return axios.get(serverconfigs.serviceBaseUrl + 'datasets/' + (uploadGroup ? uploadGroup : serverconfigs.username) + '?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut});
    }

    getPolygon(polygonUrl){
        return axios.get(polygonUrl + '?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut} );
    }
}