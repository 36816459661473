import React, { Component } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import Authenticate from './components/Auth';
import Map from './components/Map';
import PrivateRoute from './components/PrivateRoute';
import { createBrowserHistory } from "history";
import './assets/comtech-logo.svg';
import { connect } from 'react-redux';
import { getIncidents, getPeerIncidents, toggleNotifications, checkRegionStatus } from './redux/actions/incidentActions';
// import { CallsPanel } from './components/CallsPanel';
import { callback, configTimers, featureLabels, callDeclarations, serverconfigs, errorMessages, customToastId, displayLogs, pingConstants, tabUrls, TrackingRouteColors, groupFeatures, serverUrl, trackingMessages, callsConfigs, incidentConfigs } from './constants';
import AboutInfo from './components/AboutInfo';
import { Amplify, Auth } from "aws-amplify";
import { ToastContainer, toast, Slide } from 'react-toastify';
import './styles/ReactToastify.css';
import { IdleTimerProvider } from 'react-idle-timer'
import Account from './components/Account';
import { isFeatureAllowed, SolacomGroup } from './utils/groupFeatureUtils';
import { setSimulationEnabled,getStationIdFromLocalStorage, getEndCallTimeFromLocalStorage, consoleLog } from './utils/commonUtils';
import Documentation from './components/Documentation';
import AWS from 'aws-sdk';
import AwsIot from 'aws-iot-device-sdk';
import {primaryRegion, secondaryRegion} from "./awsexport-client";
import {getCallsData, callsPanelReloaded, setCallsData, updateEndCallData, setSolacomCallsData} from './redux/actions/callActions';
import {fetchTrackingData, fetchRouting, fetchRenderRouting, showTrackingList, setNetworkStatus} from './redux/actions/headerActions';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

var device, allVendorData = [];
var isIOS = /iPad|iPhone/.test(navigator.userAgent); // to support ipad

const history  = createBrowserHistory();

const ping = () => {
  return new Promise(resolve => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);
    const xhr = new XMLHttpRequest();
    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };
    xhr.open("GET", pingConstants.url);
    xhr.timeout = pingConstants.timeout;
    xhr.send();
  });
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routePath: null,
      addIdleTimer: true,
      online: false,
      timeout: configTimers.idelTimeOutTime,
      trackingError: false,
      showRegionAlert: false,
      stationID: ''
    };
    this.interval;
    this.peersInterval;
    this.pollingId;
    this.isPageActive = true;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.sessionExpiryCount = 0;
    this.awsconfig = primaryRegion;
    this.subscribeTracking = true;
  }

  handleOnAction(event) {
    if (event.type === 'visibilitychange') {
      if (event.target.visibilityState === 'hidden') {
        this.isPageActive = false;
        incidentConfigs.backgroundTimer = new Date().getTime();
      } else {
        this.isPageActive = true;
      }
    }
  }

  // handleOnIdle = (event) => {
  //   if (!this.isPageActive && window.location.pathname !== '/auth') {
  //     this.callExpiryMethod();
  //   }
  // }

  callExpiryMethod = () => {
    Auth.signOut()
      .then(() => {
        let idTokenFeatures = sessionStorage.getItem('idToken');
        let currentUser = sessionStorage.getItem('currentUser');
        var groupFeatures = sessionStorage.getItem('groupProfile');
        var showNotifications = sessionStorage.getItem('showNotifications');
        for (let key in sessionStorage) {
          sessionStorage.removeItem(sessionStorage.key(key));
        }    
        if (isFeatureAllowed(featureLabels.call) && !callDeclarations.isSimulationEnabled) {    
          sessionStorage.setItem('isSimulationEnabled', callDeclarations.isSimulationEnabled);
          sessionStorage.setItem('idToken', idTokenFeatures);
          sessionStorage.setItem('user', currentUser);
          sessionStorage.setItem('groupProfile', groupFeatures);
          sessionStorage.setItem('showNotifications', showNotifications);
        }
        window.location.href = "/auth";
      })
      .catch(e => {
        consoleLog(e);
      });
  }

  setSessionExpiry = () => {
    consoleLog('Getting session: ');
    let session = Auth.currentSession().then((user) => {
      let date = new Date().getTime();
      console.log('Session Expiry Time: ' + ((user.accessToken.payload.exp * 1000) - date));
      let nextTimeout = (user.accessToken.payload.exp * 1000) - date;
      // When access token is expired, allow Amplify for 20 seconds to refresh for a new token.
      setTimeout(() => {
        this.setSessionExpiry();
      }, nextTimeout > 0 ? nextTimeout : configTimers.refreshTokenExpiryTime);
    }).catch(exception => {
      if(exception.message){
        consoleLog('setSessionExpiry Exception::::::: ' + exception.message);
      } else {
        consoleLog('setSessionExpiry Exception::::::: ' + exception);
      }
      setTimeout(() => {
        this.sessionExpiryCount = this.sessionExpiryCount + 1;
        this.setSessionExpiry();
        if(this.sessionExpiryCount === 4) {
          consoleLog('logout');
          this.sessionExpiryCount = 0;
          this.callExpiryMethod();
        }
      }, configTimers.sessionTokenRetry);
    });
  }

  checkIdTokenExpiry = () => {
    consoleLog('::::::::: checking IdToken Expiry');
    let session = Auth.currentSession().then((user) => {
      let date = new Date().getTime();
      console.log("::::::::::::: idToken exp time ::::::::::: " + (user.idToken.payload.exp * 1000));
      if (sessionStorage.getItem('idToken')) {
        sessionStorage.removeItem('idToken');
      }
      sessionStorage.setItem("idToken", user.idToken.jwtToken);
      let nextTimeout = (user.idToken.payload.exp * 1000) - date;
      // When idToken token is expired.
      if (nextTimeout > 0) {
        setTimeout(() => {
          if (device) {
            device.end(true);
          }
          this.checkIdTokenExpiry();
          this.initializeAwsDevice();
        }, nextTimeout > 0 ? nextTimeout : configTimers.refreshTokenExpiryTime);
      } else {
        console.log('::::::::: IdToken Expired :::::::::::::: ');
      }
    }).catch(exception => {
      if (exception.message) {
        consoleLog(':::::::: checkIdTokenExpiry Exception ::::::: ' + exception.message);
        if (exception.message === "Refresh Token has expired") { toast.warning(errorMessages.iDTokenExpireMessage, { toastId: customToastId, autoClose: false, closeButton: false }); }
      } else {
        consoleLog(':::::::::: checkIdTokenExpiry Exception ::::::: ' + exception);
      }
    });
  }

  componentDidMount() {
    var currentRegion = localStorage.getItem('currentRegion')
    if((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region ){
      Amplify.configure(secondaryRegion);
      this.awsconfig = secondaryRegion
      serverUrl.serverBaseUrl = secondaryRegion.baseUrl
      sessionStorage.setItem("base", secondaryRegion.baseUrl);
      consoleLog(":::::::::::: Default Region :::: " + secondaryRegion.aws_project_region)
    } else {
      Amplify.configure(primaryRegion);
      this.awsconfig = primaryRegion
      serverUrl.serverBaseUrl = primaryRegion.baseUrl
      sessionStorage.setItem("base", primaryRegion.baseUrl);
      consoleLog(":::::::::::: Default Region :::: " + primaryRegion.aws_project_region)
    }

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (newLocation.pathname !== this.currentPathname) {
          this.currentPathname = newLocation.pathname;
          history.push({
            pathname: newLocation.pathname
          });
        }
      }else if (action === "POP") {
        if (newLocation.pathname !== this.currentPathname) {
          this.currentPathname = newLocation.pathname;
          history.push({
            pathname: newLocation.pathname
          });
        }
      } else {
        if (sessionStorage.getItem('idToken') && sessionStorage.getItem('currentUser') && (history.location.pathname === '/auth' || history.location.pathname === '/')) {
          this.callExpiryMethod();
        }
      }
    });
    setSimulationEnabled();
    // Executes only if "Calls" tab is present in Group profile
    if ((isFeatureAllowed(featureLabels.call) || isFeatureAllowed(featureLabels.incident))) {
      if (!isIOS && Notification.permission != 'granted') {
        Notification.requestPermission().then(function (permission) {
          if (permission != 'granted') {
            console.log(errorMessages.browserNotifPermMsg);
          }
        });
      }
      var showNotifications = sessionStorage.getItem('showNotifications');
      if (showNotifications != null && showNotifications === 'true') {
        this.checkIdTokenExpiry();
      }
      this.initializeAwsDevice();
    }
    if (sessionStorage.getItem('currentUser') !== null) {
      var groupName = JSON.parse(sessionStorage.getItem('groupProfile'));
      this.setSessionExpiry();
      if(isFeatureAllowed(featureLabels.tracking) && (isFeatureAllowed(featureLabels.incident) || isFeatureAllowed(featureLabels.call))){
        this.getTrackingData();
      }
      if (isFeatureAllowed(featureLabels.incident)) {
        this.props.getIncidents();
        if (!callback.callback) {
          setInterval(() => {
            if (this.isPageActive) {
              this.props.getIncidents();
              incidentConfigs.backgroundTimer = null;
            }
          }, configTimers.getIncidentsReqTime);
          callback.callback = true;
          // setInterval(() => {
          //   this.checkRegionUpdate()
          // }, 5000);
        }
        this.peersInterval = setInterval(() => {
          if (this.isPageActive) {
            if(groupName && groupName.peers && groupName.peers.length && this.props.selectedPeersValue && this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length){
              if(this.props.peersApiResp || (this.props.peersApiResp && this.props.peersApiResp.length))
                this.props.getPeerIncidents(groupName.peers);
            }
          }
        }, configTimers.getIncidentsReqTime);
      } else {
        consoleLog(":::::: FC Incidents disabled ::::: ")
        setInterval(() => {
          if (this.isPageActive) {
            this.props.checkRegionStatus();
            // this.checkRegionUpdate()
          }
        }, configTimers.multiRegionReqTime);
      }
      if (isFeatureAllowed(featureLabels.call) && !callDeclarations.isSimulationEnabled) {
        if(!SolacomGroup()){
        this.props.getCallsData();
        }
        callDeclarations.notificationFromUrl = true;
        setInterval(() => {
          this.startEndCallTimer();
        }, callsConfigs.endCallLoopTime);
      }
      this.checkRegionUpdate();
    }
    toast.dismiss()
    window.addEventListener("online", this.goOnline);
    window.addEventListener("offline", this.goOffline);
    if (this.pollingId) {
      this.stopPolling();
    }        
  }

  componentDidUpdate = (prevProps) => {
    const { vehicleType, regionName } = this.props;
    var groupName = JSON.parse(sessionStorage.getItem('groupProfile'));
    var showNotifications = sessionStorage.getItem('showNotifications');
    if(groupName && groupName.peers && groupName.peers.length && this.props.selectedPeersValue && this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length){
      if(!this.props.peersApiResp || (this.props.peersApiResp && this.props.peersApiResp.length == 0))
        this.props.getPeerIncidents(groupName.peers);
    }
    if(groupName && groupName.peers && groupName.peers.length === 0 && this.props.selectedPeersValue && this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length === 0){
      clearInterval(this.peersInterval);
    }
    if(vehicleType && vehicleType.length && this.subscribeTracking){
      this.subscribeToTracking();
    }
    else if(prevProps.vehicleType && vehicleType && vehicleType.length === 0 && !this.subscribeTracking){
      this.unScribeToTracking();
    }

    if(prevProps.regionName != regionName)
        this.checkRegionUpdate();

    if(!prevProps.regionName)
        this.checkRegionUpdate();

    if (this.props.stationID && this.props.stationID != null && this.props.stationID.length > 0 && this.props.toggleNotifications && device && showNotifications) {
      this.subscribeToGroup(this.props.stationID);
      this.props.setCallsData(null);
      this.props.setNotifications();
      if(!SolacomGroup()){
      this.props.getCallsData();
      }
    }
  }

  goOnline = () => {
    console.log("Online");
    this.stopPolling();
    toast.dismiss()
    this.props.setNetworkStatus(true)
    this.checkIdTokenExpiry();
    this.setState({showRegionAlert:false });
    clearInterval(this.pollingId);
    toast.dismiss()
  }

  goOffline = () =>  {
    this.setState({ online: false }, () => {
      this.props.setNetworkStatus(false)
    });
    if (!toast.isActive(customToastId)) {
      console.log("network down");
      toast.error(errorMessages.networkError, { toastId: customToastId, autoClose: false, closeButton: false });
    }
  }

  startPolling() {
    consoleLog(':::::::::::: startPolling ::::::: ');
    this.pollingId = setInterval(() => {
      consoleLog(':::::::::::: startPolling requestPing ::::::: ');
      this.requestPing();
    }, pingConstants.interval);
  }

  requestPing() {
    ping().then(netStatus => {
      consoleLog(':::::::::::: netStatus ::::::: ' + netStatus);
      this.setState({ online: netStatus }, () => {
        netStatus ? this.goOnline() : this.goOffline();
        if (!netStatus) {
          toast.error(errorMessages.networkError, { toastId: customToastId, autoClose: false, closeButton: false });
        } else {
          clearInterval(this.pollingId);
          toast.dismiss()
        }
      });
    });
  }

  stopPolling() {
    clearInterval(this.pollingId);
    consoleLog(':::::::::::: Stop requestPing ::::::: ');
    this.pollingId = null
  }

  unScribeToGroup = () => {
    var stationID = getStationIdFromLocalStorage(), topic, that = this;
    if (device && stationID != null && stationID.length > 0) {
      if(SolacomGroup()){
        topic = callDeclarations.sim911Topic + callDeclarations.solacomTopic;
      }
      else{
        topic = callDeclarations.sim911Topic + stationID;
      }
      device.unsubscribe(topic, function (err) {
        if (err) {
          console.error(err);
        } else {
          that.clearSessionStorage();
          console.log("::::::::::::: unsubscribe to " + topic);
        }
      });      
    }
  }

  clearSessionStorage = () => {
    // clear the session when user signout
    if (sessionStorage.getItem('currentUser') === null) {
      for (let key in sessionStorage) {        
        sessionStorage.removeItem(sessionStorage.key(key));
      }
    }
  }

  subscribeToGroup = (stationID) => {
    var topic, that = this;
    if (stationID) {
      if(SolacomGroup()){
        topic = callDeclarations.sim911Topic + callDeclarations.solacomTopic;
      }
      else{
        topic = callDeclarations.sim911Topic + stationID;
      }
        device.subscribe(topic, function (err) {
          if (err === null) {
            that.clearSessionStorage();
            console.log("::::::::::::: subscribed to " + topic);
          } 
        });
    }
  }

  subscribeToTracking = () => {
    var groupName = JSON.parse(sessionStorage.getItem('groupProfile'));
      var vehicleTopic = callDeclarations.vehicleTopic +  groupName.name;
      var that = this;
      if(device){
        device.subscribe(vehicleTopic, function (err) {
          if (err === null) {
            that.clearSessionStorage();
            console.log("::::::::::::: subscribed to " + vehicleTopic);
            that.subscribeTracking = false;
          }
        });
      }
  }

  unScribeToTracking = () => {
    var groupName = JSON.parse(sessionStorage.getItem('groupProfile'));
    var vehicleTopic = callDeclarations.vehicleTopic +  groupName.name;
    var that = this;
    if (device && vehicleTopic != null && vehicleTopic.length > 0) {
      device.unsubscribe(vehicleTopic, function (err) {
        if (err) {
          console.error(err);
        } else {
          console.log("::::::::::::: unsubscribe to " + vehicleTopic);
          that.subscribeTracking = true;
        }
      });      
    }
  }

  initializeAwsDevice = () => {
    let idTokenFeatures = sessionStorage.getItem('idToken');
    var showNotifications = sessionStorage.getItem('showNotifications');
    if (idTokenFeatures != null && idTokenFeatures.length > 0) {
      // Configure the AWS region
      AWS.config.region = this.awsconfig.aws_project_region;
      // Configure the auth client
      let login = {};
      let loginKey = 'cognito-idp.' + this.awsconfig.aws_project_region + '.amazonaws.com/' + this.awsconfig.aws_user_pools_id;
      login[loginKey] = idTokenFeatures;

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.awsconfig.aws_cognito_identity_pool_id, // <COGNITO_IDENTITY_POOL_ID>
        Logins: login
      });

      AWS.config.credentials.refresh((error) => {
        if (error === null) {
          try {
            const response = new AWS.Iot().attachPrincipalPolicy({
              policyName: serverconfigs.notificationPolicy,
              principal: AWS.config.credentials.identityId
            }).promise();
            let clientID = serverconfigs.notificationClientIdSuffix + new Date().getTime(); //needs to be unique
            device = AwsIot.device({
              region: this.awsconfig.aws_notification_region,
              clientId: clientID,
              host: this.awsconfig.aws_notification_host, // can be queried using 'aws iot describe-endpoint --endpoint-type=iot:Data-ATS'
              autoResubscribe: true,
              offlineQueueing: false,
              minimumConnectionTimeMs: serverconfigs.notificationReconnectTime,
              protocol: 'wss',
              accessKeyId: AWS.config.credentials.accessKeyId,
              secretKey: AWS.config.credentials.secretAccessKey,
              sessionToken: AWS.config.credentials.sessionToken
            });            
            this.connectAwsIot();
          } catch (err) {
            // console.error(err); // an error occurred
          }
        }
      });
    }
  }

  startCallTimer = () => {
    this.interval = setInterval(() => {
      let initCount = 0;
      callDeclarations.callstartTime = callDeclarations.callstartTime + 1;
      let timerSeconds = parseInt(callDeclarations.callTimerSeconds) + 1;
      callDeclarations.callTimerSeconds = timerSeconds === 60 ? initCount : timerSeconds;
      callDeclarations.callTimerMinutes = timerSeconds === 60 ? (parseInt(callDeclarations.callTimerMinutes) + 1) : parseInt(callDeclarations.callTimerMinutes);
    }, configTimers.counterTime);
  }

  swapCoordinates(arr) {
    let geometry = [];
    if (arr) {
      if (arr.length > 0) {
        if (arr[0] instanceof Array) {
          arr.forEach(coordinate => {
            if (coordinate && coordinate.length > 1) {
              geometry.push([coordinate[1], coordinate[0]]);
            }
          });
        }
      }
    }
    return geometry;
  }
  connectAwsIot = () => {
    // Do on device connect
    device.on('connect', () => {
      console.log("::::::::::::: Connected  ");
      var stationID = getStationIdFromLocalStorage();
      var showNotifications = sessionStorage.getItem('showNotifications') == 'true' ? true : false;
      toast.dismiss()
      if(SolacomGroup()){
        this.subscribeToGroup(stationID);
      }
      else{
      if (stationID && stationID != null && stationID.length > 0 && !callDeclarations.isSimulationEnabled && showNotifications) {
        this.subscribeToGroup(stationID);
        }
      }
      if(this.props.vehicleType && this.props.vehicleType.length && this.subscribeTracking){
        this.subscribeToTracking();
      }
    });
    device.on('error', (data) => {
      consoleLog("error");
    });
    device.on('message', (topic, payload) => {
      var groupName = JSON.parse(sessionStorage.getItem('groupProfile'));
      // var showNotifications = sessionStorage.getItem('showNotifications');
      // if (showNotifications != null && showNotifications === 'true') {
        callDeclarations.notificationFromUrl = false;
        if(groupName && topic === (callDeclarations.vehicleTopic+groupName.name)){
          consoleLog(':::::::::::: vehicle payload ::::::: ' + payload);
          let updateVehicleList = JSON.parse(payload);
          let trackingData = allVendorData;
          var selectedVehicleList = this.props.vehicleType;
          if (updateVehicleList.vehicles && updateVehicleList.vehicles.length) {
            if (trackingData && trackingData.length) {
              trackingData.map((vehicle, ind) => {
                updateVehicleList.vehicles.map(updatedVehicle => {
                  if (vehicle.id === updatedVehicle.id) {
                    trackingData[ind] = updatedVehicle;
                  }
                })
              })
              if(selectedVehicleList.length){
                selectedVehicleList.map((vehicle, ind) => {
                  updateVehicleList.vehicles.map(updatedVehicle => {
                    if (vehicle.id === updatedVehicle.id) {
                      selectedVehicleList[ind] = updatedVehicle;
                  }
                })
              })
              }
              trackingData.map((vehicle, ind) => {
                if (vehicle && vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length && vehicle.geometry.coordinates.length > 1){
                  vehicle.color = TrackingRouteColors[ind];
                }
              })
              this.props.showTrackingList(selectedVehicleList, 0, true);
            }
            this.props.fetchTrackingData(trackingData);
            if(this.props.tabName === tabUrls.call){
              if(this.props.routing || sessionStorage.getItem("routeTo") == 'true'){
                this.props.fetchRenderRouting(0);
                this.props.fetchRouting(true);
              }
            }
          }
          if(!this.props.vehicleType || this.props.vehicleType.length === 0){
            this.unScribeToTracking();
          }
        }
        else{        
          var d = new Date();
          var day = d.getUTCDate();
          var mon = d.getUTCMonth();
          var year = d.getUTCFullYear();
          var hours = d.getUTCHours();
          var min = d.getUTCMinutes();
          var sec = d.getUTCSeconds();
          var milsec = d.getUTCMilliseconds();
          console.log('::::::::::::: Message Received Time :::: ' + year + "-" + (mon+1) + "-" + day + " " + hours + ":" + min + ":" + sec + ":" + milsec);
          consoleLog(':::::::::::: Message payload ::::::: ' + payload);
          let data = JSON.parse(payload);
          data.routesList = [];
          data.cityRadius = data.radius;  
          data.radius = data.radius >= 0 ? data.radius : 0;
          if(data.rapidSOS){
            data.rapidSOS.radius = data.rapidSOS.radius >= 0 ? data.rapidSOS.radius : 0
          }
          if (SolacomGroup()) {
            data.nenaIncidentId = data.callIdentifier;
            if (data.routes && data.routes.length) {
              data.routesList.push(...data.routes);
            }
            if (data.agents && data.agents.length) {
              data.agents.map(agent => {
                if (agent.routes && agent.routes.length) {
                  data.routesList.push(...agent.routes);
                }
              })
            }
            if (data.geometry && data.geometry.coordinates && data.geometry.coordinates.length) {
              data.geometry.coordinates = this.swapCoordinates(data.geometry.coordinates);
            }
            this.props.setSolacomCallsData(data);
            if ((data.callStateExt === 'transferred' || data.callState === callsConfigs.activeStatus || data.callState === callsConfigs.released) && data.agents && data.agents.length) {
              if (getStationIdFromLocalStorage() === data.agents[0].agent) {
                if (data.geometry && data.geometry.coordinates && data.geometry.coordinates.length > 1) {
                  if (data.callStateExt === 'transferred' && data.callState === callsConfigs.activeStatus) {
                    data.eventType === 'TransferCall';
                  }
                  else if (data.callStateExt === '' && data.callState === callsConfigs.activeStatus) {
                    data.eventType === 'Answer';
                  }
                  else if (data.callState === callsConfigs.released) {
                    data.eventType === 'Released';
                  }
                }
                this.props.setCallsData(data);
              }
            }
            else if (data.eventType === 'EndCall') {
              if (data.callState === callsConfigs.endStaus && getStationIdFromLocalStorage() === data.agents[0].agent) {
                this.props.setCallsData(data);
              }
            }
          }
          else{
          if (this.props.callData === '' && data.callState === callsConfigs.endStaus) {
            this.props.setCallsData(null);
          }
          else{
              data.callState = callsConfigs.activeStatus;
            this.props.setCallsData(data);
            }
          }
          this.props.callsPanelReloaded(1);
          callDeclarations.callstartTime = 0;
          if (data && data != '' && sessionStorage.getItem('currentUser') != null) {
            clearInterval(this.interval);
            this.startCallTimer();
          }
          if (!isIOS && Notification.permission === 'granted') {
            if (!this.isPageActive) {
              this.showNotification();
            } else if (window.location.pathname === '/auth') {
              this.showNotification();
            }
          } else {
            consoleLog('::::::::::::: Browser Notifications popup permissions are blocked');
          }
        }
      // }
    });

    device.on('disconnect', (data) => {
      console.log("disconnect");
    });
    device.on('unsubscribe', (data) => {
      console.log("::::::::::::::: unsubscribe ::::::::::::::::::");
    });
    device.on('offline', (data) => {
      console.log("offline");      
      this.requestPing();
      this.startPolling();
    });
	  device.on('close', (data) => {
    });

    device.on('reconnect', (data) => {
      console.log("reconnect");
      if(this.state.online) {
          consoleLog("::::::::::::: Reconnect AWS ::::: " )
          this.stopPolling();
          device.end(true);
          this.initializeAwsDevice();
      } 
    });
  }
  // create and show the notification
  showNotification = () => {
    consoleLog('::::::::::::: showNotification :::: ');
    const notification = new Notification(serverconfigs.title, {
      body: serverconfigs.notificationBody,
      icon: '../assets/Comtech_logo_V2.jpg'
    });

    // close the notification after 10 seconds
    setTimeout(() => {
        notification.close();
    }, 10 * 1000);
    // navigate to a URL when clicked
    notification.addEventListener('click', (event) => {
        consoleLog('On notification click: ');
        event.preventDefault();
        var win = window.open('', '_top', '');
        win.focus();
    });
}

handleSimulationCallsData = () => {  
  var stationID = getStationIdFromLocalStorage()
  var showNotifications = sessionStorage.getItem('showNotifications') == 'true' ? true : false;
  var enableSimulation = sessionStorage.getItem('isSimulationEnabled') == 'true' ? true : false;
    callDeclarations.callstartTime = 0;
    callDeclarations.callTimerSeconds = 0;
    callDeclarations.callTimerMinutes = 0;
    callDeclarations.oldNinaId = '';
    clearInterval(this.interval);
  if(!enableSimulation && showNotifications) {
    this.props.setCallsData(null);
    this.props.callsPanelReloaded(1);
    this.subscribeToGroup(stationID);
  } else {  
    this.unScribeToGroup();
    // toast.dismiss();  
  }
}

handleNotificationSwitch = () => {
  var stationID = getStationIdFromLocalStorage()  
  if (stationID != null && stationID.length > 0 && isFeatureAllowed(featureLabels.call)) {
    var showNotifications = sessionStorage.getItem('showNotifications');
    if (showNotifications === 'true') {
      if(!device){
        this.initializeAwsDevice();
      }
      else if (!callDeclarations.isSimulationEnabled) {
          this.subscribeToGroup(stationID);          
        }
    } else {
      this.unScribeToGroup();
    }
  } else {
    consoleLog("::::::::: do nothing ::::::::::: ");
  }
}

getTrackingData = () => {
    let groupVendor = '', vendorList = '';
    var groupName = groupFeatures;
    if (groupName && groupName.avl_vendor) {
      vendorList = groupName.avl_vendor;
      if(Array.isArray(vendorList.length)){
        for (let i = 0; i < vendorList.length; i++) {
          if (groupVendor === '') {
            groupVendor += vendorList[i];
          } else {
            groupVendor += ',' + vendorList[i];
          }
        }
      }
      else{
        groupVendor = groupName.avl_vendor;
      }
      axios.get(`${serverconfigs.trackingUrl}&vendor=${groupVendor}`).then(response => {
        if (response.status == 200) {
          this.setState({
            trackingError: false
          })
          let trackingData = response.data;
          if(trackingData.length && trackingData){
            trackingData.map(vendor => {
              if(vendor.vehicles && vendor.vehicles.length) {
                vendor.vehicles.map(vehicle => {
                  allVendorData.push(vehicle);
                })
              }
            })
          }
          if (allVendorData.length) {
            allVendorData.map((vehicle, ind) => {
              if (vehicle && vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length && vehicle.geometry.coordinates.length > 1) {
                vehicle.color = TrackingRouteColors[ind];
              }
            })
            this.props.fetchTrackingData(allVendorData);
          }
          else {
            this.setState({
              trackingError: true
            })
            toast.warning(trackingMessages.noVehicleData, { toastId: customToastId });
          }
        }
      })
        .catch(error => {
          this.setState({
            trackingError: true
          })
          toast.error(error.message, { toastId: customToastId });
        });
    }
    else{
      toast.warning(trackingMessages.noVendorList, { toastId: customToastId });
    }
}

checkRegionUpdate = () => {
  if(this.props.regionName  && this.props.regionName != ""){
    var currentRegion = localStorage.getItem('currentRegion')
    if(this.props.regionName != currentRegion) {
      this.setRegionDownAlert(true)
    }
  }
}

startEndCallTimer() {
  var endCallTimer = getEndCallTimeFromLocalStorage()
  if (endCallTimer && endCallTimer != null && endCallTimer != '' && endCallTimer > 0) {
    let tempArray = [];
    if (this.props.endCallsData && this.props.endCallsData.length) {
      this.props.endCallsData.map(callData => {
        var difference = new Date().getTime() - callData.disconnectTime;
        var diffInMin = Math.round(difference / 1000);
        if (diffInMin < endCallTimer) {
          tempArray.push(callData);
        }
      })
      if (this.props.endCallsData.length != tempArray.length) {
        this.props.updateEndCallData(tempArray);
        this.props.callsPanelReloaded(1)
      }
    }
  }
}

setRegionDownAlert = (setShow) => {
  this.setState({
    showRegionAlert : setShow
  })
  consoleLog(":::::::::::::::: show Region Down Alert ::::::::::::: " + this.props.regionName)
}

  regionSignOut = () => {
    localStorage.setItem('currentRegion', this.props.regionName)
    Auth.signOut()
      .then(() => {
        for (let key in sessionStorage) {
          sessionStorage.removeItem(sessionStorage.key(key));
        }
        window.location.href = "/auth";
      })
      .catch(e => {
        consoleLog(e);
      });
  }

render() {
    const path = createBrowserHistory().location.pathname;
    return (
    <>
    <BrowserRouter>
          <Header 
            getTrackingData={sessionStorage.getItem('currentUser') !== null ? this.getTrackingData : null} 
            trackingError={this.state.trackingError}
          />
            <Routes>
              <Route exact path="/auth" element={<Authenticate />} />
              <Route element={<PrivateRoute />}>
                <Route path="calls" element={<Map />} />
                <Route path="account" element={<Account sendProps={this.handleNotificationSwitch} handleSimulation={this.handleSimulationCallsData} />} />
                <Route path="about" element={<AboutInfo />} />
                <Route path="documentation" element={<Documentation />} />
              </Route>
              <Route path="*" element={<Authenticate />} />
              {/* <Route exact  path="/calls" element={<PrivateRoute><Map/></PrivateRoute>} /> */}
              {/* <Route exact  path="/account" element={<PrivateRoute></PrivateRoute>} /> */}
              {/* <PrivateRoute exact path="/account" element={sessionStorage.getItem('currentUser') !== null ? 
            <Account sendProps={this.handleNotificationSwitch} handleSimulation={this.handleSimulationCallsData} /> : ''} /> */}
              {/* <Route exact  path="/about" element={<PrivateRoute><AboutInfo/></PrivateRoute>} />
            <Route exact  path="/documentation" element={<PrivateRoute><Documentation/></PrivateRoute>} /> */}
            </Routes>
        
        <Footer routePath={this.state.routePath} />
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        closeButton={false}
        transition={Slide}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
        <IdleTimerProvider
              timeout={this.state.timeout}
              onAction={this.handleOnAction}
            />

<Modal className="region-down-signout" show={this.state.showRegionAlert}
        hide={this.state.showRegionAlert}
        size="sm"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
                    <Modal.Header>
                      <Modal.Title>Sign Out</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>{errorMessages.regionDownMsg}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant="primary" onClick={this.regionSignOut}>
                            Sign Out
                        </Button>
                    </Modal.Footer>
                </Modal>      
    </>)
  }
}

const mapStateToProps = (state) => {
  return {
    callData: state.calls ? state.calls.data : state.calls,
    renderCalls: state.renderCalls ? state.renderCalls.renderCalls : state.renderCalls,
    routing: state.routing ? state.routing.routing : state.routing,
    vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking,
    tabName: state.tab.tabName,
    selectedPeersValue: state.selectedPeersValue,
    peersApiResp: state.peersApiResp.peersApiResp,
    regionName: state.regionName ? state.regionName.regionName : "",
    endCallsData: state.calls ? state.calls.endCallsData : state.calls,
    stationID: state.stationID ? state.stationID.stationID : state.stationID,
    toggleNotifications: state.stationID ? state.stationID.toggleNotifications : state.stationID
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidents: () => dispatch(getIncidents()),
    getCallsData: () => dispatch(getCallsData()),
    callsPanelReloaded: (val) => dispatch(callsPanelReloaded(val)),
    setCallsData: (val) => dispatch(setCallsData(val)),
    fetchTrackingData: (val) => dispatch(fetchTrackingData(val)),
    fetchRouting: (val) => dispatch(fetchRouting(val)),
    fetchRenderRouting : (val) => dispatch(fetchRenderRouting(val)),
    showTrackingList: (type, renderCount, val) => dispatch(showTrackingList(type, renderCount, val)),
    setNetworkStatus: (val) => dispatch(setNetworkStatus(val)),
    getPeerIncidents: (val) => dispatch(getPeerIncidents(val)),
    updateEndCallData: (val) => dispatch(updateEndCallData(val)),
    setSolacomCallsData: (val) => dispatch(setSolacomCallsData(val)),
    setNotifications: () => dispatch(toggleNotifications()),
    checkRegionStatus: () => dispatch(checkRegionStatus())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
