import React from "react";
import { BsDownload } from "react-icons/bs";
import mapboxgl from 'mapbox-gl'
import RulerControl from './ruler/Ruler';
import Spinner from 'react-bootstrap/Spinner';
import { serverconfigs, incidentConfigs, customToastId, groupLocations, tabUrls, splitterConfigs, groupFeatures, featureLabels, callDeclarations, psapLayerBounds, routingRadius, configTimers, cancelErrorMessages,errorMessages, successMessages, massGISAPLayerBound, clickableLayers, streetLayerIds, googleApiKey, mapStyleLayers, trackingMessages, callsConfigs } from '../constants';
import Sidebar from "./Sidebar";
import GifControl from './dopplerControl';
import { addRasterLayer, removeGifLayers, clearFrames } from './dopplerControl';
import { connect } from 'react-redux';
import { fetchMapLoaded, setPauseZoom, setIncidentAutoZoom, setSecondMapPath } from '../redux/actions/incidentActions';

import { fetchRenderHistory } from '../redux/actions/historyActions';
import '../../node_modules/mapbox-gl/dist/mapbox-gl.css'
import '../styles/geoservices-ctrl.css'
import '../styles/map.scss';
import '../styles/markerPopup.scss';
import '../styles/doppler-control.css';
import '../assets/btn_locate_off.png';
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import DrawPolygon from "./modals/DrawPolygon";
import EditPolygon from "./modals/EditPolygon";
import DatasetService from '../services/dataset.services';
import { toast } from 'react-toastify';
import { fetchRenderMapThemes, fetchRenderWeather, fetchRenderUncertainity, fetchSplitScreen, fetchRouting, fetchRenderRouting, fetchMapThemeUrl, fetchAllMapLayers, fetchWeather, fetchLoginStatus, showTrackingList, fetchAllSourceLayers, setStreamingStatus } from '../redux/actions/headerActions';
import { setTabName } from '../redux/actions/tabActions';
import SearchAdd from "./SearchAdd";
import Intersection_Off from '../assets/icon_intersection_off.png';
import comtechLogo from '../assets/comtech-logo.svg'
import Intersection_On from '../assets/icon_intersection_on.png';
import POI_Off from '../assets/icon_poi_off.png';
import POI_On from '../assets/icon_poi_on.png';
import W3W_Search_On from '../assets/icon_w3w_on.png';
import W3W_Search_Off from '../assets/icon_w3w_off.png';
import latlng_Off from '../assets/icon_latlon_off.png';
import latlng_On from '../assets/icon_latlon_on.png';
import SearchIntersect from "./SearchIntersect";
import { isFeatureAllowed, SolacomGroup } from '../utils/groupFeatureUtils';
import RouteServices from '../services/route.service';
import { createRoot } from 'react-dom/client';
import Button from 'react-bootstrap/Button';
import { BsX } from "react-icons/bs";
import turfDistance from '@turf/distance';
import axios from 'axios';
import AddressMarkers from "./AddressMarkers";
import SearchWhat3Words from "./SearchWhat3Words";
import {callsPanelReloaded, setCallAutoZoom} from '../redux/actions/callActions';
import { Auth } from "aws-amplify";
import VehicleDonut from './VehicleDonut';
import AddressPointSearch from "./AddressPointSearch";
import Alert from 'react-bootstrap/Alert';
import DiscrepancyService from '../services/Discrepancy.service';
import DiscrepancyReport from "./modals/DiscrepancyReport";
import moment from "moment";
import { isPointedToSecondaryRegion, consoleLog, calculateIndoorBBbox } from '../utils/commonUtils';
import { Loader } from '@googlemaps/js-api-loader';
import UploadIndoorMap from "./modals/UploadIndoorMap";
import IndoorMapService from '../services/IndoorMap.services';
import IndoorDropDownArrow from '../assets/icon_dropdown.svg';

var cancelTokenSource;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#0068aE ' : '#333',
        backgroundColor: state.isSelected ? 'transparent' : 'transparent',
        fontWeight: 500,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10
    }),
    menu: (provided, state) => ({
        ...provided,
        maxHeight: 180,
        overflow: 'auto'
    }),
    control: () => ({
        width: 200,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const measurementLableValue = 0.10;

const loader = new Loader({
    apiKey: googleApiKey.wrapperKey,
    version: "weekly",
    libraries: ["places"],
    nonce: googleApiKey.nonceValue
});

class Map extends React.Component {
    map;
    satelliteMap;
    popup; // mapboxgl.Popup
    timer; // Camera update timer
    draw;
    tooltipObj;
    marker;
    customControl;

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.mapRef2 = React.createRef();
        this.mapRef3 = React.createRef();
        // this.mapsService = new MapsService();
        // this.map = this.props.defaultMap ? this.props.defaultMap.defaultMap : {},
        // this.satelliteMap = this.props.satelliteMap ? this.props.satelliteMap.satelliteMap : {},
        this.datasetService = new DatasetService();
        this.RouteServices = new RouteServices();
        this.IndoorMapService = new IndoorMapService();
        this.isPolygonEnable = false;
        this.rulerControl = new RulerControl({
            units: 'miles',
            labelFormat: n => {
                let label = n.toFixed(2);
                if (label > measurementLableValue) {
                    return `${label} ml`
                } else {
                    let labelInFeet = label * 5280 // converting miles into feet ft=mi*5280
                    return `${labelInFeet.toFixed(2)} ft`
                }
            },
        });
        this.polylineId = [];
        this.stationsList = [];
        this.popups = [];
        this.poiRoutePopup = [];
        this.trackingPopups = [];
        this.trackingPolylineId = [];
        this.updateBounds = true;
        this.googleMap = null,
        this.state = {
            secondaryView: '',
            styles: null,
            places: [],
            layerData: [],
            customSources: [],
            selectedStyle: '',
            selectedOption: '',
            showSpinner: true,
            isMapLoaded: false,
            isSatelliteMapLoaded: false,
            satelliteMapError: false,
            fullImage: '',
            hugeImage: '',
            currentTab: '',
            showOptions: false,
            showWeather: false,
            showTraffic: false,
            showRadius: true,
            showRadiusLayers: true,
            showWeatherTraffic: false,
            showHistory: false,
            isSingleMap: false,
            showMapTheme: false,
            showMap: false,
            showPolygon: false,
            polygonDialog: false,
            showEditPloygon: false,
            polygons: [],
            polygonValues: {
                name: '',
                description: ''
            },
            showSearch: false,
            search: 'ADDRESS',
            isMeasuring: false,
            showLocateIcon: false,
            currentTabName: tabUrls.call,
            clearpolygons: true,
            isDrawPolygon: false,
            resizing: false,
            secondaryPaneSize: splitterConfigs.secondaryPaneSize,
            dividerPosition: splitterConfigs.dividerPosition,
            primaryPaneSize: splitterConfigs.primaryPaneSize,
            renderPoi: false,
            routeCoordinates: {},
            resetCallsEndCall: false,
            routeToList: [],
            enableIncidentAutoZoom: this.props.incidentAutoZoom ? true : false,
            enableCallAutoZoom: this.props.callAutoZoom ? true : false,
            addressPointPOI: false,
            pointFeature: null,
            showReportPopup: false,
            reportPopupData: null,
            savedRouting: 0,
            showIndoorModal: false,
            indoorMapsList: [],
            showIndoorMap: false,
            activeIndoorObj: null
        }
        this.DiscrepancyService = new DiscrepancyService();
        this.streetPoipopups = [];
    }

    static getDerivedStateFromProps(props, state) {
        if(props.cancelError){
            state.showSpinner = false
        }
        return state
    }

    componentDidMount = () => {
        let that = this;
        document.body.className = 'incident-bg';
        document.addEventListener("showFeed", function (e) {
            if (e) {
                if (e.detail.text === "Historical") {
                    that.setState({
                        currentTab: e.detail.text
                    })
                }
                else {
                    that.setState({
                        currentTab: e.detail.text
                    })
                }
            }
        })
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);
        if(this.props.cancelError){
            this.setState({
                showSpinner: false
            })
        } 
        if(isFeatureAllowed(featureLabels.indoorMap)){
            this.getUserIndoorMapsList();
        }
        this.loadMap();
        this.props.fetchRenderMapThemes(0);
        this.props.fetchMapThemeUrl(this.props.mapUrl);
        this.props.setTabName(this.props.tabName);
        
        if(this.props.tabName === tabUrls.call || this.props.tabName === tabUrls.incident){
            this.props.showTrackingList(this.props.vehicleType, 0, true);
        }
        if (isFeatureAllowed(featureLabels.call) && this.props.tabName === tabUrls.call) {
            this.props.fetchSplitScreen(true);
            this.props.setTabName(tabUrls.call);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { mapUrl, vehicleType, isSearchZoom, tabName } = this.props;

        if(prevProps.filteredSourceLayer && prevProps.filteredSourceLayer.length && this.state.showMap && this.map.getStyle().layers && this.map.getStyle().layers.length){
            this.customLayersEvents(this.map);
        }
        
        if (prevProps.mapUrl && prevProps.mapUrl !== mapUrl) {
            this.handleStyleSelection(mapUrl);
            this.removePoiRouting();
            this.props.fetchRouting(false);
            let poiPopup = document.getElementsByClassName('massgis-poi');
                (poiPopup.length) ? (poiPopup[0].remove()) : null
            if (this.props.weather && this.props.weather.weather) {
                clearFrames();
                // setTimeout(this.addWeatherLayer, 1000);
            }
            this.props.showTrackingList(this.props.vehicleType, 0, true);
            this.setState({ savedRouting: this.props.renderRouting });
        }

        if (prevProps.isSplitScreen != this.props.isSplitScreen) {
            if (this.map) {
                this.map.resize();
                if (groupFeatures && groupFeatures.secondMap && this.satelliteMap) {
                    this.satelliteMap.resize();
                }
            }
        }
        if(this.props.routing && this.props.renderRouting === 0 && sessionStorage.getItem("routeTo") == 'false' && this.props.callData){
            if(this.state.routeToList.length > 0) {
                this.setState({
                    routeToList: []
                })
            }
            
            this.removePoiRoutePopup();
            // this.props.fetchRouting(true);
            // this.props.fetchRenderRouting(1);
            if(prevProps.vehicleType && vehicleType && prevProps.vehicleType.length !== vehicleType.length){
                this.removePolyline();
                this.props.fetchRenderRouting(0);
            }
        }
        if (this.props.mapRender === 1 && this.props.mapUrl) {
            var element = document.querySelectorAll('.mapboxgl-control-container');
            if(element.length){
                element.forEach(el => el.remove());
            }
            this.loadMap();            
            
            if (isFeatureAllowed(featureLabels.call)) {
                this.props.fetchSplitScreen(true);
                this.props.setTabName(tabUrls.call);
            }
            else if (isFeatureAllowed(featureLabels.incident)) {
                this.props.fetchSplitScreen(false);
                this.props.setTabName(tabUrls.incident);
            }
            else if (isFeatureAllowed(featureLabels.analytics)) {
                this.props.fetchSplitScreen(false);
                this.props.setTabName(tabUrls.analytics);
            }
            else if (!isFeatureAllowed(featureLabels.incident) && !isFeatureAllowed(featureLabels.analytics) && !isFeatureAllowed(featureLabels.call)) {
                this.props.fetchSplitScreen(false);
                this.props.setTabName(tabUrls.analytics);
            } 
           
            this.props.fetchRenderMapThemes(2);
        }
        if (prevProps.tabName && prevProps.tabName != tabName) {
        if(this.props.tabName === tabUrls.call || this.props.tabName === tabUrls.incident){
            if(this.props.vehicleType && this.props.vehicleType.length){
                this.props.showTrackingList(this.props.vehicleType, 0, true);
                }
            }
        }
        if (prevProps.isSearchZoom && prevProps.isSearchZoom != isSearchZoom && this.props.tabName === tabUrls.call) {
            this.trackingBounds();
        }
    }
    showSearch = (val) => {
        this.setState({
            showSearch: val
        })
    }

    handleSearchToggle = (val) => {
        this.setState({
            search: val
        });
        this.removeMarkers();
    }

    loadMap = () => {
        this.setState({
            showMap: false
        }, () => {

        if (!document.getElementById('doppler-control'))
            this.customControl = new GifControl();
        if (this.props.mapThemes && this.props.styleLoad) {            
            this.setState({ selectedOption: this.props.mapUrl, showWeatherTraffic: true });
            if (groupFeatures && groupFeatures.secondMap) {
                let secondMap = groupFeatures.secondMap;
                if(secondMap != null && secondMap.length) {
                    let secondMapUrl = '';
                    const mapPathStyles = this.props.mapThemes.map(ds => ds.Url.split('default')[1]);
                    const selectedSecondaryMap = this.props.secondMapPath;
                    // const selectedSecondaryMap = localStorage.getItem('secondarymapview');
                    if (selectedSecondaryMap && selectedSecondaryMap !== '') {
                        secondMapUrl = selectedSecondaryMap;
                        this.state.secondaryView = secondMapUrl
                    } else {
                        let tempSecondMap = Array.isArray(secondMap) ? secondMap[0] : secondMap;
                        if(tempSecondMap.charAt(0) === "/"){
                            secondMapUrl = tempSecondMap
                        } else {
                            secondMapUrl = '/' + tempSecondMap
                        }
                        this.state.secondaryView = secondMapUrl
                    }                    
                    
                    try {
                        if (mapPathStyles.includes(secondMapUrl)) {
                            this.satelliteMap = this.createMap(this.mapRef2, (serverconfigs.secondaryMapBaseUrl + secondMapUrl));
                            this.satelliteMap.once('styledata', this.onSatelliteMapLoaded);
                        } else {
                            if (secondMapUrl !== `/${mapStyleLayers.GoogleStreetViewLayer}.json`) {
                                this.state.secondaryView = mapStyleLayers.NoMapLayer                                
                            }
                        }
                    } catch (error) {              
                        this.setState({
                            isSatelliteMapLoaded: false,
                        })
            }            
                } else {
                    this.satelliteMap = null
                    this.props.fetchSplitScreen(false);
                }
            } else {
                this.satelliteMap = null
                this.props.fetchSplitScreen(false);
            }
            
            let groupLoc, groupZoom;
            if (groupFeatures) {
                if (groupFeatures.map) {
                    groupLoc = groupFeatures.map.center && groupFeatures.map.center.length ? groupFeatures.map.center : groupLocations.default.center;
                    groupZoom = groupFeatures.map.zoom ? groupFeatures.map.zoom : groupLocations.default.zoom;
                }
                else {
                    groupLoc = groupLocations.default.center;
                    groupZoom = groupLocations.default.zoom;
                }
            }
            else {
                groupLoc = groupLocations.default.center;
                groupZoom = groupLocations.default.zoom;
            }
            
            this.map = new mapboxgl.Map({
                container: this.mapRef.current,
                style: this.props.mapUrl + '?api_key=' + serverconfigs.apiKey,
                hash: true,
                center: groupLoc,
                zoom: groupZoom
            });

            this.map.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');
            this.state.secondaryView === `/${mapStyleLayers.GoogleStreetViewLayer}.json` && this.handleGoogleStreetView({ lat: groupLoc[1], lng: groupLoc[0] });
            
            let options = { showCompass: true, showZoom: false };
            this.map.addControl(new mapboxgl.NavigationControl(options), 'top-right');
            
            options = { showCompass: false, showZoom: true };
            this.map.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
            this.handleLatLngPopup(this.map, 'primary-map-latlng');
            this.handleMapError();
            this.addDraw();
            this.map.once('styledata', this.onMaploaded);
            this.map.on('dblclick', this.onDoubleClick);
            this.map.on('click', this.onMouseClick);
            this.map.on('mousemove', this.onMouseMove);
        }        
        })
        //this.createMap()              
    }

    handleGoogleStreetView = (locposition) => {
        // const secondaryMapView = localStorage.getItem('secondarymapview');
        // @TODO
        let checkIfMapExistsInStyles = false;
        let checkIfInitViewExistsInStyles = false;
        let initMapViewName;
        let secondaryMapViewName;
        let secondaryMapView;
        
        const mapPaths = this.props.mapThemes && this.props.mapThemes.map(ds => ds.Url.split('default')[1]);
        
        if ( this.props.secondMapPath === '' ) {
            const sessionSecondMap = JSON.parse(sessionStorage.getItem('groupProfile'));
            let initMapViewPath = Array.isArray(sessionSecondMap.secondMap) ? 
                                            (sessionSecondMap.secondMap)[0] : sessionSecondMap.secondMap;
            this.props.setSecondMapPath(initMapViewPath);
            initMapViewName = Array.isArray(sessionSecondMap.secondMap) ? 
                            (sessionSecondMap.secondMap)[0].match(/(\/\w+)?\/(.*)\.json/)[2] : 
                            sessionSecondMap.secondMap.match(/(\/\w+)?\/(.*)\.json/)[2] 

            if (mapPaths) {
                if (mapPaths.includes(initMapViewPath) || initMapViewPath === `/${mapStyleLayers.GoogleStreetViewLayer}.json`) {
                    checkIfInitViewExistsInStyles = true
                }
            }
        } else {
            secondaryMapView = this.props.secondMapPath;
            secondaryMapViewName = secondaryMapView ? secondaryMapView.match(/(\/\w+)?\/(.*)\.json/)[2] : 'No-Map';            

            if (mapPaths) {
                if (mapPaths.includes(secondaryMapView) || secondaryMapView === `/${mapStyleLayers.GoogleStreetViewLayer}.json`) {
                    checkIfMapExistsInStyles = true;
                }
            }
        }    
           
        this.setState({
            secondaryView: (!!secondaryMapView && checkIfMapExistsInStyles) ? secondaryMapViewName : ((!!initMapView && checkIfInitViewExistsInStyles) ? initMapViewName : 'No-Map')
        }, () => {
            if (this.mapRef2.current && this.state.secondaryView === mapStyleLayers.GoogleStreetViewLayer) {
                loader.load()
                    .then((google) => {
                        this.googleMap = new google.maps.Map(this.mapRef2.current, {
                            zoom: 11,
                            center: locposition,
                        });

                        const panorama = new google.maps.StreetViewPanorama(
                            this.mapRef2.current,
                            {
                                position: locposition,
                                pov: {
                                    heading: 34,
                                    pitch: 10,
                                },
                            }
                        );
                        this.googleMap.setStreetView(panorama);
                    }).catch(e => {
                        console.log('unable to load google maps', e)
                    });
            }
        });
    }
    handleLatLngPopup = (map, id) => {
        map.on('mousemove', (e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('mouseout', () => {
            document.getElementById(id).style.display = 'none';
        });
        map.on('touchmove',(e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('touchend', () => {
            document.getElementById(id).style.display = 'none';
        });
    }

    handleMapError = () => {
        this.map.on('error', e => {
            if (e.error.status >= 500) {
                this.setState({
                    showSpinner: false
                })
                toast.error('' + e.error.message, { toastId: customToastId });
            }
        });
        if ((groupFeatures && groupFeatures.secondMap && this.satelliteMap)) {
            this.satelliteMap.on('error', e => {
                // this.props.fetchSplitScreen(false);
                this.setState({
                    satelliteMapError: true
                })
                // toast.error(' Satellite Map :: ' + e.error.message, { toastId: customToastId });
            });
        }
    }
    createMap = (container, style) => {
        let groupLoc, groupZoom;
        if (groupFeatures) {
            if (groupFeatures.map) {
                groupLoc = groupFeatures.map.center && groupFeatures.map.center.length ? groupFeatures.map.center : groupLocations.default.center;
                groupZoom = groupFeatures.map.zoom ? groupFeatures.map.zoom : groupLocations.default.zoom;
            }
            else {
                groupLoc = groupLocations.default.center;
                groupZoom = groupLocations.default.zoom;
            }
        }
        else {
            groupLoc = groupLocations.default.center;
            groupZoom = groupLocations.default.zoom;
        }
        let tempMap = new mapboxgl.Map({
            container: container.current,
            style: style + '?api_key=' + serverconfigs.apiKey,
            bearing: 0,
            pitch: 45,
            center: groupLoc,
            zoom: groupZoom
        });
        tempMap.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');

        let options = { showCompass: true, showZoom: false };
        tempMap.addControl(new mapboxgl.NavigationControl(options), 'top-right');

        options = { showCompass: false, showZoom: true };
        tempMap.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
        this.handleLatLngPopup(tempMap, 'satellite-map-latlng');
        return tempMap;
    }
    onSatelliteMapLoaded = (e) => {
        this.setState({
            isSatelliteMapLoaded: true,
            satelliteMapError: false
        })
    }

    onMaploaded = (e) => {
        let layerNames = this.map.getStyle().layers;
        let activeCallers = [];
        this.setState({
            showSpinner: false,
            isMapLoaded: true,
            layerData: layerNames,
            showMap: true
        }, () => {
        removeGifLayers();
        this.props.fetchRenderHistory(2);
        this.props.callsPanelReloaded(1);
        this.props.fetchMapLoaded(this.state.showMap);
        this.props.fetchAllMapLayers(layerNames);
        setTimeout(this.addWeatherLayer, 1000);
        if(this.state.indoorMapsList && this.state.indoorMapsList.length && this.map){
            this.addIndoorMaps(this.state.indoorMapsList);
            this.map.on('moveend', () => {
                this.setIndoorResources(this.getIndoorResources());
            });
        }
        (this.props.weather) ? this.showRadarLayers(this.props.weather.weather) : null;
        if (this.props.traffic) {
            this.enableTraffic(this.props.traffic.traffic);
        }
        if (this.map.getStyle().sources['' + clickableLayers.poiSources + ''] && !this.state.renderPoi) {
            this.setState({ renderPoi: true }, () => {
                this.poiEvents(this.map);
            });
        } else {
            this.setState({ renderPoi: false });
        }
        this.disableSources();
        this.props.fetchRouting(this.state.savedRouting);
        })
    }

    addWeatherLayer = () => {
        if (this.state.showWeather) {
            addRasterLayer(Math.round(new Date().getTime() / 1000), 1, 0);
        }

    }

    loadLayers = (layers) => {
        layers.map((item, index) => {
            if (item.checkedState) {
                this.map.setLayoutProperty(item.value, 'visibility', 'visible');
            }
            else {
                this.map.setLayoutProperty(item.value, 'visibility', 'none');
            }
        })
    }
    handleLayerSelection = (clickedLayer) => {
        let visibility = this.map.getLayoutProperty(clickedLayer, 'visibility');
        // toggle layer visibility by changing the layout object's visibility property
        if (visibility === 'visible') {
            this.map.setLayoutProperty(clickedLayer, 'visibility', 'none');
        }
        else if (visibility === 'none') {
            this.map.setLayoutProperty(clickedLayer, 'visibility', 'visible');
        }
    }

    handleStyleSelection = selectedOption => {
        // this.refs.sidebar.hideLayer();
        if(navigator.onLine){
            this.setState({
                selectedOption,
                showSpinner: true,
                showMap: false
            });
        }
        else{
            this.setState({
                selectedOption,
                showSpinner: false,
                showMap: false
            })
        }

        this.props.fetchRenderHistory(false);
        this.props.fetchMapLoaded(false);
        if (this.state.showWeather) {
            clearFrames();
        }
        this.map.once('style.load', this.onMaploaded);
        setTimeout(this.loadStyle, 100);
       
    };

    loadStyle = (e) => {
        if (this.props.styleLoad)
            this.map.setStyle(this.props.mapUrl + '?api_key=' + serverconfigs.apiKey, { diff: false });
    }

    getMapCenter = () => {
        return this.map.getCenter();
    }
    removeMarkers = () => {
        if (this.map.getLayer('incidents')) {
            this.map.removeLayer('incidents');
            this.map.removeSource('incidents');
        }
        this.setState({
            places: []
        })
    }

    showMarkers = (places, renderMarkers) => {
        this.setState({
            places: places,
            renderMarkers: renderMarkers
        });
    }

    highlightAddress = (placeNo) => {
        let places = this.state.places;
        // this.refs.searchAddress.highlightPOI(placeNo);
        places.map((list, index) => {
            if (index === placeNo) {
                places[index].show = true;
            }
            else {
                places[index].show = false;
            }
        })
        this.setState({
            places
        })
    }

    handleOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions
        })
    }

    handleCloseOption = () => {
        this.setState({
            showOptions: false
        })
    }

    weatherRetriesCount = 0
    showRadarLayers = (weather) => {
        if (weather) {
            axios.get(serverconfigs.weatherUrl+`/doppler/1/0/0/radar?ts=${Math.round(new Date().getTime() / 1000)}&api_key=`+serverconfigs.apiKey,{timeout: configTimers.weatherTimeOut})
            .then(response => {
                if(response.status == 200) {
                    (!document.getElementById("doppler-control")) ? this.map.addControl(this.customControl) : null;
                    addRasterLayer(Math.round(new Date().getTime() / 1000), 1, 0);
                }                 
            })
            .catch(error => {
                if(error.code ==  configTimers.cancelStatusCode){
                    this.weatherRetriesCount = this.weatherRetriesCount + 1;
                    if(this.weatherRetriesCount > 2){
                        // TODO show error
                        toast.error(cancelErrorMessages.weatherTimeOutMsg, { toastId: customToastId});
                        this.props.fetchWeather(false);
                        this.map.removeControl(this.customControl);
                        removeGifLayers();
                        this.weatherRetriesCount = 0
                    }
                    else{
                        this.showRadarLayers(weather)
                    }
                }
                else if(error.response){
                    // TODO show error
                    toast.error(error.response.data.message, { toastId: customToastId});
                    this.map.removeControl(this.customControl);
                    removeGifLayers();
                } 
            })
        }
        else {
            // this.map.showTileBoundaries = false;
            this.map.removeControl(this.customControl);
            removeGifLayers();
        }
    }

    showTrafficLayers = (map, traffic) => {
        if(map && map != null){
        var layers = map.getStyle().layers;
        if (layers) {
            for (var ind in layers) {
                if (layers[ind].source == 'traffic') {
                    map.setLayoutProperty(layers[ind].id, 'visibility', traffic ? 'visible' : 'none')
                }
            }
        }
    }
    }

    enableTraffic = (val) => {
        if (this.state.showMap) { this.showTrafficLayers(this.map, val); }
        if (this.state.isSatelliteMapLoaded) { this.showTrafficLayers(this.satelliteMap, val); }
    }

    handleTraffic = () => {
        this.setState({
            showTraffic: this.state.showTraffic = !this.state.showTraffic
        })
        this.enableTraffic();
    }

    showPsapBoundsLayers = () => {
        var layers = this.map.getStyle().layers;
        const selectedSourceLayerLength = this.props.selectedSourceLayers;
        if (layers) {
            for (var ind in layers) {
                if ((layers[ind].id == this.props.boundLayer.id) || (layers[ind].source == this.props.boundLayer.boundLayer)) {
                    psapLayerBounds.map(list => {
                        if(list.Name === this.props.boundLayer.boundLayer){
                            this.map.setLayoutProperty(layers[ind].id, 'visibility', this.props.boundLayer.showPsapLayer ? 'visible' : 'none');
                            if(list.data && list.data.length){
                                list.data.map(scaleData => {
                                    this.map.setLayoutProperty(scaleData.Name, 'visibility', this.props.boundLayer.showPsapLayer ? 'visible' : 'none');
                                })
                            }
                        }
                        else{
                            this.map.setLayoutProperty(list.Name, 'visibility', false ? 'visible' : 'none');
                            if(list.data && list.data.length){
                                list.data.map(scaleData => {
                                    this.map.setLayoutProperty(scaleData.Name, 'visibility', false ? 'visible' : 'none');
                                })
                            }
                        }
                    })
                }
                if((selectedSourceLayerLength) && selectedSourceLayerLength.length){
                    // check the selected array has address point if present 
                    this.props.selectedSourceLayers.map(selSourceName =>{
                        if(selSourceName.name === massGISAPLayerBound.massGISAPLayer.Name){
                            if(selSourceName.name === layers[ind].id){
                                this.map.setLayoutProperty(layers[ind].id, 'visibility', selSourceName.show ? 'visible' : 'none');                                
                            }
                        }
                    })

                    if(this.props.allSourceLayers && this.props.allSourceLayers.length) {
                        this.props.allSourceLayers.map(sourceName => {
                            if(sourceName.id === layers[ind].id){
                                this.map.setLayoutProperty(layers[ind].id, 'visibility', sourceName.active ? 'visible' : 'none');
                            }
                        })
                    }
                }
            }
        }
    }

    disableSources = () =>{
        if(this.state.showMap && this.props.allSourceLayers){
           var layers = this.map.getStyle().layers;
            if (layers) {
                var tempLayers2 = [];
                for (var ind in layers) {
                    callDeclarations.customSourceLayersList.map(list => {
                        if (list.Name === layers[ind].source) {
                            var a = {}
                            a.id = layers[ind].id
                            a.Name = list.Name
                            a.ResourceType = list.ResourceType
                            a.Url = list.Url
                            a.active = false
                            tempLayers2.push(a)
                            this.map.setLayoutProperty(layers[ind].id, 'visibility', 'none');
                        }
                    })
                }
                this.props.fetchAllSourceLayers(tempLayers2);
            }
        }
    }

    handleHistory = (val) => {
        this.setState({
            showHistory: val
        })
    }

    handleTrackingCallBounds = () => {
        let bounds = [], satBounds = [], trackingBounds = [];
        if ((this.props.vehicleType && this.props.vehicleType.length === 0) || !this.props.vehicleType) {
            if(this.props.solacomData && this.props.solacomData !== '' && Object.keys(this.props.solacomData).length){
                if(this.props.solacomData.queuedCalls && this.props.solacomData.queuedCalls.length){
                    this.props.solacomData.queuedCalls.map(ds => {  
                        if (ds.geometry && ds.geometry.coordinates) {                 
                            let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                            bounds.push(lngLat);
                            satBounds.push(lngLat);                   
                        } 
                    }); 
                }
                if(this.props.solacomData.answeredCalls && this.props.solacomData.answeredCalls.length){
                    this.props.solacomData.answeredCalls.map(ds => {  
                        if (ds.geometry && ds.geometry.coordinates) {                 
                            let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                            bounds.push(lngLat);
                            satBounds.push(lngLat);                   
                        } 
                    }); 
                }
            }
        }
        if (this.props.callData) {
            if (this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length) {
                // this.handleCallsBounds(this.map, 0, callDeclarations.initMapZoom, this.props.callData)
                if (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released) {
                    let lngLat =  new mapboxgl.LngLat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]);
                    bounds.push(lngLat);
                    satBounds.push(lngLat);
                }
                if (this.state.isSatelliteMapLoaded && !SolacomGroup()) {
                    this.flyToMap(this.satelliteMap, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom, this.props.callData);
                }
            }
            // else {
            //     this.zoomToGroupLocation();
            // }
        }
        if (this.props.vehicleType && this.props.vehicleType.length) {
            this.props.vehicleType.map(vehicle => {
                if (vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length) {
                    let lngLat = new mapboxgl.LngLat(vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][1], vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][0]);
                    trackingBounds.push(lngLat);
                }
            })
            if (trackingBounds.length) {
                bounds.push(...trackingBounds);
            }
        }
        if (satBounds.length && SolacomGroup()) {
            this.setBounds(satBounds, this.satelliteMap, true);
        }
        if (bounds.length) {
            this.setBounds(bounds, this.map);
        }
        else {
            this.zoomToGroupLocation();
        }
    }

    handleCallsBounds = (map, pitch, zoomLevel, list) => {
        let bounds = [];
        if (this.props.vehicleType && this.props.vehicleType.length && list.geometry.coordinates.length) {
            if(list.callState !== callsConfigs.endStaus){
                bounds.push( new mapboxgl.LngLat(list.geometry.coordinates[list.geometry.coordinates.length - 1][0], list.geometry.coordinates[list.geometry.coordinates.length - 1][1]))
            }
            this.props.vehicleType.map(vehicle => {
                if (vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length) {
                    let lngLat = new mapboxgl.LngLat(vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][1], vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][0]);
                    bounds.push(lngLat);
                }
            })
            if (bounds.length) {
                this.setBounds(bounds, this.map);
            }
        }
        else if(list.callState !== callsConfigs.endStaus){
            this.flyToMap(map, pitch, zoomLevel, list);
        }
        else{
            this.zoomToGroupLocation(); 
        }
    }

    zoomToGroupLocation = () => {
        let groupLoc, groupZoom;
        if (groupFeatures) {
            if (groupFeatures.map) {
                groupLoc = groupFeatures.map.center && groupFeatures.map.center.length ? groupFeatures.map.center : groupLocations.default.center;
                groupZoom = groupFeatures.map.zoom ? groupFeatures.map.zoom : groupLocations.default.zoom;
            }
            else {
                groupLoc = groupLocations.default.center;
                groupZoom = groupLocations.default.zoom;
            }
        }
        else {
            groupLoc = groupLocations.default.center;
            groupZoom = groupLocations.default.zoom;
        }
        this.map.flyTo({
            center: groupLoc,
            zoom: groupZoom,
            bearing: 0,
            speed: 2, // make the flying slow
            curve: 4.5, // change the speed at which it zooms out
            easing: function (t) { return t; }
        });
        if(this.satelliteMap && this.props.tabName === tabUrls.call){
            this.satelliteMap.flyTo({
                center: groupLoc,
                zoom: groupZoom,
                bearing: 0,
                screenSpeed: 4, // make the flying slow
                minZoom: 12,
                pitch: serverconfigs.satelliteMapPitch,
                easing: function (t) { return t; }
            });
        }
    }

    handleIncidentBounds = () => {
        let bounds = [];
        this.props.setPauseZoom(false);
        if (this.props.incidents && this.props.incidents.length) {
            if (this.props.incidents.length) {
                this.props.incidents.map(item => {
                    if (item.geometry.coordinates.length) {
                        if (item.callState === incidentConfigs.activeStatus) {
                            if (this.props.vehicleType && this.props.vehicleType.length){
                                this.props.vehicleType.map(vehicle => {
                                    if (vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length) {
                                        let lngLat = new mapboxgl.LngLat(vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][1], vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][0]);
                                        bounds.push(lngLat);
                                    }
                                })
                            }                            
                            let lngLat = new mapboxgl.LngLat(item.geometry.coordinates[item.geometry.coordinates.length - 1][0], item.geometry.coordinates[item.geometry.coordinates.length - 1][1]);
                            bounds.push(lngLat);
                        }
                        if(this.props.filteredpeers){
                            if(this.props.filteredpeers.length){
                                this.props.filteredpeers.map(peersObj => {
                                    peersObj.results.map(resultList => {
                                        if(resultList.geometry.coordinates.length){
                                            if (resultList.callState === incidentConfigs.activeStatus) {
                                                let lngLat = new mapboxgl.LngLat(resultList.geometry.coordinates[resultList.geometry.coordinates.length - 1][0], resultList.geometry.coordinates[resultList.geometry.coordinates.length - 1][1]);
                                                bounds.push(lngLat);
                                            }
                                        }
                                    })
                                })
                            }
                        }
                    }
                })
                if (bounds.length) {
                    this.setBounds(bounds, this.map);
                }
                else {
                    this.zoomToGroupLocation();
                }
            }
        }
        else if(this.props.filteredpeers && this.props.filteredpeers.length){
            if(this.props.filteredpeers.length){
                this.props.filteredpeers.map(peersObj => {
                    peersObj.results.map(resultList => {
                        if(resultList.geometry.coordinates.length){
                            if (resultList.callState === incidentConfigs.activeStatus) {
                                let lngLat = new mapboxgl.LngLat(resultList.geometry.coordinates[resultList.geometry.coordinates.length - 1][0], resultList.geometry.coordinates[resultList.geometry.coordinates.length - 1][1]);
                                bounds.push(lngLat);
                            }
                        }
                    })
                })
                if (bounds.length) {
                    this.setBounds(bounds, this.map);
                }
                else {
                    this.zoomToGroupLocation();
                }
            }
        }
        else if (this.props.vehicleType && this.props.vehicleType.length) {
            this.props.vehicleType.map(vehicle => {
                 if (vehicle.geometry && vehicle.geometry.coordinates && vehicle.geometry.coordinates.length) {
                     let lngLat = new mapboxgl.LngLat(vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][1], vehicle.geometry.coordinates[vehicle.geometry.coordinates.length - 1][0]);
                     bounds.push(lngLat);
                 }
             })
             if (bounds.length) {
                 this.setBounds(bounds, this.map);
             }
            //  else {
            //     this.zoomToGroupLocation();
            // }
        }
         else{
            this.zoomToGroupLocation();
        }
    }


    handleBounds = () => {
        if(!this.state.showMap)
            return;
        if (this.props.tabName === tabUrls.incident) {
            this.handleIncidentBounds();
        }
        else if (this.props.tabName === tabUrls.call) {
            this.handleTrackingCallBounds();
            let bounds = [];
            if (this.props.callData) {
                if (this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length) {
                    this.setState({
                        resetCallsEndCall: true
                    })
                }
            }
        }

    }

    removeEndCallResetValue = () => {
        this.setState({
            resetCallsEndCall: false
        })
    }

    setBounds = (bounds, map, mapZoom) => {
        if(!this.state.showMap)
            return
        var bbox = new mapboxgl.LngLatBounds();
        if (bounds.length === 1 && map) {
            map.flyTo({
                center: bounds[0],
                zoom: (mapZoom && this.props.callData && (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released)) ? callDeclarations.satelliteMapZoom : 15,
                bearing: 0,
                speed: 2, // make the flying slow
                curve: 4.5, // change the speed at which it zooms out
                easing: function (t) { return t; }
            });
        } else if (bounds.length > 1 && map) {
            bounds.forEach((coordinate) => {
                bbox.extend(coordinate);
            });
            map.fitBounds(bbox, {
                padding: { top: 120, bottom: 100, left: 50, right: 75 }
            });
        }
    }

    handleUncertainity = (val) => {
        this.setState({
            showRadius: this.state.showRadius = !this.state.showRadius
        })
        if (!this.state.showRadius) {
            //this.removeCircles();
        }
    }

    showRadius = (val) => {
        this.setState({
            showRadiusLayers: val
        })
    }

    hideRadius = (val) => {
        this.setState({
            showRadiusLayers: val
        })
    }

    splitPaneChange = (value) => {
        if (this.state.showMap) {
            this.map.resize();
            if (this.satelliteMap) {
                this.satelliteMap.resize();
            }
        }
    }

    showSingleMap = (isSingleMap, tabName) => {
        this.setState({ isSingleMap: isSingleMap }, () => {
            if (this.state.showMap){
                this.map.resize();
            }
            if (!isSingleMap && this.satelliteMap && this.state.isSatelliteMapLoaded) {
                this.satelliteMap.resize();
            }
        });
        this.props.setTabName(tabName);
        if (tabName == tabUrls.incident) {
            this.props.fetchSplitScreen(false);
        } else if (tabName == tabUrls.call) {
            this.props.fetchSplitScreen(true);
        } else if (tabName == tabUrls.analytics) {
            this.props.fetchSplitScreen(false);
        }
        else {
            this.setState({
                showIncidentLocate: false,
                showCallsLocate: false
            })
        }
    }

    handleMapTheme = (val) => {
        this.setState({
            handleMapTheme: val
        });
    }

    addDraw = () => {
        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            // Instead of showing all the draw tools, show only the line string and delete tools
            controls: {
                line_string: false,
                polygon: false,
                point: false,
                trash: false
            }
        });
        this.map.addControl(this.draw, 'top-right');
        this.map.on('draw.create', this.onPolygonDraw);
    }

    hideDeletePolygon = () => {
        this.setState({ showEditPloygon: false })
    }

    hideEditPolygon = () => {
        this.setState({ showEditPloygon: false, polygonValues: { id: '' } });
    }

    onPolygonDraw = (event) => {
        let feature = event.features[0];
        this.updatePolygons(feature.id)
    }

    updatePolygons = (id) => {
        let polygons = this.state.polygons;
        polygons.push({ name: '', description: '', id: id });
        this.setState({
            showEditPloygon: true,
            polygonDialog: false,
            polygonValues: { name: '', description: '', id: id }
        });
    }

    showPolygon = () => {
        this.setState({
            polygonDialog: true
        })
    }

    hidePolygon = () => {
        this.setState({
            polygonDialog: false
        })
    }

    drawMeasurement = () => {
        if (this.map) {
            if (this.isPolygonEnable) {
                this.draw.changeMode('simple_select');
                this.isPolygonEnable = false;
                this.setState({
                    isDrawPolygon: false,
                });
            }
            if (this.state.isMeasuring) {
                this.setState({
                    isMeasuring: false
                });
                this.rulerControl.removeMeasuring();
            } else {
                this.setState({
                    isMeasuring: true,
                });
                this.rulerControl.addMeasuring(this.map);
            }
        }
    }

    drawPolygon = () => {
        // Remove Measurement when draw polygon is active and viceversa
        this.setState({
            isDrawPolygon: this.state.isDrawPolygon = !this.state.isDrawPolygon,
            isMeasuring: false,
        });
        this.rulerControl.removeMeasuring();

        let showHelp = sessionStorage.getItem('helpTextShown') ?
            (sessionStorage.getItem('helpTextShown') == 'true' || sessionStorage.getItem('helpTextShown') == true ? true : false) : false;
        this.setState({
            polygonDialog: !showHelp,
        })
        // this.draw.changeMode('draw_polygon');
        sessionStorage.setItem('helpTextShown', true);

        if (this.state.isDrawPolygon) {
            this.draw.changeMode('draw_polygon');
            this.isPolygonEnable = true;
        }
        else {
            this.draw.changeMode('simple_select');
            this.isPolygonEnable = false;
        }
    }

    deletePolygon = async (polygonId) => {
        let selectedPolygon = -1;
        let polygons = this.state.polygons;
        polygons.forEach((polygon, index) => {
            selectedPolygon = (polygon.id == polygonId) ? index : selectedPolygon;
        });

        if (selectedPolygon > -1 && !polygons[selectedPolygon].name) {
            polygons.splice(selectedPolygon, 1)
            this.setState({ polygons });

            this.draw.delete(polygonId);
        } else {
            this.datasetService.deletePolygon(polygonId, serverconfigs.groupName).then(response => {
                if (selectedPolygon > -1) {
                    polygons.splice(selectedPolygon, 1)
                    this.setState({ polygons });
                }
                this.draw.delete(polygonId);
                toast.success(successMessages.polygonDeletedMsg, { toastId: customToastId });
            }).catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.polygonDatasetTimeOutMsg, { toastId: customToastId});
                } else if(error.response) {
                    toast.error(error.response.data.message, { toastId: customToastId});
                }   
                consoleLog('Error deleting polygon...' + error);
            })
        }


    }

    handlePolygon = (value) => {
        this.setState({
            showPolygon: value
        })
        this.hidePolygon();
        this.draw.changeMode('simple_select');
    }

    getPolygons = () => {
        let geometries = this.draw.getAll();
    }

    removePolygonsFromMap = () => {
        this.draw.deleteAll();
        this.setState({ polygons: [] });
    }

    onDoubleClick = (event) => {
        let selectedIds = this.draw.getSelectedIds();
        if (selectedIds && selectedIds.length > 0) {
            event.preventDefault();
            let polygons = this.state.polygons;
            let selectedPolygon;
            polygons.forEach((polygon, index) => {
                if (polygon.id == selectedIds[0]) {
                    selectedPolygon = polygon;
                }
            })
            if (selectedPolygon) {
                this.setState({
                    polygonValues: { name: selectedPolygon.name, description: selectedPolygon.description, id: selectedPolygon.id },
                    showEditPloygon: true
                });
            }
        }
        if (this.state.isMeasuring) {
            this.setState({
                isMeasuring: false
            });
        }
    }

    savePolygonProperties = async (name, description, id) => {
        let isUpdate;
        let polygons = this.state.polygons;
        let properties = { name: name, description: description, id: id };
        this.state.polygons.forEach((polygon, ind) => {
            if (id == polygon.id) {
                isUpdate = true;
                polygon.name = properties.name;
                polygon.description = properties.description;
            }
        })

        if (!isUpdate) {
            polygons.push(properties);
        }

        let geojson = this.draw.get(id);
        geojson.properties = properties;
        this.datasetService.uploadPolygon(geojson, serverconfigs.groupName).then((response) => {
            toast.success(successMessages.polygonUploadMsg, { toastId: customToastId });
        }).catch(error => {
            if (error.code == configTimers.cancelStatusCode) {
                toast.error(cancelErrorMessages.polygonDatasetTimeOutMsg, { toastId: customToastId});
            } else if(error.response) {
                toast.error(error.response.data.message, { toastId: customToastId});
            } 
        });

        this.setState({ polygons, polygonValues: { id: '' } });
    }

    loadPolygons = async () => {

        this.setState({
            clearpolygons: !this.state.clearpolygons
        })
        if (this.state.clearpolygons) {
            this.datasetService.listPolygon(serverconfigs.groupName).then(response => {
                let polygons = this.state.polygons;
                let remainingPolygons = [];
                polygons.forEach((polygon, index) => {
                    if (polygon.name) {
                        this.draw.delete(polygon.id);
                    } else {
                        remainingPolygons.push(polygon);
                    }
                });
                this.setState({ polygons: remainingPolygons });
                if (response.status == 200 && response.data) {
                    if (response.data.Resources && response.data.Resources.length > 0) {
                        response.data.Resources.forEach((polygon, ind) => {
                            this.drawPolygonFromService(polygon);
                        });
                    }
                }
            }).catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.polygonDatasetTimeOutMsg, { toastId: customToastId});
                } else if(error.response) {
                    toast.error(error.response.data.message, { toastId: customToastId});
                } 
            });
        }
        else {
            this.removePolygonsFromMap();
        }
    }

    drawPolygonFromService = (polygon) => {
        this.datasetService.getPolygon(polygon.Url).then(response => {
            this.draw.add(response.data);
            let properties = { name: response.data.properties.name, description: response.data.properties.description, id: response.data.properties.id };
            let polygonProps = this.state.polygons;
            polygonProps.push(properties);
            this.setState({ polygons: polygonProps });
        }, error => {
            if (error.code == configTimers.cancelStatusCode) {
                toast.error(cancelErrorMessages.polygonDatasetTimeOutMsg, { toastId: customToastId});
            } else if(error.response) {
                toast.error(error.response.data.message, { toastId: customToastId});
            }        
        });
    }

    handleCancel = () => {
        this.setState({
            search: "ADDRESS"
        })
    }

    changeAddress = (itemNo) => {
        // this.onAddressPopupClose();
        this.refs.markerRef.showAddress(itemNo);
        this.setState({
            renderMarkers: true
        })
    }

    handleSplitterMouseDown = () => {
        this.setState({ resizing: true });
    }

    handleMouseUp = () => {
        this.setState(prevState => (prevState.resizing ? { resizing: false } : null));
    }

    handleMouseMove = (e) => {
        if (this.state.resizing) {
            const containerRect = this.container.getBoundingClientRect();
            const splitterRect = this.splitter.getBoundingClientRect();
            const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
                left: e.clientX,
                top: e.clientY
            }, true);

        }
    }

    getSecondaryPaneSize = (containerRect, splitterRect, clientPosition, offsetMouse) => {
        let totalSize;
        let splitterSize;
        let offset;

        totalSize = containerRect.width;
        splitterSize = splitterRect.width;
        offset = clientPosition.left - containerRect.left;

        if (offsetMouse) {
            offset -= splitterSize / 2;
        }
        if (offset < 0) {
            offset = 0;
        } else if (offset > totalSize - splitterSize) {
            offset = totalSize - splitterSize;
        }

        let secondaryPaneSize;
        secondaryPaneSize = totalSize - splitterSize - offset;

        let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
        secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
        primaryPaneSize = (primaryPaneSize * 100) / totalSize;
        splitterSize = (splitterSize * 100) / totalSize;
        totalSize = 100;

        if (primaryPaneSize < this.props.primaryMinSize) {
            secondaryPaneSize = Math.max(secondaryPaneSize - (0 - primaryPaneSize), 0);
        } else if (secondaryPaneSize < 0) {
            secondaryPaneSize = Math.min(totalSize - splitterSize - 0, 0);
        }

        let dividerPosition = secondaryPaneSize + '%';
        secondaryPaneSize = secondaryPaneSize + '%';
        primaryPaneSize = primaryPaneSize + '%';

        this.setState({ secondaryPaneSize: secondaryPaneSize, dividerPosition: dividerPosition, primaryPaneSize: primaryPaneSize }, () => {
            if(this.state.showMap){
                this.map.resize();
            }
            if (groupFeatures && groupFeatures.secondMap && this.satelliteMap && this.state.isSatelliteMapLoaded) {
                this.satelliteMap.resize();
            }
        });

        return secondaryPaneSize;
    }

    poiRoute = (poiCo) => {
        this.routeCoordinates = {
            geometry: {
                coordinates: poiCo
            }
        };
        this.removePoiRoutePopup();
        if (this.props.tabName === tabUrls.call) {
            if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates.length) {
                let destination = [this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0]];
                this.callRouting(this.routeCoordinates, destination, 0);
            }
        }
    }

    customLayersEvents(map) {
        let filteredSourceLayer = this.props.filteredSourceLayer;
        if (filteredSourceLayer && filteredSourceLayer.length) {
            for (let i = 0; i < filteredSourceLayer.length; i++) {
                let customLayer = filteredSourceLayer[i];
                var popupOptions = { offset: [0, -22], className: 'customLayer-poi' };
                map.on('click', customLayer.id, function (e) {
                    let props = e.features[0].properties;
                    let data = '';
                    for (const [key, value] of Object.entries(props)) {
                        data += `<div class="text-capitalize"><span class="street-label">${key}:</span> ${value}</div>`;
                    }
                    let customLayerPopUp = new mapboxgl.Popup(popupOptions)
                        .setLngLat(e.lngLat)
                        .setHTML(`<div class="poi-container"><div><span class="street-label">Layer:</span> ${customLayer.id}</div>${data}</div>`);
                        customLayerPopUp.addTo(map);

                    let custLayerIndex = document.getElementsByClassName('customLayer-poi');
                    if (custLayerIndex.length === 2) {
                        document.getElementsByClassName('customLayer-poi')[custLayerIndex.length - 1].remove();
                    }
                });
                map.on('mousemove', customLayer.id, function () {
                    map.getCanvas().style.cursor = 'pointer';
                });
            }
        }
    }

    poiEvents(map) {
        let style = map.getStyle();
        let layers = Object.values(style.layers);
        let poiData = layers.filter(layer => layer.source === clickableLayers.poiSources);
        let poiCo;
        let triggerPoiRoute = () => {
            this.setState({
                routeToList:[]
            }, () => {
                this.poiRoute(poiCo);
                this.props.fetchRouting(false);
                this.props.fetchRenderRouting(1);
            })
        }
        var that = this, popupHTML = '';
        if (poiData.length && this.state.renderPoi) {
            for (let i = 0; i < poiData.length; i++) {
                let poi = poiData[i];
                map.on('click', poi.id, function (e) {
                    poiCo = e.features[0].geometry.coordinates.slice();
                    let props = e.features[0].properties;
                    let data = '';
                    for (const [key, value] of Object.entries(props)) {
                        data += `<div><span class='poi-label street-label'>${key}</span>: ${value}</div>`;
                    }
                    if (isFeatureAllowed(featureLabels.routing) && that.props.tabName === tabUrls.call) {                        
                        popupHTML = `<div id="poiRouteBtn_${i}" key="poi_${i}" class="report-menu d-flex align-items-center justify-content-between"><div class="report-btn hand-cursor">Route</div></div>`;
                    }
                    else {
                        popupHTML = '';
                    }
                    let msPoipopup = new mapboxgl.Popup({ className: "massgis-poi" })
                        .setLngLat(poiCo)
                        .setHTML(`${popupHTML}
                        <div class="poi-container">${data}</div>`);
                    msPoipopup.addTo(map);
                    that.poiRoutePopup.push(msPoipopup);
                    let poiBtn = document.getElementById("poiRouteBtn_" + i);
                    if (isFeatureAllowed(featureLabels.routing) && that.props.tabName === tabUrls.call) {
                        poiBtn.addEventListener('click', triggerPoiRoute, true);
                    }
                    let poiIndex = document.getElementsByClassName('massgis-poi');
                    if (poiIndex.length === 2) {
                        document.getElementsByClassName('massgis-poi')[poiIndex.length - 1].remove();
                        if (isFeatureAllowed(featureLabels.routing) && that.props.tabName === tabUrls.call) {
                            poiBtn.removeEventListener('click', triggerPoiRoute, true);
                        }
                    }
                });
                map.on('mousemove', poi.id, function () {
                    map.getCanvas().style.cursor = 'pointer';
                });
            }
        }
    }

    drawPolyline = (station, lngLats, index, time) => {
        var that = this;
        var popupOptions = { offset: [0, -10], closeButton: false, closeOnClick: false, className: 'routing-popup' };
        const popup = new mapboxgl.Popup(popupOptions);
        popup.setLngLat(station.geometry.coordinates);
        popup.setDOMContent(this.addPopup(time, 'routing' + index, index));
        popup.addTo(this.map);
        this.popups[index] = { ...popup, id: 'routing' + index };
        this.polylineId[index] = 'routing' + index;
        if (!this.map.getLayer('routing' + index)) {
            this.map.addLayer({
                'id': 'routing' + index,
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [{
                            'type': 'Feature',
                            'properties': {
                                'color': '#820edc'
                            },
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': lngLats
                            }
                        }]
                    }
                },
                'paint': {
                    'line-width': 3,
                    'line-color': ['get', 'color']
                }

            });
        }

    }

    drawTrackPolyline = (station, lngLats, index, time) => {
        var that = this;
        var popupOptions = { offset: [0, -10], closeButton: false, closeOnClick: false, className: 'routing-popup' };
        const popup = new mapboxgl.Popup(popupOptions);
        popup.setLngLat([station.geometry.coordinates[station.geometry.coordinates.length-1][1], station.geometry.coordinates[station.geometry.coordinates.length-1][0]]);
        popup.setDOMContent(this.addTrackPopup(time, station.id, index));
        popup.addTo(this.map);
        this.trackingPopups.push({ ...popup, id: station.id });
        this.trackingPolylineId.push(station.id);
        if (!this.map.getLayer(station.id)) {
            this.map.addLayer({
                'id': station.id,
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [{
                            'type': 'Feature',
                            'properties': {
                                'color': '#820edc'
                            },
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': lngLats
                            }
                        }]
                    }
                },
                'paint': {
                    'line-width': 3,
                    'line-color': ['get', 'color']
                }

            });
        }

    }

    removePoiRoutePopup = () => {
       this.poiRoutePopup.forEach(popup => {
            popup.remove();
        });
        this.popups.forEach((popup) => {
            popup.remove();
        });
        for (var i = 0; i < this.polylineId.length; i++) {
            if (this.map.getLayer(this.polylineId[i])) {
                this.map.removeLayer(this.polylineId[i]);
                this.map.removeSource(this.polylineId[i]);
            }
        } 
    }

    removePoiRouting = () => {
        this.removePolyline();
        this.removePoiRoutePopup();
    }

    removePolyline = () => {
        let removedPopups = this.trackingPopups;
        this.trackingPopups.map((popup) => {
            popup.remove();
            // popups.splice(i, 1);
        });
        if(this.props.tabName === tabUrls.call){
            this.trackingPopups = [];
        }
        for (var i = 0; i < this.trackingPolylineId.length; i++) {
            if (this.map.getLayer(this.trackingPolylineId[i])) {
                this.map.removeLayer(this.trackingPolylineId[i]);
                this.map.removeSource(this.trackingPolylineId[i]);
            }
        }
    }
    onPopupClose = (id, ind) => {
        let removedPopups = this.popups;
        if (this.map.getLayer(this.polylineId[ind])) {
            this.map.removeLayer(this.polylineId[ind]);
            this.map.removeSource(this.polylineId[ind]);
        }
        this.popups.forEach((popup, i) => {
            if (id === popup.id) {
                popup.remove();
                removedPopups.splice(i, 1);
            }
        });
        this.popups = [];
    }

    onTrackPopupClose = (id, ind) => {
        sessionStorage.setItem("routeTo", false);
        let removedPopups = this.trackingPopups;
        let removeRouteToPopups = this.state.routeToList;
        if (this.map.getLayer(id)) {
            this.map.removeLayer(id);
            this.map.removeSource(id);
        }
        this.trackingPopups.forEach((popup, i) => {
            if (id === popup.id) {
                popup.remove();
                removedPopups.splice(i, 1);
            }
        });
        this.state.routeToList.map((popup, i) => {
            if (id === popup) {
                removeRouteToPopups.splice(i,1);
            }
        });
        if (removeRouteToPopups.length === 0 && removedPopups.length === 0) {
            this.props.fetchRouting(false);
            this.state.routeToList = [];
        }
    }

    addPopup = (time, id, ind) => {
        const routePopup = document.createElement('div');
        const jsx = <div>
            <div className='close-popup'>
                <BsX className="hand-cursor" onClick={this.onPopupClose.bind(this, id, ind)}>close</BsX>
            </div>
            <div className='popup-text'>
                <div>
                    {Math.round(time / 60) + ' min'}
                </div>
            </div>
        </div>
        var root = createRoot(routePopup);
        root.render(jsx);
        return routePopup
    }

    addTrackPopup = (time, id, ind) => {
        const routePopup = document.createElement('div');
        const jsx = <div>
            <div className='close-popup'>
                <BsX className="hand-cursor" onClick={this.onTrackPopupClose.bind(this, id, ind)}>close</BsX>
            </div>
            <div className='popup-text'>
                <div>
                    {Math.round(time / 60) + ' min'}
                </div>
            </div>
        </div>
        var root = createRoot(routePopup);
        root.render(jsx);
        return routePopup
    }

    callRouting = (station, destination, index) => {
        var lngLats = [], origin = [], ind = index;
        origin = [station.geometry.coordinates[1], station.geometry.coordinates[0]];
        this.RouteServices.drawPolyline(origin, destination).then(response => {
            if (response.status === 200 && this.props.tabName === tabUrls.call) {
                lngLats = [];
                response.data.polyline.map(data => {
                    lngLats.push([data.lon, data.lat]);
                })
                if (this.props.routing || this.routeCoordinates.geometry.coordinates.length) {
                    this.drawPolyline(station, lngLats, ind, response.data.estimated_travel_time);
                }
            }
        }).catch(error => {
            if(error.code ==  configTimers.cancelStatusCode) {
                toast.error(cancelErrorMessages.routeTimeOutMsg, { toastId: customToastId });
            } else {
                toast.error(error, { toastId: customToastId });
            }
            setTimeout(() => {
                this.props.fetchRouting(false);
            }, 500);
        })
    }

    trackRouting = (station, destination, index) => {
        if (sessionStorage.getItem("routeTo") == 'true') {
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
            }
            const CancelToken = axios.CancelToken;
            cancelTokenSource = CancelToken.source();
        }
        var lngLats = [], origin = [], ind = index;
        if (station.geometry && station.geometry.coordinates && station.geometry.coordinates.length) {
            origin = [station.geometry.coordinates[station.geometry.coordinates.length - 1][0], station.geometry.coordinates[station.geometry.coordinates.length - 1][1]];
            axios.get(serverconfigs.routeUrl + "?api_key=" + serverconfigs.routeApikey + '&origin=' + origin + '&destination=' + destination,
                { cancelToken: cancelTokenSource ? cancelTokenSource.token : null, timeout: configTimers.routeCallTimeOut }).then(response => {
                    if (response.status === 200 && this.props.tabName === tabUrls.call) {
                        lngLats = [];
                        response.data.polyline.map(data => {
                            lngLats.push([data.lon, data.lat]);
                        })
                        if (lngLats.length && (this.props.routing || sessionStorage.getItem("routeTo") == 'true')) {
                            this.drawTrackPolyline(station, lngLats, ind, response.data.estimated_travel_time);
                        }
                    }
                }).catch(error => {
                    if (error.code == configTimers.cancelStatusCode) {
                        toast.error(cancelErrorMessages.routeTimeOutMsg, { toastId: customToastId });
                    } else {
                        toast.error(error, { toastId: customToastId });
                    }
                    setTimeout(() => {
                        this.props.fetchRouting(false);
                    }, 500);
                })
        }
    }

    drawRouteToPolygon = (list) => {
        this.setState({
            routeToList: list
        })
    }

    signOut() {
        Auth.signOut()
          .then(() => {
            let idTokenFeatures = sessionStorage.getItem('idToken');
            let currentUser = sessionStorage.getItem('currentUser');
            var groupFeatures = sessionStorage.getItem('groupProfile');
            var showNotifications = sessionStorage.getItem('showNotifications');
            this.setState({ signedIn: false });
            sessionStorage.currentUser = undefined;
            sessionStorage.notAdmin = undefined;
            sessionStorage.removeItem('createUser');
            sessionStorage.clear();
            if (isFeatureAllowed(featureLabels.call) && !callDeclarations.isSimulationEnabled) {
              sessionStorage.setItem('isSimulationEnabled', callDeclarations.isSimulationEnabled);
              sessionStorage.setItem('idToken', idTokenFeatures);
              sessionStorage.setItem('user', currentUser);
              sessionStorage.setItem('groupProfile', groupFeatures);
              sessionStorage.setItem('showNotifications', showNotifications);
            }
            window.location.href = "/auth";
          })
          .catch(e => {
            consoleLog(e);
          });
      }
    trackingBounds = () => {
        if (!this.state.showMap || this.props.isSearchZoom || this.state.showReportPopup)
            return;
        if (this.props.tabName === tabUrls.incident && !this.props.incidentAutoZoom) {
            this.handleIncidentBounds();
        }
        else if (this.props.tabName === tabUrls.call && !this.props.callAutoZoom) {
            this.handleTrackingCallBounds();
        }
    }

    setUpdateBounds = (val) => {
        this.updateBounds = val;
    }

    handleIncidnetAutoZoom = () => {
        this.setState({
            enableIncidentAutoZoom: this.state.enableIncidentAutoZoom = !this.state.enableIncidentAutoZoom
        },() => {
            this.props.setIncidentAutoZoom(this.state.enableIncidentAutoZoom);
        })
    }

    handleCallAutoZoom = () => {
        let lockCallTabMap = this.props.callAutoZoom;
        this.setState({
            enableCallAutoZoom: lockCallTabMap = !lockCallTabMap
        },() => {
            this.props.setCallAutoZoom(this.state.enableCallAutoZoom);
            if(!this.state.enableCallAutoZoom){
                this.props.callsPanelReloaded(1);
            }
        })
    }

    showPointerCursor = (qrFeatures) => {
        let isClickbleExist = false;
        if (this.map.getZoom() >= clickableLayers.cursorPointerZoom) {
            qrFeatures.forEach((feature) => {
                // This statement acts like a continue statement for the remaining elements when the condition is satisfied
                if (isClickbleExist) { return; }
                if (clickableLayers.layerIds && clickableLayers.layerIds.length) {
                    clickableLayers.layerIds.map(layerId => {
                        if (feature.layer.id === layerId) {
                            isClickbleExist = true;
                            return;
                        }
                    })
                }
            });
        }
        return isClickbleExist;
    }

    isAPFeatureExists = (qrFeatures) => {
        let addressPointLayer = null;
        qrFeatures.forEach((feature) => {  
            if (feature.layer.id == massGISAPLayerBound.massGISAPLayer.id) {
                addressPointLayer = feature;
            }
        });
        return addressPointLayer;
    }

    getStLayerProperties = (properties, params, clickEvent) => {
        var that = this;
        var content = this.createStLayerContent(properties, params);
        let msPoipopup = new mapboxgl.Popup({ className: "report-poi" })
            .setLngLat(clickEvent.lngLat)
            .setDOMContent(content);
        msPoipopup.addTo(this.map);
        this.streetPoipopups.push(msPoipopup);
    }

    showStreetLayerPopUp = (qrFeatures, clickEvent) => {
        let streetLayerArray = [], featureID, featureType, reportParams;
        let exitLoop = false;
        qrFeatures.forEach((feature) => {
            if (exitLoop) { return }
            streetLayerIds.map(layerId => {
                if (feature.layer.id === layerId) {
                    streetLayerArray.push(feature);
                    exitLoop = true
                    return
                }
            })
        });

        if (streetLayerArray && streetLayerArray.length) {
            this.setState({
                reportPopupData: null
            })
            var stLayerProperties = streetLayerArray[0].properties;
            featureID = stLayerProperties.RCL_COMID;
            featureType = 'Street Address Range';
            let idTokenFeatures = sessionStorage.getItem('idToken');
            if (!isPointedToSecondaryRegion()) {
                this.DiscrepancyService.getReport(featureID, featureType, idTokenFeatures).then(response => {
                    this.removeStreetPopup();
                    if (response.status === 200 && response.data) {
                        reportParams = {
                            ...response.data,
                            ...stLayerProperties,
                            'type': featureType,
                            'lngLat': clickEvent.lngLat,
                            'id': stLayerProperties.RCL_COMID,
                            'receiver': response.data.found ? response.data._source.receiver : '',
                            'sender': response.data.found ? response.data._source.sender : response.data.sender,
                            'notes': response.data.found ? response.data._source.notes : ''
                        }
                        if (stLayerProperties) {
                            this.getStLayerProperties(stLayerProperties, reportParams, clickEvent);
                        }
                    }
                }).catch(error => {
                    if (stLayerProperties) {
                        this.getStLayerProperties(stLayerProperties, null, clickEvent);
                    }
                    if (error.code == configTimers.cancelStatusCode) {
                        toast.error(cancelErrorMessages.discrepencyReportTimeOutMsg, { toastId: customToastId });
                    } else if (error.response) {
                        toast.error(error.response.data.message, { toastId: customToastId });
                    }
                })
            } else {
                if (stLayerProperties) {
                    this.getStLayerProperties(stLayerProperties, null, clickEvent);
                }
            }
        }
    }

    createStLayerContent(stLayerProperties, reportParams) {
        
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker '>
            {
                <div className='street-popup-text'>
                    {(!isPointedToSecondaryRegion() && reportParams != null) ? <div className='report-menu'>
                        <div className="d-flex align-items-center">
                            <div className='report-btn hand-cursor' id='street-report-btn' onClick={this.sendReport.bind(this, reportParams)}>{reportParams.found ? 'Reported' : 'Report'}</div>
                        </div>
                    </div> : ''
                    }
                    <div><span className="street-label">Street name:</span> {stLayerProperties.NAME ? (stLayerProperties.NAME): "----"}</div>
                    <div><span className="street-label">RCL_COMMID:</span> {stLayerProperties.RCL_COMID ? ( stLayerProperties.RCL_COMID): "----"}</div>
                    <div><span className="street-label">Left From-To Address:</span> {stLayerProperties.Lt_AddrFrom ?stLayerProperties.Lt_AddrFrom : "xx" }-{stLayerProperties.Lt_AddrTo?stLayerProperties.Lt_AddrTo:"xx"}
                    </div>
                    <div><span className="street-label">Right From-To Address:</span> {stLayerProperties.Rt_AddrFrom ? stLayerProperties.Rt_AddrFrom: "xx"}-{stLayerProperties.Rt_AddrTo?stLayerProperties.Rt_AddrTo:"xx"}</div>
                </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    onMouseMove = (e) => {
        const bbox = [
            [e.point.x - 3, e.point.y - 3],
            [e.point.x + 3, e.point.y + 3]
        ];
        var qrFeatures = this.map.queryRenderedFeatures(bbox) || [];
        this.map.getCanvas().style.cursor = (qrFeatures.length && this.showPointerCursor(qrFeatures)) ? 'pointer' : '';
    }
    
    onMouseClick = (e) => {
        const bbox = [
            [e.point.x - 3, e.point.y - 3],
            [e.point.x + 3, e.point.y + 3]
        ];
        var qrFeatures = this.map.queryRenderedFeatures(bbox) || [];
        
        if (qrFeatures.length) {
            let apFeature = this.isAPFeatureExists(qrFeatures);
            if (apFeature && clickableLayers) {
                this.setState({
                    reportPopupData: null
                })
                if (apFeature.properties.hasOwnProperty(clickableLayers.addresPointProperty)) {
                    this.setState({
                        addressPointPOI: true,
                        pointFeature: apFeature
                    })
                }
                else {
                    consoleLog('no APID');
                }
            } else {
                if (this.map.getZoom() >= clickableLayers.cursorPointerZoom) {
                    this.showStreetLayerPopUp(qrFeatures, e);
                }
            }
        }
    }

    flyToMap = (map, pitch, zoomLevel, list) => {
        map.flyTo({
            center: list.geometry.coordinates[list.geometry.coordinates.length - 1],
            zoom: zoomLevel,
            bearing: 0,
            speed: 2,
            curve: 4.5,
            pitch: pitch,
            easing: function (t) { return t; }
        });
    }
    
    sendReport = (reportParams, e) => {
        if(this.state.reportPopupData){
            reportParams.found = true;
            reportParams.time =  moment().format('ddd MMM Do h:mm:ss A YYYY');
            reportParams.receiver = this.state.reportPopupData.receiver;
            reportParams.sender = this.state.reportPopupData.sender;
        }
        else if(reportParams.found && reportParams._source && reportParams._source.time){
            reportParams.time =  reportParams._source.time
            
        }
        else{
            reportParams.time =  moment().format('ddd MMM Do h:mm:ss A YYYY');
        }
        this.setState({showReportPopup: true, reportPopupData: {
            ...reportParams
        }});
    }

    closeReportPopup = () => {
        this.setState({showReportPopup: false})
    }

    updateReportPopup = (val, data) => {
        var elem = document.getElementById('street-report-btn');
        this.setState({showReportPopup: val, reportPopupData: data});
        if(data && data.found && elem){
            elem.innerHTML = 'Reported';
        }
    }

    removeStreetPopup = () => {
        this.streetPoipopups.forEach(popup => {
            popup.remove();
        });
        this.streetPoipopups = [];
    }

    handleUploadIndoorMap = () => {
        this.setState({
            showIndoorModal: true
        })
    }

    closeIndoorModal = () => {
        this.setState({
            showIndoorModal: false
        })
    }

    getIndoorMapsData = (url, name) => {
        let indoorMapsList = this.state.indoorMapsList;
        axios.get(url,{timeout: configTimers.IndoorMapDataTimeout})
            .then(response => { 
                if(response.status === 200 && response.data && response.data.floors && response.data.floors.length){
                    response.data.floors.map((list, index) => {
                        if(index === 0){
                            list.showImage = true;
                        }
                        else{
                            list.showImage = false;
                        }
                        let coords = [];
                        if(list.coordinates && list.coordinates.length){
                            list.coordinates.map(lnglat => {
                                coords.push([lnglat.lon, lnglat.lat]);
                            })
                            list.coordinates = coords.reverse();
                        }
                    })
                    indoorMapsList.push(response.data);
                    this.setState({
                        indoorMapsList: indoorMapsList
                    },() => {                        
                        this.addIndoorMaps(this.state.indoorMapsList)
                    })
                }              
            })
            .catch(error => {
                if(error.response){
                    if(error.response.data && error.response.data !== ''){
                        toast.error(error.response.data, { toastId: customToastId });
                    }
                    else if (error) {
                        toast.error(error.message, { toastId: customToastId });
                    }
                } 
            })
    }

    getUserIndoorMapsList = () => {
        this.setState({
            indoorMapsList: []
        }, () => {
            this.IndoorMapService.loadIndoorMaps().then((response) => {
                if (response.status === 200 && response.data && response.data.Resources && response.data.Resources.length) {
                    response.data.Resources.map((list) => {
                        this.getIndoorMapsData(list.Url, list.Name);
                    })
                }
            })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data !== '') {
                            toast.error(error.response.data, { toastId: customToastId });
                        }
                        else if (error) {
                            toast.error(error.message, { toastId: customToastId });
                        }
                    }
                })
        })
    }

    getIndoorResources = () => {
        const resources = [];
        this.state.indoorMapsList.forEach(indoorMap => {
          if (indoorMap && indoorMap.floors) {
            indoorMap.floors.forEach(indoorObj => {
              const isIndoor = calculateIndoorBBbox(indoorObj, this.map);
              if (isIndoor) {
                resources.push(indoorMap);
              }
            });
          }
        });
        return resources;
      };
      
      setIndoorResources = (resources) => {
        this.setState({
          showIndoorMap: !!resources.length,
          activeIndoorObj: resources.length > 0 ? resources[0] : null,
        });
      };

    addIndoorMaps = (indoorList) => {
        if (this.state.showMap && indoorList.length) {
            indoorList.map(indoorMap => {
                if(indoorMap.floors && indoorMap.floors.length){
                    indoorMap.floors.map(list => {
                        if (this.map.getLayer(indoorMap.name+'_'+list.flNo)) {
                            this.map.removeLayer(indoorMap.name+'_'+list.flNo);
                            this.map.removeSource(indoorMap.name+'_'+list.flNo);
                        }
                        this.map.addSource(indoorMap.name+'_'+list.flNo, {
                            "type": "image", "url": list.imageFileName,
                            "coordinates": list.coordinates
                        })
                        this.map.addLayer({
                            "id": indoorMap.name+'_'+list.flNo, "source": indoorMap.name+'_'+list.flNo,
                            "type": "raster", "layout": {
                                'visibility': list.showImage ? 'visible' : 'none',
                            },
                            'minzoom': serverconfigs.indoorMapZoomLevel
                        })
                    })
                }
            })
        }
    }

    handleIndoorMapSwitch = (list) => {
        // This code assigns the value of this.state.activeIndoorObj to a variable named indoorMapsList.
        let indoorMapsList = this.state.activeIndoorObj;

        // The following lines check if indoorMapsList contains an object with a 'floors' property and that the length of the floors array is not zero.
        if (indoorMapsList && indoorMapsList.floors && indoorMapsList.floors.length) {
            // If it passes the condition above, the .map() function is called on the floors array.
            indoorMapsList.floors.map(mapList => {
                // For each mapList in the array, we check if its flNo property matches that of list. 
                if (list.flNo === mapList.flNo) { 
                    // If there's a match, the showImage property of the mapList is set to true.
                    mapList.showImage = true;
                    // It also sets the visibility of the corresponding layout in this.map.
                    this.map.setLayoutProperty(indoorMapsList.name + '_' + mapList.flNo, 'visibility', 'visible');
                    // Finally, it calls the calculateIndoorBBbox() function passing along the current mapList and this.map.
                    calculateIndoorBBbox(mapList, this.map)
                } else {
                    // If there's no match, the showImage property is set to false and the visibility property of the corresponding layout is set to none.
                    mapList.showImage = false;
                    this.map.setLayoutProperty(indoorMapsList.name + '_' + mapList.flNo, 'visibility', 'none');
                }
            })
        }

        // After the updates through the .map() method, the updated indoorMapsList is set as the new state of activeIndoorObj.
        this.setState({
            activeIndoorObj: indoorMapsList
        })
    }

    render() {
        let activeCallers = [];
        if(this.props.loggedIn && !sessionStorage.getItem('currentUser')){
            this.props.fetchLoginStatus(false);
            this.signOut();
          }
        if(this.props.cancelError && this.props.mapRender === 1){
            toast.error(this.props.cancelError, { toastId: customToastId });
            this.props.fetchRenderMapThemes(2)
        }
        if((Object.keys(this.props.boundLayer).length || this.props.selectedSourceLayers.length) && this.state.showMap){
            /* draw psap bound layers */
            this.showPsapBoundsLayers();
        }

        if (this.props.traffic && this.state.showMap) {
            this.enableTraffic(this.props.traffic.traffic);
        }

        if (this.state.showMap && this.props.weather && this.props.renderWeather === 1) {
            this.showRadarLayers(this.props.weather.weather);
            this.props.fetchRenderWeather(3);

        }
        if (this.props.uncertainity) {
            if (this.props.renderUncertainity === 0) {
                this.handleUncertainity(this.props.uncertainity.uncertainity)
                this.props.fetchRenderUncertainity(1);
            }
        }
        if (this.props.routing && this.props.tabName === tabUrls.call) {
            if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates.length && this.props.callData.callState !== callsConfigs.endStaus) {
                var destination = [this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0]];
                if (this.props.renderRouting === 0 && this.state.showMap) {
                    this.props.fetchRenderRouting(1);             
                    if(this.props.vehicleType && this.props.vehicleType.length) {
                        this.routeCoordinates = {};
                        if(this.state.routeToList.length && sessionStorage.getItem("routeTo") == 'true'){
                            this.removePoiRouting();
                            this.props.fetchRouting(false);
                            this.props.vehicleType.map(vehicle => {
                                this.state.routeToList.map((station, index) => {
                                    if(vehicle.id === station){
                                        this.trackRouting(vehicle, destination, index);
                                    }
                                })
                            })
                        }
                        else{
                            sessionStorage.setItem("routeTo", false);
                            if (this.trackingPopups.length && sessionStorage.getItem("routeTo") == 'false') {
                                this.state.routeToList = [];
                                var newVehicles = [];
                                this.trackingPopups.map((vehicle, ind) => {
                                    newVehicles.push(vehicle.id);
                                    var vehicleFound = false;
                                    this.props.vehicleType.map((station, index) => {
                                        if (vehicle.id === station.id) {
                                            if(station.ignition && station.ignition === 'on'){
                                                this.onTrackPopupClose(station.id, ind);
                                                this.props.fetchRouting(true);
                                                this.trackRouting(station, destination, ind);
                                            }
                                            vehicleFound = true;
                                        }
                                    })
                                    if (!vehicleFound) {
                                        this.onTrackPopupClose(vehicle.id, ind);
                                    }
                                })
                                this.props.vehicleType.map((station, index) => {
                                    var myIndex = newVehicles.indexOf(station.id);
                                    if (myIndex === -1) {
                                        this.trackRouting(station, destination, index);
                                    }
                                })
                            }
                            else {
                                this.props.vehicleType.map((station, index) => {
                                    this.trackRouting(station, destination, index);
                                })
                            }
                        }
                    }
                    else {
                        toast.warning(trackingMessages.noVehicleData, { toastId: customToastId });
                        setTimeout(() => {
                            this.props.fetchRouting(false);
                        }, 500);
                    }
                }
            }
            else {
                toast.warning(errorMessages.noActiveCalls, { toastId: customToastId });
                setTimeout(() => {
                    this.props.fetchRouting(false);
                }, 500);
            }
        }
        else if (this.state.showMap && this.props.renderRouting === 1) {
            this.removePoiRouting();
            this.props.fetchRenderRouting(0);
        }
        if (this.props.isStreaming && !this.props.callData) {
            toast.warning(errorMessages.noActiveCalls, { toastId: customToastId });
            setTimeout(() => {
                this.props.setStreamingStatus(false);
            }, 500);
        }
        return (
            <>
                {/* <Header handleTraffic={this.handleTraffic} handleUncertainty={this.handleRadius} handleWeather={this.handleWeather} handleMapTheme={this.handleMapTheme} /> */}
                <div className={(sessionStorage.getItem('currentUser') === null) ? 'main container ' : `${this.state.resizing ? 'psap-content user-select' : 'psap-content '}`} >
                    <Sidebar mapCenter={this.getMapCenter} hideMarkers={this.removeMarkers}
                        ref="sidebar" layerData={this.state.layerData} removeRadius={this.hideRadius} removeCircles={this.removeCircles}
                        handleLayerSelection={this.handleLayerSelection} map={this.map} loadLayers={this.loadLayers} showLoader={this.showLoader}
                        mapLoaded={this.state.showMap} showRadius={this.showRadius} satelliteMap={this.satelliteMap} googleMap={this.googleMap} removePoiRouting={this.removePoiRouting}
                        analytics={this.handleHistory} showSingleMap={this.showSingleMap} showUncertainity={this.state.showRadius} isSatelliteMapLoaded={this.state.isSatelliteMapLoaded} 
                        isMeasurementOn={this.state.isMeasuring} resetCallsEndCall={this.state.resetCallsEndCall} removeEndCallResetValue={this.removeEndCallResetValue} drawRouteToPolygon={this.drawRouteToPolygon} 
                        updateBounds={this.updateBounds} trackingBounds={this.trackingBounds}  mapRef2={this.mapRef2} secondaryView={this.state.secondaryView} setUpdateBounds={this.setUpdateBounds} showReportBounds={this.state.showReportPopup} />

                    {/* <SplitterLayout percentage={true} secondaryInitialSize={40}
                        onSecondaryPaneSizeChange={this.splitPaneChange} isSingleMap={(this.state.isSingleMap || !this.props.isSplitScreen)}> */}

                    <div className='map-container' ref={(c) => { this.container = c; }}>
                        <div ref={this.mapRef} className='psap-map' style={{ width: this.props.isSplitScreen ? this.state.primaryPaneSize : '100%', position: 'absolute' }}>
                            {isFeatureAllowed(featureLabels.search) ?
                                <div className='search-container'>
                                        {this.state.search == "ADDRESS" ?
                                            <SearchAdd 
                                                ref="searchAddress" 
                                                mapRef2={this.mapRef2} 
                                                googleMap={this.googleMap} 
                                                searchKey={this.state.search} 
                                                mapCenter={this.getMapCenter} 
                                                showSearch={this.showSearch} 
                                                map={this.map} 
                                                satelliteMap={this.satelliteMap} 
                                                showMap={this.state.showMap} 
                                                isSatelliteMapLoaded={this.state.isSatelliteMapLoaded}
                                                secondaryView={this.state.secondaryView}
                                            /> : this.state.search == 'INTERSECTION' ?
                                            <SearchIntersect 
                                                mapCenter={this.getMapCenter} 
                                                map={this.map} 
                                                mapRef2={this.mapRef2} 
                                                secondaryView={this.state.secondaryView}
                                                googleMap={this.googleMap} 
                                                handleCancel={this.handleCancel} 
                                                satelliteMap={this.satelliteMap} 
                                                showMap={this.state.showMap} 
                                                isSatelliteMapLoaded={this.state.isSatelliteMapLoaded} /> : 
                                            <SearchWhat3Words 
                                                mapCenter={this.getMapCenter} 
                                                mapRef2={this.mapRef2} 
                                                googleMap={this.googleMap} 
                                                secondaryView={this.state.secondaryView}
                                                showSearch={this.showSearch} 
                                                map={this.map} 
                                                satelliteMap={this.satelliteMap} showMap={this.state.showMap} 
                                                isSatelliteMapLoaded={this.state.isSatelliteMapLoaded} />

                                    }
                                    <div className="searchLayout">
                                        <button className="ssbtn" style={{ background: this.state.search === 'ADDRESS' ? '#4b4b4b' : '#d1d1d1' }} onClick={() => this.handleSearchToggle('ADDRESS')}>{this.state.search === 'ADDRESS' ? <img src={POI_On} className='poi-icon' /> : <img src={POI_Off} className='poi-icon' />}</button>
                                        <button className="ssbtn" style={{ background: this.state.search === 'INTERSECTION' ? '#4b4b4b' : '#d1d1d1' }} onClick={() => this.handleSearchToggle('INTERSECTION')}>{this.state.search === 'INTERSECTION' ? <img src={Intersection_On} className='intersect-icon' /> : <img src={Intersection_Off} className='intersect-icon' />}</button>
                                        <button className="ssbtn" style={{ background: this.state.search === 'WHAT3WORDS' ? '#4b4b4b' : '#d1d1d1' }} onClick={() => this.handleSearchToggle('WHAT3WORDS')}>{this.state.search === 'WHAT3WORDS' ? <img src={W3W_Search_On} className='w3w-icon' /> : <img src={W3W_Search_Off} className='w3w-icon' />}</button>
                                    </div>
                                </div>
                                : ''}
                                {this.state.addressPointPOI && this.map ? 
                                    <AddressPointSearch mapCenter={this.getMapCenter} map={this.map} pointFeature={this.state.pointFeature} isAPFeatureExists={this.isAPFeatureExists} 
                                    updateReportPopup={this.updateReportPopup} reportPopupData={this.state.reportPopupData} /> : ''}
                            <div>
                                {/* <Markers places={this.state.places} map={this.map} hideMarkers={this.removeMarkers} renderMarkers={this.state.renderMarkers}
                    ref="markerRef" showAddress={this.highlightAddress} /> */}
                            </div>
                            <div className='draw-polygon'>
                                <div className='polygon-tools'>
                                    <div title={this.state.isMeasuring ? "Stop Measurement" : "Draw Measurement"} className='icon-measurement' onClick={this.drawMeasurement}></div>
                                    <div title={this.state.isDrawPolygon ? "Stop Polygon" : "Draw Polygon"} className='icon-draw' onClick={this.drawPolygon}></div>
                                    <div title={!this.state.clearpolygons ? "Clear Polygons" : "Download Polygon"} className='icon-download' onClick={this.loadPolygons}>
                                        <BsDownload>download</BsDownload>
                                    </div>
                                    {isFeatureAllowed(featureLabels.indoorMap) ?
                                        <div title='Upload Indoor Map' className='icon-download icon-upload' onClick={this.handleUploadIndoorMap}></div>
                                        : ''}
                            </div>
                            </div>
                            {this.state.activeIndoorObj && this.state.activeIndoorObj.floors && this.state.activeIndoorObj.floors.length ?
                                <div className="indoorMap-list">
                                    <span className="floor-list">{this.state.activeIndoorObj.name}</span>
                                    {this.state.activeIndoorObj.floors.map((list,ind) => {
                                        return (
                                            <>
                                                {ind === 0 || ind === 1 ?
                                                    <div className={`${list.showImage ? 'active' : ''} floor-list hand-cursor`}
                                                        key={list.flNo} onClick={this.handleIndoorMapSwitch.bind(this, list)}>{list.flNo}
                                                        {ind === 1 && this.state.activeIndoorObj.floors.length > 2 ? <img src={IndoorDropDownArrow} className='indoor-dropdown-icon' /> : ''}
                                                    </div>
                                                    : ''
                                                }
                                            </>
                                        )
                                    })}
                                    {this.state.activeIndoorObj.floors.length > 2 ?
                                        <span className="indoor-dropdown">
                                            {this.state.activeIndoorObj.floors.length > 2 && this.state.activeIndoorObj.floors.map((list, ind) => {
                                                return (
                                                    <>
                                                        {ind > 1 ?
                                                            <div className={`${list.showImage ? 'active' : ''} indoor-list hand-cursor`}
                                                                key={list.flNo} onClick={this.handleIndoorMapSwitch.bind(this, list)}>{list.flNo}
                                                            </div>
                                                            : ''
                                                        }
                                                    </>
                                                )
                                            })}
                                        </span>
                                        : ''}
                                </div>
                                : ''}
                            {this.props.tabName === tabUrls.incident || this.props.tabName === tabUrls.call ?
                                <div title="Locate" className='bounds-icon hand-cursor' onClick={this.handleBounds}></div>

                                : ''
                            }
                            {this.props.tabName === tabUrls.call ?
                                <div title={this.props.callAutoZoom ? 'Auto Zoom Out Off' : 'Auto Zoom Out On'} className={`auto-zoom-icon hand-cursor ${this.props.callAutoZoom ? 'disable-auto-zoom' : 'enable-auto-zoom'}`} onClick={this.handleCallAutoZoom}></div>
                            : ''}
                            {this.props.tabName === tabUrls.incident ?
                                <div title={this.state.enableIncidentAutoZoom ? 'Auto Zoom Out Off' : 'Auto Zoom Out On'} className={`auto-zoom-icon hand-cursor ${this.state.enableIncidentAutoZoom ? 'disable-auto-zoom' : 'enable-auto-zoom'}`} onClick={this.handleIncidnetAutoZoom}></div>
                            : ''}
                            {(this.props.cancelError || this.props.cancelError !== 'undefined') && !this.state.showSpinner ? '' :
                            <Spinner animation="border" variant="primary">
                            <span className="sr-only"></span>
                        </Spinner>
                            }
                            <div className="comtech-logo">
                                <a href="https://www.comtechlocation.com/location-products/location-studio/geolocation-solutions" target='_blank' ><img src={comtechLogo}/></a>
                            </div>
                            <pre id="primary-map-latlng" className="latlng-popup"></pre>
                        </div>
                        </div>
                        <div className="map-divider" onMouseDown={this.handleSplitterMouseDown}
                            onTouchStart={this.handleSplitterMouseDown}
                            ref={(c) => { this.splitter = c; }}
                            style={{ right: this.state.dividerPosition, display: this.props.isSplitScreen ? '' : 'none' }}
                        >
                            <div className="map-divider-image" />
                        </div>
                        {this.state.secondaryView === mapStyleLayers.GoogleStreetViewLayer && (
                            <div 
                                ref={this.mapRef2} 
                                className="psap-sattelite-map" 
                                style={{ 
                                    width: this.props.isSplitScreen  ? this.state.secondaryPaneSize : 'auto', position: this.props.isSplitScreen ? 'absolute' : 'relative', display: this.props.isSplitScreen ? 'block' : 'none' 
                                }} 
                            />)}
                        {(this.state.secondaryView !== mapStyleLayers.GoogleStreetViewLayer && this.state.secondaryView !== mapStyleLayers.NoMapLayer) &&
                            (<div ref={this.mapRef2} className="psap-sattelite-map" style={{ width: this.props.isSplitScreen  ? this.state.secondaryPaneSize : 'auto', position: this.props.isSplitScreen ? 'absolute' : 'relative', display: this.props.isSplitScreen ? 'block' : 'none' }}>
                                {this.state.satelliteMapError ?
                                    <div className="no-data-found">Failed to load Satellite Map</div>
                                : ''}
                                {groupFeatures && !groupFeatures.secondMap ?
                                    <div className="no-data-found">Secondary Map not available for this Group profile</div>
                                : ''}
                                <div className="comtech-logo">
                                    <a href="https://www.comtechlocation.com/location-products/location-studio/geolocation-solutions" target='_blank' ><img src={comtechLogo} /></a>
                                </div>
                                <pre id="satellite-map-latlng" className="latlng-popup"></pre>
                            </div>)}
                        {this.state.secondaryView === mapStyleLayers.NoMapLayer && (
                            <div 
                                ref={this.mapRef3} 
                                className="psap-sattelite-map" 
                                style={{ 
                                    width: this.props.isSplitScreen  ? this.state.secondaryPaneSize : 'auto', 
                                    position: this.props.isSplitScreen ? 'absolute' : 'relative', display: this.props.isSplitScreen ? 'block' : 'none'
                                }}
                            >   
                                <div className="no-data-found">Selected Map Layer doesnt exists. Please select correct layer to view</div>
                        </div>)}
                    {/* </SplitterLayout> */}
                    <DrawPolygon show={this.state.polygonDialog} hide={this.hidePolygon} />
                    <EditPolygon show={this.state.showEditPloygon} hide={this.hideEditPolygon} onExit={this.savePolygonProperties}
                        hideDeletePolygon={this.hideDeletePolygon}
                        delete={this.deletePolygon} polygon={this.state.polygonValues} />
                    {this.state.showMap || this.state.isSatelliteMapLoaded ?
                        <AddressMarkers map={this.map} showMap={this.state.showMap} satelliteMap={this.satelliteMap} isSatelliteMapLoaded={this.state.isSatelliteMapLoaded}></AddressMarkers>
                        : ''}
                </div>
                {this.state.showMap && isFeatureAllowed(featureLabels.tracking) && (isFeatureAllowed(featureLabels.call) || isFeatureAllowed(featureLabels.incident)) ?
                    <VehicleDonut map={this.map} showMap={this.state.showMap} drawRouteToPolygon={this.drawRouteToPolygon} trackingBounds={this.trackingBounds}
                    updateBounds={this.updateBounds} setUpdateBounds={this.setUpdateBounds} /> : ''}
                {this.state.reportPopupData && this.state.showReportPopup ?
                    <DiscrepancyReport show={this.state.showReportPopup} hide={this.closeReportPopup} onExit={this.updateReportPopup} reportPopupData={this.state.reportPopupData} />
                    : ''}
                    {this.state.showIndoorModal ? 
                    <UploadIndoorMap show={this.state.showIndoorModal} hide={this.closeIndoorModal} onExit={this.getUserIndoorMapsList} /> : ''}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
        secondMapPath: state.secondMapPath ? state.secondMapPath.secondMapPath : state.secondMapPath,
        styleLoad: state.mapUrl ? state.mapUrl.styleLoad : state.styleLoad,
        mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
        mapRender: state.mapRender ? state.mapRender.mapRender : state.mapRender,
        traffic: state.traffic,
        weather: state.weather,
        renderWeather: state.renderWeather ? state.renderWeather.renderWeather : state.renderWeather,
        uncertainity: state.uncertainity,
        renderUncertainity: state.renderUncertainity ? state.renderUncertainity.renderUncertainity : state.renderUncertainity,
        isSplitScreen: state.header.isSplitScreen,
        incidents: state.incidents.incidents,
        callData: state.calls ? state.calls.data : state.calls,
        tabName: state.tab ? state.tab.tabName : state.tab,
        routing: state.routing ? state.routing.routing : state.routing,
        renderRouting: state.renderRouting ? state.renderRouting.renderRouting : state.renderRouting,
        loadMapOnPageChange: state.loadMapOnPageChange ? state.loadMapOnPageChange.loadMapOnPageChange : state.loadMapOnPageChange,
        boundLayer: state.boundLayer,
        cancelError: state.mapThemes ? state.mapThemes.cancelError : state.mapThemes,
        vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking,
        allSourceLayers: state.allSourceLayers.allSourceLayers,
        loggedIn: state.loggedIn ? state.loggedIn.loggedIn : state.loggedIn,
        selectedSourceLayers: state.selectedSourceLayers,
        incidentAutoZoom:  state.incidentAutoZoom ? state.incidentAutoZoom.incidentAutoZoom :  state.incidentAutoZoom,
        callAutoZoom:  state.callAutoZoom ? state.callAutoZoom.callAutoZoom :  state.callAutoZoom,
        filteredpeers: state.filteredpeers.filteredPeers,
        filteredSourceLayer: state.filteredSourceLayer,
        isSearchZoom: state.searchPauseZoom ? state.searchPauseZoom.isSearchZoom : false,
	isStreaming: state.isStreaming.isStreaming,
        solacomData: state.solacomCallEvents
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchMapLoaded: (val) => dispatch(fetchMapLoaded(val)),
        fetchRenderHistory: (val) => dispatch(fetchRenderHistory(val)),
        fetchRenderMapThemes: (val) => dispatch(fetchRenderMapThemes(val)),
        fetchRenderWeather: (val) => dispatch(fetchRenderWeather(val)),
        fetchRenderUncertainity: (val) => dispatch(fetchRenderUncertainity(val)),
        setTabName: (tabName) => dispatch(setTabName(tabName)),
        fetchSplitScreen: (val) => dispatch(fetchSplitScreen(val)),
        setPauseZoom: (isPauseZoom) => dispatch(setPauseZoom(isPauseZoom)),
        fetchRouting: (val) => dispatch(fetchRouting(val)),
        fetchRenderRouting: (val) => dispatch(fetchRenderRouting(val)),
        fetchMapThemeUrl: (val) => dispatch(fetchMapThemeUrl(val)),
        fetchAllMapLayers: (val) => dispatch(fetchAllMapLayers(val)),
        fetchWeather: (val) => dispatch(fetchWeather(val)),
        showTrackingList: (type, renderCount, val) => dispatch(showTrackingList(type, renderCount, val)),
        callsPanelReloaded: (val) => dispatch(callsPanelReloaded(val)),
        fetchLoginStatus: (val) => dispatch(fetchLoginStatus(val)),
        fetchAllSourceLayers: (val) => dispatch(fetchAllSourceLayers(val)),
        setIncidentAutoZoom: (val) => dispatch(setIncidentAutoZoom(val)),
        setCallAutoZoom: (val) => dispatch(setCallAutoZoom(val)),
        setSecondMapPath: (val) => dispatch(setSecondMapPath(val)),
        setStreamingStatus: (val) => dispatch(setStreamingStatus(val))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Map);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Map);

