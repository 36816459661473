/* incident actions */
export const FETCH_INCIDENTS_REQUEST = 'FETCH_INCIDENTS_REQUEST';
export const FETCH_INCIDENTS_SUCCESS = 'FETCH_INCIDENTS_SUCCESS';
export const FETCH_INCIDENTS_FAIL = 'FETCH_INCIDENTS_FAIL';

export const SELECT_INCIDENT = 'SELECT_INCIDENT';

export const FETCH_NENA = 'FETCH_NENA';

export const FETCH_UBER = 'FETCH_UBER';

export const FETCH_ADDINFO = 'FETCH_ADDINFO';

export const FETCH_MAP_LOADED = 'FETCH_MAP_LOADED';

export const FETCH_MAP_BOUND = 'FETCH_MAP_BOUND';

export const SET_SELECTED_CAMERA = 'SET_SELECTED_CAMERA';

export const SET_PAUSE_ZOOM = 'SET_PAUSE_ZOOM';

export const SET_SEARCH_PAUSE_ZOOM = 'SET_SEARCH_PAUSE_ZOOM';

export const SET_AUTO_ZOOM = 'SET_AUTO_ZOOM';

export const SET_PEERS_DATA = 'SET_PEERS_DATA';
export const SET_PEERS_API_RESPONSE = 'SET_PEERS_API_RESPONSE';
export const SET_FILTERED_PEERS = 'SET_FILTERED_PEERS';
export const FETCH_PEERS_INCIDENTS_FAIL = 'FETCH_PEERS_INCIDENTS_FAIL';

/* history actions */
export const FETCH_INCIDENTS_HISTORY_REQUEST = 'FETCH_INCIDENT_HISTORY_REQUEST';
export const FETCH_INCIDENTS_HISTORY_SUCCESS = 'FETCH_INCIDENTS_HISTORY_SUCCESS';
export const FETCH_INCIDENTS_HISTORY_FAIL = 'FETCH_INCIDENTS_HISTORY_FAIL';

export const FETCH_FEATURE_COLLECTION = 'FETCH_FEATURE_COLLECTION';

export const FETCH_HISTORY_DATES = 'FETCH_HISTORY_DATES';

export const FETCH_RENDER_HISTORY =  'FETCH_RENDER_HISTORY';

export const FETCH_HISTORY_INTERVALs = 'FETCH_HISTORY_INTERVALs';

export const FETCH_HISTORY_SLIDER_VALUE = 'FETCH_HISTORY_SLIDER_VALUE';

/* 911 call actions */
export const FETCH_CALL_DATA_SUCCESS = 'FETCH_CALL_DATA_SUCCESS';

export const FETCH_CAMERA_DATA_SUCCESS = 'FETCH_CAMERA_DATA_SUCCESS';

export const FETCH_CALL_DATA_FAIL = 'FETCH_CALL_DATA_FAIL';

export const FETCH_CALL_DATA_REQUESTED = 'FETCH_CALL_DATA_REQUESTED';

export const SET_CAMERAS_RELOADED = "SET_CAMERAS_RELOADED";

export const RENDER_CALLS_PANEL = 'RENDER_CALLS_PANEL';

export const FETCH_RAPID_SOS_ADDRESS = 'FETCH_RAPID_SOS_ADDRESS';

export const SET_CALL_AUTO_ZOOM = 'SET_CALL_AUTO_ZOOM';

export const SET_CALL_DEPENDENCIES = 'SET_CALL_DEPENDENCIES';

/* header actions */
export const FTECH_MAP_THEME_REQUEST = 'FTECH_MAP_THEME_REQUEST';

export const FTECH_MAP_THEME_SUCCESS = 'FTECH_MAP_THEME_SUCCESS';

export const FTECH_MAP_THEME_FAIL = 'FTECH_MAP_THEME_FAIL';

export const FTECH_MAP_THEME_CANCEL = 'FTECH_MAP_THEME_CANCEL';

export const FETCH_MAP_THEME_URL = 'FETCH_MAP_THEME_URL';

export const FETCH_RENDER_Theme = 'FETCH_RENDER_Theme';

export const FETCH_TRAFFIC = 'FETCH_TRAFFIC';

export const FETCH_WEATHER = 'FETCH_WEATHER';

export const FTECH_SPLIT_SCREEN = 'FTECH_SPLIT_SCREEN';

export const FETCH_RENDER_WEATHER = 'FETCH_RENDER_WEATHER';
export const FETCH_UNCERTAINITY = 'FETCH_UNCERTAINITY';
export const FETCH_RENDER_UNCERTAINITY = 'FETCH_RENDER_UNCERTAINITY';

export const FETCH_ROUTING = 'FETCH_ROUTING'; 

export const FETCH__RENDER_ROUTING = 'FETCH__RENDER_ROUTING'; 

export const SHOW_TRACKING_LIST = 'SHOW_TRACKING_LIST';

export const FETCH_TRACKING_DATA = 'FETCH_TRACKING_DATA';

export const FETCH_LOGIN_STATUS = 'FETCH_LOGIN_STATUS';

export const FETCH_NETWORK_STATUS = 'FETCH_NETWORK_STATUS';

/* Tab Names */

export const SET_TAB_NAME = 'SET_TAB_NAME';


/* Search actions */

export const DISPLAY_SEARCH_PANEL = "DISPATCH_SEARCH_PANEL";

export const HIDE_SEARCH_PANEL = "HIDE_SEARCH_PANEL";

export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const SET_SEARCH = "SET_SEARCH"

/* Whooster actions */

export const IDENTITY_SEARCH_REQUEST = 'IDENTITY_SEARCH_REQUEST';

export const IDENTITY_SEARCH_SUCCESS = 'IDENTITY_SEARCH_SUCCESS';

export const IDENTITY_SEARCH_FAIL = 'IDENTITY_SEARCH_FAIL';

export const CRIMINAL_SEARCH_REQUEST = 'CRIMINAL_SEARCH_REQUEST';

export const CRIMINAL_SEARCH_SUCCESS = 'CRIMINAL_SEARCH_SUCCESS';

export const CRIMINAL_SEARCH_FAIL = 'CRIMINAL_SEARCH_FAIL';

/* Smart Response Content actions */

export const FETCH_CAMERA = 'FETCH_CAMERA';

export const FETCH_AED_REQUEST = 'FETCH_AED_REQUEST';

export const FETCH_AED_SUCCESS = 'FETCH_AED_SUCCESS';

export const FETCH_AED_FAIL = 'FETCH_AED_FAIL';

export const SET_AED_RELOADED = 'SET_AED_RELOADED'; 

export const SET_RAPID_SOS = 'SET_RAPID_SOS';
export const RENDER_RAPID_SOS = 'RENDER_RAPID_SOS'; 

/* Psap map layers actions */

export const FETCH_PSAP_BOUNDS_LAYERS = 'FETCH_PSAP_BOUNDS_LAYERS';

/* fetch map layers */

export const FETCH_ALL_MAP_LAYers = 'FETCH_ALL_MAP_LAYers';
export const FETCH_REGION_NAME = 'FETCH_REGION_NAME';
export const FETCH_ALL_SOURCE_LAYERS = 'FETCH_ALL_SOURCE_LAYERS';
export const SET_SELECTED_SOURCE_VALUE = 'SET_SELECTED_SOURCE_VALUE';
export const SET_FILTERED_SOURCE_VALUE = 'SET_FILTERED_SOURCE_VALUE';

export const SET_INCIDENT_DEPENDENCIES = 'SET_INCIDENT_DEPENDENCIES';

export const FETCH_STREAMING_STATUS = 'FETCH_STREAMING_STATUS';
export const SET_SEOND_MAP_PATH = 'SET_SEOND_MAP_PATH';

export const SET_SEARCH_FLAG = 'SET_SEARCH_FLAG';
export const SET_STATION_ID = 'SET_STATION_ID';
export const TOGGLE_STATION_ID = 'TOGGLE_STATION_ID';
export const FETCH_SOLACOM_CALL_EVENTS = 'FETCH_SOLACOM_CALL_EVENTS';
export const UPDATE_SOLACOM_CALL_EVENTS = 'UPDATE_SOLACOM_CALL_EVENTS';
