import {
    FTECH_MAP_THEME_REQUEST, FTECH_MAP_THEME_SUCCESS, FTECH_MAP_THEME_FAIL, FETCH_MAP_THEME_URL, FETCH_RENDER_Theme,
    FETCH_TRAFFIC, FETCH_WEATHER, FETCH_RENDER_WEATHER, FETCH_UNCERTAINITY, FETCH_RENDER_UNCERTAINITY, FTECH_SPLIT_SCREEN,
    FETCH_ROUTING, FETCH__RENDER_ROUTING, FETCH_CAMERA, SET_RAPID_SOS, RENDER_RAPID_SOS, FETCH_PSAP_BOUNDS_LAYERS, FETCH_ALL_MAP_LAYers,
    FTECH_MAP_THEME_CANCEL, FETCH_LOGIN_STATUS, FETCH_ALL_SOURCE_LAYERS, FETCH_TRACKING_DATA, SHOW_TRACKING_LIST, SET_SELECTED_SOURCE_VALUE, FETCH_NETWORK_STATUS, SET_FILTERED_SOURCE_VALUE,
    FETCH_STREAMING_STATUS
} from '../actions/actionTypes';
import axios from 'axios';
import { configTimers, serverconfigs, cancelErrorMessages, groupFeatures, callDeclarations } from '../../constants';
import { consoleLog } from "../../utils/commonUtils.js";

const fetchMapThemeRequest = () => {
    return {
        type: FTECH_MAP_THEME_REQUEST
    }
}

export const fetchSplitScreen = (splitScreen) => {
    return {
        type: FTECH_SPLIT_SCREEN,
        data: splitScreen
    }    
}

const fetchMapThemeSuccess = (data) => {
    return {
        type: FTECH_MAP_THEME_SUCCESS,
        data: data
    }
}

const fetchMapThemeFail = (error) => {
    return {
        type: FTECH_MAP_THEME_FAIL,
        data: error
    }
}

const fetchMapThemeCancel = (val, retry) => {
    return {
        type: FTECH_MAP_THEME_CANCEL,
        error: val,
        retires: retry ? retry : false
    }
}

export const fetchMapThemeUrl = (url) => {
    return {
        type: FETCH_MAP_THEME_URL,
        data: url
    }
}

export const fetchRenderMapThemes = (val) => {
    return{
        type: FETCH_RENDER_Theme,
        data: val
    }
}

export const fetchTraffic = (val) => {
    return{
        type: FETCH_TRAFFIC,
        data: val
    }
}

export const fetchWeather = (val) => {
    return{
        type: FETCH_WEATHER,
        data: val
    }
}

export const fetchRenderWeather = (val) => {
    return{
        type: FETCH_RENDER_WEATHER,
        data: val
    }
}

export const fetchUncertainity = (val) => {
    return{
        type: FETCH_UNCERTAINITY,
        data: val
    }
}

export const setRapidSOS = (val) => {
    return{
        type: SET_RAPID_SOS,
        data: val
    }
}

export const renderRapidSOS = (val) => {
    return{
        type: RENDER_RAPID_SOS,
        data: val
    }
}

export const fetchRenderUncertainity = (val) => {
    return{
        type: FETCH_RENDER_UNCERTAINITY,
        data: val
    }
}

export const fetchRouting = (val) => {
    return{
        type: FETCH_ROUTING,
        data: val
    }
}

export const fetchRenderRouting = (val) => {
    return{
        type: FETCH__RENDER_ROUTING,
        data: val
    }
}

export const fetchCamera = (val) => {
    return{
        type: FETCH_CAMERA,
        data: val
    }
}

export const fetchPsapBoundsLayers = (obj) => {
    return{
        type: FETCH_PSAP_BOUNDS_LAYERS,
        data: obj
    }
}

export const fetchAllMapLayers = (val) => {
    return{
        type: FETCH_ALL_MAP_LAYers,
        data: val
    }
}

export const fetchAllSourceLayers = (val) => {
    return{
        type: FETCH_ALL_SOURCE_LAYERS,
        data: val
    }
}

export const selectedSourceLayer = (val) => {
    return{
        type: SET_SELECTED_SOURCE_VALUE,
        data: val
    }
}

export const fetchFilteredSourceLayer = (val) => {
    return{
        type: SET_FILTERED_SOURCE_VALUE,
        data: val
    }
}

export const getSourceLayers = () => {
    return function (dispatch) {
        let customLayerUrl = axios.get(serverconfigs.serviceBaseUrl + serverconfigs.layerUrl + serverconfigs.username + '?api_key=' + serverconfigs.apiKey, { timeout: configTimers.mapDataTimeout })
        .then(response => {
            if(response.data.Resources && response.data.Resources.length){
                callDeclarations.customSourceLayersList = response.data.Resources
                dispatch(fetchAllSourceLayers(response.data.Resources));
            }
      })
      .catch(error => consoleLog(error));
    }
}

let mapStyles = [] , callRetriesCount  = 0;
export const getMapThemesData = () => {
    return function (dispatch) {
        var gisStyleUrl = ""
        var defaultStyleUrl = serverconfigs.serviceBaseUrl + 'styles/default?api_key=' + serverconfigs.apiKey
        if (groupFeatures != null && groupFeatures.gis_id != null && groupFeatures.gis_id != "") {
            gisStyleUrl = serverconfigs.serviceBaseUrl + 'styles/default?api_key=' + serverconfigs.apiKey  + '&gis_id=' + groupFeatures.gis_id
        }
        var customStylesUrl = serverconfigs.serviceBaseUrl + 'styles/' + serverconfigs.username + '?api_key=' + serverconfigs.apiKey

        const defaultStyleRequest = axios.get(defaultStyleUrl);
        const customStyleRequuest = axios.get(customStylesUrl);
        var finalUrl = []
        if (gisStyleUrl != "") {
            const gisStyleRequest = axios.get(gisStyleUrl);
            finalUrl = [customStyleRequuest, gisStyleRequest, defaultStyleRequest]
        } else {
            finalUrl = [customStyleRequuest, defaultStyleRequest]
        }
        axios.all(finalUrl, { timeout: configTimers.mapDataTimeout })
            .then(axios.spread((...responses) => {
                if (responses && responses.length) {
                    responses.map(styleData => {
                        if (styleData && styleData.data && styleData.data.Resources) {
                            mapStyles.push(...styleData.data.Resources)
                        }
                    })
                }
                let styleObjs = [];
                if (mapStyles.length) {
                    mapStyles.forEach((style, ind) => {
                        style['label'] = style.Name;
                        style['value'] = style.Name
                        styleObjs.push(style);
                    });
                    dispatch(fetchMapThemeCancel(null, false));
                    dispatch(fetchMapThemeUrl(styleObjs[0].Url));
                    dispatch(fetchMapThemeSuccess(styleObjs));
                    dispatch(fetchRenderMapThemes(1));
                    dispatch(fetchRenderWeather(1));
                    dispatch(fetchRenderUncertainity(0));
                }
            }))
            .catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    callRetriesCount = callRetriesCount + 1;
                    if (callRetriesCount > 2) {
                        dispatch(fetchMapThemeCancel(cancelErrorMessages.mapUserMsg, false));
                        dispatch(fetchRenderMapThemes(1));
                    }
                    else {
                        dispatch(fetchMapThemeCancel(null, true));
                        dispatch(fetchRenderMapThemes(1));
                    }
                }
                else if (error.response) {
                    dispatch(fetchMapThemeCancel(error.response.statusText));
                    dispatch(fetchRenderMapThemes(1));
                } else {
                    dispatch(fetchMapThemeFail(error.message));
                }
            })

    }
}

export const fetchTrackingData = (obj) => {
    return{
        type: FETCH_TRACKING_DATA,
        data: obj
    }
}

export const showTrackingList = (obj, renderCount, val) => {
    return{
        type: SHOW_TRACKING_LIST,
        vehicleType: obj,
        renderCount: renderCount,
        showTracking: val
    }
}

export const fetchLoginStatus = (val) => {
    return{
        type: FETCH_LOGIN_STATUS,
        data: val
    }
}

export const setNetworkStatus = (val) => {
    return{
        type: FETCH_NETWORK_STATUS,
        data: val
    }
}

export const setStreamingStatus = (val) => {
    return{
        type: FETCH_STREAMING_STATUS,
        data: val
    }
}