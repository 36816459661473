import React, { Component } from 'react';
import '../styles/sidebar.scss';
import '../styles/leftpanel.scss';
import IncidentPanel from './IncidentPanel';
import HistoricalData from './HistoricalData';
import CallsPanel from './CallsPanel';
import { connect } from 'react-redux';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';
import { hideSearchPanel } from '../redux/actions/searchActions';
import {featureLabels, tabUrls } from '../constants';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCallPanel: false,
            showSearchPanel: false,
            showIncidentPanel: this.showTab(tabUrls.incident),
            showPanel: false,
            showLayer: false,
            showFound: false,
            customLayerData: [],
            showText: true,
            showHistory: this.showTab(tabUrls.analytics),
            showCalls: isFeatureAllowed(featureLabels.call)
        }
    }

    showTab = (tabName) => {
        if(tabName == tabUrls.incident && !isFeatureAllowed(featureLabels.call)){
            return true;
        } else if(tabName == tabUrls.analytics && !isFeatureAllowed(featureLabels.call) && !isFeatureAllowed(featureLabels.incident)){
            return true;
        }

        return false;
    }

    handleCalls = () => {
        this.setState({
            showIncidentPanel: false,
            showHistory: false,
            showCalls: true
        })
        this.props.showSingleMap(false, tabUrls.call);
    }
    handleSearch = () => {
        debugger;
        this.props.removeRadius(true);
        this.props.showRadius(false);
        this.props.analytics(false);
        this.props.hideMarkers();

    }

    handleIncident = () => {
        this.setState({
            showIncidentPanel: true,
            showPanel: true,
            showHistory: false,
            showCalls: false
        })
        this.props.analytics(false);
        this.props.showSingleMap(false, tabUrls.incident);
        this.props.removePoiRouting();
    }

    handleHistory = () => {
        this.setState({
            showHistory: true,
            showIncidentPanel: false,
            showCalls: false
        })
        this.props.analytics(true);
        this.props.showSingleMap(true, tabUrls.analytics);
        this.props.removePoiRouting();
    }

    handleClose = () => {
        this.setState({
            showCallPanel: false,
            showSearchPanel: false,
            showIncidentPanel: false,
            showPanel: false
        })
        this.props.hideMarkers();
        this.props.showPolygon(false);
    }

    render() {
        return (
            <>

                <div className='psap-sidebar position-fixed'>
                    {!isFeatureAllowed(featureLabels.incident) && !isFeatureAllowed(featureLabels.analytics) && !isFeatureAllowed(featureLabels.call) ?
                        <div className='no-data-found'>No Features Available</div>
                        : ''}
                    {!this.props.showSearch ? <div className='sa-tabs d-flex align-items-center justofy-content-around'>
                        {isFeatureAllowed(featureLabels.call) ?
                            <div className={`tab-link hand-cursor ${this.props.tabName === tabUrls.call ? 'active' : ''}`} onClick={this.handleCalls}>{tabUrls.call}</div>
                            : ''}
                        {isFeatureAllowed(featureLabels.incident) ?
                            <div className={`tab-link hand-cursor ${this.props.tabName === tabUrls.incident ? 'active' : ''}`} onClick={this.handleIncident}>{tabUrls.incident}</div>
                            : ''}
                        {isFeatureAllowed(featureLabels.analytics) ?
                            <div className={`tab-link hand-cursor ${this.props.tabName === tabUrls.analytics ? 'active' : ''}`} onClick={this.handleHistory}>{tabUrls.analytics}</div>
                            : ''}
                    </div> : ''}
                    {isFeatureAllowed(featureLabels.incident) ?
                        (this.props.tabName === tabUrls.incident) && !this.props.showSearch ?
                            <IncidentPanel map={this.props.map} hideMarkers={this.props.hideMarkers} updateTrackBounds={this.props.updateBounds}
                                showMap={this.props.mapLoaded} showUncertainity={this.props.showUncertainity} trackingBounds={this.props.trackingBounds} showReportBounds={this.props.showReportBounds}></IncidentPanel>
                            : ''
                        : ''
                    }
                    {isFeatureAllowed(featureLabels.analytics) ?
                       (this.props.tabName === tabUrls.analytics) && !this.props.showSearch ?

                            <HistoricalData map={this.props.map} hideMarkers={this.props.hideMarkers} showMap={this.props.mapLoaded}></HistoricalData>
                            : ''
                        : ''
                    }
                    {isFeatureAllowed(featureLabels.call) ?

                        (this.props.tabName === tabUrls.call) && !this.props.showSearch ?

                            <CallsPanel map={this.props.map} mapRef2={this.props.mapRef2} hideMarkers={this.props.hideMarkers} showMap={this.props.mapLoaded} removePoiRouting={this.props.removePoiRouting}
                                satelliteMap={this.props.satelliteMap} googleMap={this.props.googleMap}  showUncertainity={this.props.showUncertainity} isSatelliteMapLoaded={this.props.isSatelliteMapLoaded}
                                 isMeasurementOn={this.props.isMeasurementOn} resetCallsEndCall={this.props.resetCallsEndCall} removeEndCallResetValue={this.props.removeEndCallResetValue}
                                  updateBounds={this.props.updateBounds} secondaryView={this.props.secondaryView} setUpdateBounds={this.props.setUpdateBounds}></CallsPanel>
                            : ''
                        : ''
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showSearch: state.search.showSearch,
        searchData: state.search.searchData,
        tabName: state.tab ? state.tab.tabName : state.tab
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideSearchPanel: () => dispatch(hideSearchPanel(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
